/* eslint-disable */
import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";

import "react-multi-carousel/lib/styles.css";

//import context
import { GlobalProvider } from "context/GlobalContext";

/*import url and gateway methods */
import { getData, putData } from "utils/Gateway";
import * as url from "helper/UrlHelper";

// import use translation ------------------------------
import { useTranslation } from "react-i18next";

//import images
import { assetImages } from "constants";
import { formatTimeAgo } from "utils/FormatTimeAgo";

const AuthContactPanel = () => {
  const { screenHeight, reloadContactList, setReloadContactList } =
    useContext(GlobalProvider);
  const token = localStorage.getItem("token");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const moduleAccess = localStorage.getItem("moduleaccess");

  const { t } = useTranslation(); // translation marker

  const [contactList, setContactList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [searchValue, setSearchValue] = useState("");

  //function for set filter leads
  const filterSearchContacts = () => {
    return contactList.filter((contact) => {
      const searchTerm = searchValue.toLowerCase().trim();
      const nameMatch = contact.contactname.toLowerCase().includes(searchTerm);

      return nameMatch;
    });
  };

  //function for get all contacts
  const getAllContactList = async () => {
    try {
      setIsLoading(true);
      let requestUrl =
        url.API_BASE_URL + url.API_GET_ALL_CONTACTS + `?token=${token}`;

      // console.log("url of contact list------>", requestUrl);

      const response = await getData(requestUrl);

      setIsLoading(false);

      // console.log("response in contact list------>", response);

      if (response.status) {
        setContactList(response.data);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    if (reloadContactList) {
      getAllContactList();
      setReloadContactList(false);
    }
  }, [reloadContactList]);

  useEffect(() => {
    getAllContactList();
  }, []);

  if (
    userInfo.role.slug === "ADMIN" ||
    userInfo.role.slug === "SUPER_ADMIN" ||
    moduleAccess.includes("MOD_CONTACT")
  ) {
    return (
      <div className="pnl_rightpnl contact_rightpnl">
        <div className="white_shadow_bx">
          <div className="contact_header">
            <div className="contact_hdng_row d-flex align-items-center justify-content-between">
              <div className="post_hdng">
                <h4>{t("Contacts")}</h4>
              </div>
              {/* <div className="contacts_srch_right">
                <ul className="list_stye_none">
                  <li>
                    <Link to="#">
                      <i className="material-icons-outlined">more_vert</i>
                    </Link>
                  </li>
                </ul>
              </div> */}
            </div>
            <div className="contact_srch">
              <input
                type="text"
                className="form-control"
                placeholder={t("Search")}
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
              />
              <div className="search_list">
                <div className="srch_row d-flex align-items-center justify-content-between">
                  <div className="srch_name_left d-flex align-items-center">
                    <i className="material-icons-outlined">history</i>
                    <span>Richa Holmowalk</span>
                  </div>
                  <Link to="#" className="srch_name_cross">
                    <i className="material-icons-outlined">close</i>
                  </Link>
                </div>
                <div className="srch_row d-flex align-items-center justify-content-between">
                  <div className="srch_name_left d-flex align-items-center">
                    <i className="material-icons-outlined">history</i>
                    <span>David Anderson</span>
                  </div>
                  <Link to="#" className="srch_name_cross">
                    <i className="material-icons-outlined">close</i>
                  </Link>
                </div>
                <div className="srch_row d-flex align-items-center justify-content-between">
                  <div className="srch_name_left d-flex align-items-center">
                    <i className="material-icons-outlined">history</i>
                    <span>Alan Benjamin</span>
                  </div>
                  <Link to="#" className="srch_name_cross">
                    <i className="material-icons-outlined">close</i>
                  </Link>
                </div>
                <div className="srch_row d-flex align-items-center justify-content-between">
                  <div className="srch_name_left d-flex align-items-center">
                    <i className="material-icons-outlined">history</i>
                    <span>Lester Disusa</span>
                  </div>
                  <Link to="#" className="srch_name_cross">
                    <i className="material-icons-outlined">close</i>
                  </Link>
                </div>
                <div className="srch_row d-flex align-items-center justify-content-between">
                  <div className="srch_name_left d-flex align-items-center">
                    <i className="material-icons-outlined">history</i>
                    <span>Richa Holmowalk</span>
                  </div>
                  <Link to="#" className="srch_name_cross">
                    <i className="material-icons-outlined">close</i>
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="contacts_pnl">
            <div className="chat_usrPnl">
              <div className="chat_innrScroll">
                <div className="ai_assistant">
                  <div className="chat_hdng">
                    <span>AI Assistant</span>
                  </div>
                  <div className="chat_usrRow send_msg_row active_user">
                    <Link
                      // to="/conversation"
                      to={`/conversation/ai/aichat/MOD_AI`}
                      // onClick={() => {
                      //   handleConnectSelect("0", "ai");
                      // }}
                    >
                      <div className="usr_lftPnl">
                        <div className="chatting_user">
                          <img src={assetImages.aiLogoSvg} alt="" />
                        </div>
                        <div className="user_name">
                          <h3>LobeesAI</h3>
                          <p>Click to start conversation</p>
                        </div>
                      </div>
                      <div className="msg_statusRight">
                        <span className="msg_time"></span>
                        {/* <span className="pending_msg">5</span> */}
                      </div>
                    </Link>
                  </div>
                </div>

                {contactList.map((contact, index) => {
                  return (
                    <div
                      key={index}
                      className={
                        filterSearchContacts().includes(contact)
                          ? "chat_usrRow cursor-pointer"
                          : "d-none"
                      }
                    >
                      <Link
                        to={`/conversation/contact/${contact._id}/MOD_CONTACT`}
                      >
                        <div className="usr_lftPnl">
                          <div className="chatting_user">
                            {/* <span className="material-icons-round"> person</span> */}
                            <img
                              height="41"
                              className="rounded-circle"
                              src={
                                contact.contactimage === ""
                                  ? assetImages.defaultUser
                                  : url.SERVER_URL + contact.contactimage
                              }
                              alt=""
                            />
                          </div>
                          <div className="user_name">
                            <h3>{contact.contactname}</h3>
                            <p>
                              {contact.contactposition === ""
                                ? "-"
                                : contact.contactposition}
                            </p>
                          </div>
                        </div>
                        <div className="msg_statusRight">
                          <span className="msg_time mt-1">
                            {/* {contact.lastmessagetimestring} */}
                          </span>
                          {/* <span className="pending_msg">2</span> */}
                        </div>
                      </Link>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return null;
  }
};

export default AuthContactPanel;

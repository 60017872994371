/* eslint-disable */
import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";

import { loadStripe } from "@stripe/stripe-js";

/*------ import url and method ------*/
import * as url from "helper/UrlHelper";
import { getData, postData, putData } from "utils/Gateway";

const CartDetails = () => {
  const history = useHistory();
  const token = localStorage.getItem("token");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const invoiceId = localStorage.getItem("invoiceid"); // mongodb invoice id

  const [orderId, setOrderId] = useState(""); // invoiceid to show from API
  const [totalPrice, setTotalPrice] = useState(0);
  const [totalTax, setTotalTax] = useState(0);
  const [totalDiscount, setTotalDiscount] = useState(0);
  const [totalPayable, setTotalPayable] = useState(0);
  const [currency, setCurrency] = useState("€");
  const [invoiceDate, setInvoiceDate] = useState("");

  const [subscriptionPlan, setSubscriptionPlan] = useState(null);

  const [isLoading, setIsLoading] = useState(false);
  const [cartItems, setCartItems] = useState([]);
  const [stripeProductItems, setStripeProductItems] = useState([]);
  const [isUpdating, setIsUpdating] = useState(false);

  //function for company details
  const getInvoiceDetails = async () => {
    try {
      setIsLoading(true);

      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_INVOICE_DETAILS +
        `/${invoiceId}?token=${token}`;

      console.log(requestUrl);

      const response = await getData(requestUrl);

      setIsLoading(false);

      console.log("response", response);

      if (response.data) {
        setOrderId(response.data.orderid);
        setInvoiceDate(response.data.invoicedate);
        setSubscriptionPlan(response.data.plan);

        setTotalPrice(response.data.totalprice);
        setTotalTax(response.data.totaltax);
        setTotalDiscount(response.data.totaldiscount);
        setTotalPayable(response.data.totalpayble);

        setCartItems(response.data.invoiceitems || []);

        setStripeProductItems(response.data.stripecartitems);
      }
    } catch (error) {
      console.log("Error", error.message);
    }
  };

  // update the quantity
  const updateQuantity = (item, value) => {
    if (value > 0) {
      const updatedList = [...cartItems];

      const rowIndex = updatedList.findIndex((cart) => cart._id === item._id);

      if (rowIndex !== -1) {
        updatedList[rowIndex].unit = parseInt(value);

        // calculate total tax
        const productTax = (
          ((item.price * parseInt(value)).toFixed(2) * item.taxpercent) /
          100
        ).toFixed(2);

        updatedList[rowIndex].taxamount = Number(productTax);

        // calculate total amount
        const totalAmount =
          Number((item.price * parseInt(value)).toFixed(2)) +
          Number(productTax);

        updatedList[rowIndex].totalprice = totalAmount;

        const updatedTotalPrice = updatedList.reduce(
          (sum, curr) =>
            sum + (Number((curr.price * curr.unit).toFixed(2)) || 0),
          0
        );

        setTotalPrice(Number(updatedTotalPrice.toFixed(2)));

        const updatedPayble = updatedList.reduce(
          (sum, curr) => sum + (curr.totalprice || 0),
          0
        );

        setTotalPayable(Number(updatedPayble.toFixed(2)));

        if (updatedList[rowIndex].unit > 0) setCartItems(updatedList);
      }
    }
  };

  // checkout function
  const checkoutHandler = async () => {
    try {
      setIsUpdating(true);
      const invoiceItemsUpdated = cartItems.map((item) => {
        return {
          _id: item._id,
          productid: item.productid,
          description: item.description,
          quantity: parseInt(item.unit),
          price: parseFloat(item.price),
          taxpercent: parseFloat(item.taxpercent),
          tax: parseFloat(item.taxamount),
          discountpercent: parseFloat(item.discountpercent),
          discount: parseFloat(item.discountamount),
          amount: parseFloat(item.totalprice),
        };
      });

      let invoiceData = {
        items: invoiceItemsUpdated,
        amount: totalPayable,
      };

      // console.log("invoiceData", invoiceData);

      let requestUrl =
        url.API_BASE_URL +
        url.API_UPDATE_INVOICE +
        `/${invoiceId}?token=${token}`;

      // console.log(requestUrl);

      const response = await putData(requestUrl, invoiceData);

      setIsUpdating(false);

      // console.log("response in checkout", response);

      if (response.status) {
        history.push("/billings");
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  // reset all data
  const resetCartData = () => {
    setOrderId("");
    setTotalPrice(0);
    setTotalTax(0);
    setTotalDiscount(0);
    setTotalPayable(0);
    setCurrency("€");
    setSubscriptionPlan(null);
    setCartItems([]);
  };

  useEffect(() => {
    getInvoiceDetails();
  }, []);

  // payment gateway through Stripe
  const makePayment = async () => {
    console.log("make payment");

    let successUrl = url.FRONTEND_BASE_URL + "/payment/success";
    let failureUrl = url.FRONTEND_BASE_URL + "/payment/failure";

    if (invoiceId) {
      successUrl = successUrl + `?ref=${invoiceId}`;
    }

    console.log("userinfo._id", userInfo._id);

    const stripe = await loadStripe(
      "pk_test_51IJm7YL6PquBzXKuvGXoEkT32UbYTvpafT4Wf6naaoNezsrD9Lt1juEzQd4SJzJWscPci5jr2LRbUcUxu5wUu2Pn00kTokCrRA"
    );

    let requestUrl =
      url.API_BASE_URL + url.API_CART_CHECKOUT_SESSION + `?token=${token}`;

    const paymentInfo = {
      cartItems: stripeProductItems,
      userId: userInfo._id,
      invoiceid: invoiceId,
      successurl: successUrl,
      failureurl: failureUrl,
    };

    try {
      const response = await postData(requestUrl, paymentInfo);

      console.log("response", response);

      if (response.id) {
        const result = stripe.redirectToCheckout({
          sessionId: response.id,
        });
      }
    } catch (error) {
      console.log("error message", error);
    }
  };

  if (cartItems.length === 0) {
    return (
      <section className="feed_pnltotal empty_vh">
        <div className="container-fluid">
          <div className="notication_bx white_shadow_bx py-5 text-center">
            <div className="empty_pic mb-2">
              <img src="/images/empty-cart.png" alt="" />
            </div>

            <div className="empty_text">
              <h2 className="mb-3">
                Your Cart is <span>Empty!</span>
              </h2>

              <p className="mb-4">
                Must add items on the cart before you proceed to check out.
              </p>

              <Link to="/marketplace" className="btn">
                Return to shop
              </Link>
            </div>
          </div>
        </div>
      </section>
    );
  }

  return (
    <section className="pt-70">
      <div className="container-fluid">
        <div className="p-4 cart_sec">
          <div className="row">
            <div className="col-lg-8">
              <div className="cart_leftpnl p-4 rounded-10 overflow-hidden bg-white">
                {cartItems.map((cartItem, index) => {
                  return (
                    <div className="cart_item p-4" key={index}>
                      <div className="row">
                        <div className="col-lg-9 col-md-8">
                          <div className="left_cart_info">
                            {/* <h5 className="mb-2 fs-sm text-gray">#ODR22012024</h5> */}
                            <h3 className="mb-2 fs-md text-black fw-semibold">
                              {cartItem.description}
                            </h3>

                            {subscriptionPlan ? (
                              <ul className="list_stye_none d-flex align-items-center gap-4 mb-2">
                                <li className="d-flex align-items-center gap-2">
                                  <label className="mb-0 text-gray fs-sm d-inline-block">
                                    Date :
                                  </label>
                                  <span className="text-black fs-sm d-inline-block fw-medium">
                                    {invoiceDate}
                                  </span>
                                </li>
                              </ul>
                            ) : cartItem.productid ? (
                              <ul className="list_stye_none d-flex align-items-center gap-4 mb-2">
                                <li className="d-flex align-items-center gap-2">
                                  <label className="mb-0 text-gray fs-sm d-inline-block">
                                    Size:
                                  </label>
                                  <span className="text-black fs-sm d-inline-block fw-medium">
                                    {cartItem.productid.hight} Cm
                                  </span>
                                </li>
                                <li className="d-flex align-items-center gap-2">
                                  <label className="mb-0 text-gray fs-sm d-inline-block">
                                    length:
                                  </label>
                                  <span className="text-black fs-sm d-inline-block fw-medium">
                                    {cartItem.productid.length} Cm
                                  </span>
                                </li>
                                <li className="d-flex align-items-center gap-2">
                                  <label className="mb-0 text-gray fs-sm d-inline-block">
                                    Width:
                                  </label>
                                  <span className="text-black fs-sm d-inline-block fw-medium">
                                    {cartItem.productid.width} Cm
                                  </span>
                                </li>
                                <li className="d-flex align-items-center gap-2">
                                  <label className="mb-0 text-gray fs-sm d-inline-block">
                                    Weight:
                                  </label>
                                  <span className="text-black fs-sm d-inline-block fw-medium">
                                    {cartItem.productid.weight} Gm
                                  </span>
                                </li>
                              </ul>
                            ) : null}

                            <div className="discount_tags_outer d-flex align-items-center gap-2 mb-4">
                              {subscriptionPlan ? (
                                <div className="shoppings_tags d-inline-flex align-items-center gap-2 text-green light-green-bg py-1 px-2 rounded-8">
                                  <span className="fs-sm text-green">
                                    Subscription
                                  </span>
                                  {subscriptionPlan.duration} days
                                </div>
                              ) : (
                                <div className="shoppings_tags d-inline-flex align-items-center gap-2 text-green light-green-bg py-1 px-2 rounded-8">
                                  <i className="material-icons text-green fs-md">
                                    local_shipping
                                  </i>
                                  <span className="fs-sm text-green">
                                    Free Shipping
                                  </span>
                                </div>
                              )}

                              <div className="discount_tags d-inline-flex align-items-center gap-2 text-ornge light-ornge-bg py-1 px-2 rounded-8">
                                <span className="fs-sm text-ornge">
                                  {cartItem.discountpercent}% Discount
                                </span>
                              </div>
                            </div>

                            <div className="product_count">
                              <div className="number d-flex align-items-center gap-2">
                                {subscriptionPlan ? (
                                  "Unit"
                                ) : (
                                  <button
                                    className="minus rounded-circle d-flex align-items-center justify-content-center bg-light text-gray"
                                    onClick={() => {
                                      updateQuantity(
                                        cartItem,
                                        parseInt(cartItem.unit) - 1
                                      );
                                    }}
                                  >
                                    <i className="material-icons-outlined fs-lg">
                                      remove_circle_outline
                                    </i>
                                  </button>
                                )}

                                <input
                                  type="number"
                                  min={1}
                                  className="form-control text-gray fw-medium text-center bg-white rounded-8"
                                  value={cartItem.unit}
                                  onChange={(e) => {
                                    updateQuantity(cartItem, e.target.value);
                                  }}
                                />

                                {subscriptionPlan ? null : (
                                  <button
                                    className="plus rounded-circle d-flex align-items-center justify-content-center bg-light text-gray"
                                    onClick={() => {
                                      updateQuantity(
                                        cartItem,
                                        parseInt(cartItem.unit) + 1
                                      );
                                    }}
                                  >
                                    <i className="material-icons-outlined fs-lg">
                                      {" "}
                                      add_circle_outline
                                    </i>
                                  </button>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-4">
                          <div className="right_cart_info">
                            <div className="payment_info">
                              <h4 className="fs-sm fw-medium mb-3 text-black">
                                {cartItem.price}{" "}
                                <span className="d-inline-block text-gray">
                                  x {cartItem.unit}
                                </span>
                              </h4>
                              <h4 className="fs-sm fw-medium mb-3 text-gray">
                                + {cartItem.taxpercent}%
                              </h4>
                              <h3 className="d-flex gap-2 align-items-center justify-content-end text-gray fs-sm">
                                Payble
                                <span className="fs-md text-black d-inline-block fw-medium">
                                  {cartItem.totalprice} €
                                </span>
                              </h3>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>

            <div className="col-lg-4">
              {cartItems.length > 0 ? (
                <div className="cart_rightPnl p-4 bg-white rounded-10 overflow-hidden border-0">
                  <div className="oredr-hdng mb-3">
                    <h4 className="text-gray text-uppercase fs-sm">
                      Order ID : {orderId}
                    </h4>
                  </div>
                  <div className="order_infopnl">
                    <div className="oredr_row d-flex align-items-center justify-content-between gap-2 mb-3">
                      <h5 className="fs-sm text-black mb-0">Total Price</h5>
                      <h5 className="fs-sm text-black mb-0">
                        {totalPrice} {currency}
                      </h5>
                    </div>
                    <div className="oredr_row d-flex align-items-center justify-content-between gap-2 mb-3">
                      <h5 className="fs-sm text-black mb-0">VAT</h5>
                      <h5 className="fs-sm text-gray mb-0">
                        {totalTax}% {currency}
                      </h5>
                    </div>
                    <div className="oredr_row d-flex align-items-center justify-content-between gap-2 mb-3">
                      <h5 className="fs-sm text-black mb-0">Discount</h5>
                      <h5 className="fs-sm  mb-0 text-gray">
                        - {totalDiscount}% {currency}
                      </h5>
                    </div>
                    <div className="oredr_row d-flex align-items-center justify-content-between gap-2 pt-3">
                      <h5 className="fs-sm text-black mb-0">Total Payable</h5>
                      <h3 className="fs-xl  mb-0 text-black fw-semibold">
                        {totalPayable} {currency}
                      </h3>
                    </div>
                  </div>
                  <div className="mt-4">
                    <button
                      className="btn w-100"
                      onClick={checkoutHandler}
                      disabled={isUpdating ? true : false}
                      style={{
                        cursor: isUpdating ? "not-allowed" : "pointer",
                      }}
                    >
                      Continue Checkout
                      {isUpdating && (
                        <div
                          className="mx-2 spinner-border spinner-border-sm"
                          role="status"
                        >
                          <span className="visually-hidden">Loading...</span>
                        </div>
                      )}
                    </button>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CartDetails;

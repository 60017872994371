import React from "react";
import { Link } from "react-router-dom";

import * as url from "helper/UrlHelper";

import MostImportantPanel from "../../MostImportantPanel/MostImportantPanel";
import { fileDownloadHandler } from "utils/DownloadFile";

const AboutTab = ({ courseDetails = {} }) => {
  return (
    <div className="inner_pnl_outer">
      <div className="inner_leftpnl">
        <div className="people_detailspnl">
          <div className="people_details_bx white_shadow_bx">
            <div className="details_hdng">
              <h4>Skills You Gain</h4>
            </div>
            {courseDetails?.skillvalues ? (
              <div className="experts_skill">
                <ul className="list_stye_none">
                  {courseDetails?.skillvalues?.map((skill, index) => {
                    return (
                      <li key={index}>
                        <Link to="#">{skill.label}</Link>
                      </li>
                    );
                  })}
                </ul>
              </div>
            ) : null}
          </div>

          {/* <div className="people_details_bx white_shadow_bx">
            <div className="details_hdng">
              <h4>You Will Learn</h4>
            </div>
            <div className="training_ticklisting">
              <p>
                <i className="material-icons-outlined">check_circle</i>
                <span>
                  Follow the design process: empathize with users, define pain
                  points, ideate solutions, create wireframes and prototypes,
                  test and iterate on designs
                </span>
              </p>
              <p>
                <i className="material-icons-outlined">check_circle</i>
                <span>
                  Understand the basics of UX research, like planning research
                  studies, conducting interviews and usability studies, and
                  synthesizing research results
                </span>
              </p>
              <p>
                <i className="material-icons-outlined">check_circle</i>
                <span>
                  Apply foundational UX concepts, like user-centered design,
                  accessibility, and equity-focused design
                </span>
              </p>
              <p>
                <i className="material-icons-outlined">check_circle</i>
                <span>
                  Create a professional UX portfolio that includes 3 end-to-end
                  projects: a mobile app, a responsive website, and a
                  cross-platform experience
                </span>
              </p>
            </div>
          </div> */}

          <div className="people_details_bx white_shadow_bx">
            <div className="details_hdng">
              <h4>About This Course</h4>
            </div>
            <div className="results_para">
              <p
                dangerouslySetInnerHTML={{ __html: courseDetails?.description }}
              ></p>
            </div>
          </div>
          {courseDetails?.uploads ? (
            <div className="people_details_bx white_shadow_bx">
              <div className="details_hdng">
                <h4>Sample Contents</h4>
              </div>
              {courseDetails?.uploads
                ?.sort((a, b) => a.rank - b.rank)
                ?.map((content, index) => {
                  if (
                    content.uploadid?.name?.split(".").pop() === "mp4" ||
                    content.uploadid?.name?.split(".").pop() === "mov"
                  ) {
                    return (
                      <div className="course_vdo mb-3" key={index}>
                        <iframe
                          width="100%"
                          height="100%"
                          src={url.SERVER_URL + content?.uploadid?.path}
                          title="YouTube video player"
                          frameBorder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                          allowFullScreen
                        ></iframe>
                      </div>
                    );
                  } else {
                    return (
                      <div
                        className="file d-flex gap-3 align-items-center px-3 py-2 border border-gray rounded-3 mb-3"
                        key={index}
                      >
                        <p className="fw-medium">{content?.uploadid?.name}</p>
                        <Link
                          to="#"
                          onClick={() => fileDownloadHandler(content?.uploadid)}
                        >
                          <span className="d-block material-icons-outlined">
                            download
                          </span>
                        </Link>
                      </div>
                    );
                  }
                })}
            </div>
          ) : null}
        </div>
      </div>
      {/* -------- most important panel ------------- */}
      <MostImportantPanel />
    </div>
  );
};

export default AboutTab;

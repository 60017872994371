/* eslint-disable */
import React, { useState, useEffect } from "react";

import { Link, useParams } from "react-router-dom";

import * as url from "helper/UrlHelper";

import GiveReviewModal from "components/common/Modals/GiveReviewModal";
import ShowReviewModal from "components/common/Modals/ShowReviewModal";

import { getData } from "utils/Gateway";
import UpdateIdea from "components/JobsComponents/JobDetailsComponents/Popups/UpdateIdea";
import UpdateOpportunity from "components/JobsComponents/JobDetailsComponents/Popups/UpdateOpportunity";

const FeedbackTab = () => {
  const token = localStorage.getItem("token");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  const params = useParams();

  const challengeId = params.id;

  const [selectedIdea, setSelectedIdea] = useState(null); // idea id if any

  const [selectedOpportunity, setSelectedOpportunity] = useState(null); // opportunity id if any

  const [selectedChallengeResponse, setSelectedChallengeResponse] =
    useState(null); // challenge response

  const [selectedReview, setSelectedReview] = useState(null); // review id if any

  const [selectedUserId, setSelectedUserId] = useState(null); // who receive the feedback

  const [allIdeas, setAllIdeas] = useState([]); // all ideas of the challenge associated with the user
  const [allOpportunities, setAllOpportunities] = useState([]); // all opportunities of the challenge associated with the user

  const [showIdea, setShowIdea] = useState(false);
  const [showOpportunity, setShowOpportunity] = useState(false);
  const [showReview, setShowReview] = useState(false);
  const [isReviewDone, setIsReviewDone] = useState(false);

  // // POST feedback request ---------------------
  // const giveFeedback = (e) => {
  //   let reviewModal = document.getElementById("feedback_mdl");
  //   let offcanvas = document.getElementById("listIdeasOffcanvas");

  //   //if (reviewModal && offcanvas) {
  //   // Close the offcanvas
  //   let offcanvasInstance = bootstrap.Offcanvas.getInstance(offcanvas);
  //   if (offcanvasInstance) {
  //     offcanvasInstance.hide();
  //   }

  //   // Wait for the offcanvas animation to finish before showing the modal
  //   //setTimeout(() => {
  //   let modal = new bootstrap.Modal(reviewModal);
  //   modal.show();
  //   //}, 500);
  //   //}
  // };

  // Show feedback data ---------------------
  const showFeedbackData = async () => {
    try {
      let requestURL =
        url.API_BASE_URL +
        url.API_CHALLENGE_FEEDBACK_COMPONENTS +
        `/${params.id}?token=${token}&challenge=${challengeId}`;

      console.log("feedback requestURL", requestURL);

      const response = await getData(requestURL);

      console.log("feedback response", response);

      if (response.status) {
        setAllIdeas(response.ideas);
        setAllOpportunities(response.invoices);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  // show idea popup -------------------
  const showIdeaHandler = () => {
    console.log("showIdeaHandler selectedIdea", selectedIdea);

    //setTimeout(() => {
    const offcanvasElement = document.getElementById("updateIdeaOffcanvas");
    const bsOffcanvas = new bootstrap.Offcanvas(offcanvasElement);
    bsOffcanvas.show();
    //}, 100); // delay to ensure state is set

    setShowOpportunity(false);
  };

  // show opportunity popup ------------
  const showOpportunityHandler = () => {
    console.log(
      "showOpportunityHandler selectedOpportunity",
      selectedOpportunity
    );

    //setTimeout(() => {
    const offcanvasElement = document.getElementById(
      "updateOpportunityOffcanvas"
    );
    const bsOffcanvas = new bootstrap.Offcanvas(offcanvasElement);
    bsOffcanvas.show();
    //}, 100); // delay to ensure state is set

    setShowIdea(false);
  };

  // open Givereview modal with id feedback_mdl
  const reviewIdea = (idea) => {
    // reset opportunity
    setSelectedIdea(idea);
    setSelectedOpportunity(null);

    let reviewModal = document.getElementById("feedback_mdl");
    // Wait for the offcanvas animation to finish before showing the modal
    //setTimeout(() => {
    let modal = new bootstrap.Modal(reviewModal);
    modal.show();
    //}, 500);
  };

  // open Givereview modal with id feedback_mdl
  const reviewOpportunity = (invoice) => {
    // reset opportunity
    setSelectedIdea(null);
    setSelectedOpportunity(invoice);

    let reviewModal = document.getElementById("feedback_mdl");
    // Wait for the offcanvas animation to finish before showing the modal
    //setTimeout(() => {
    let modal = new bootstrap.Modal(reviewModal);
    modal.show();
    //}, 500);
  };

  // show review
  const showReviewHandler = (review) => {
    setSelectedReview(review);
    setShowReview(true);

    let reviewModal = document.getElementById("view_feedback_modal");
    // Wait for the offcanvas animation to finish before showing the modal
    //setTimeout(() => {
    let modal = new bootstrap.Modal(reviewModal);
    modal.show();
    //}, 500);
  };

  // show idea
  useEffect(() => {
    console.log("selectedIdea", selectedIdea);
    console.log("showIdea", showIdea);

    if ((selectedIdea != null) & showIdea) {
      showIdeaHandler();
    }
  }, [selectedIdea, showIdea]);

  useEffect(() => {
    if (isReviewDone) {
      showFeedbackData();
    }
  }, [isReviewDone]);

  // show opportunity
  useEffect(() => {
    console.log("selectedOpportunity", selectedOpportunity);
    console.log("showOpportunity", showOpportunity);

    if ((selectedOpportunity != null) & showOpportunity) {
      showOpportunityHandler();
    }
  }, [selectedOpportunity, showOpportunity]);

  // show feedbacks
  useEffect(() => {
    if (challengeId) {
      showFeedbackData();
    }
  }, [challengeId]);

  return (
    <>
      <div className="d-flex justify-content-end mb-2">
        <div
          class="btn outline"
          onClick={() => {
            showFeedbackData();
          }}
        >
          <span className="material-icons-outlined">refresh</span>
        </div>
      </div>

      <div className="people_details_bx white_shadow_bx">
        <table className="table">
          <thead>
            <tr key="0">
              <th scope="col">Who Send</th>
              <th scope="col">What Send</th>
              <th scope="col">Who Receive</th>
              <th scope="col">Date</th>
              <th scope="col">Status</th>
              <th scope="col">Feedback</th>
              <th scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            {/* Map all ideas */}
            {allIdeas &&
              allIdeas.map((idea) => (
                <tr key={idea.id}>
                  <td>
                    {idea.owner?.name ?? ""} {idea.owner?.surname ?? ""}
                  </td>
                  <td>
                    <Link
                      to="#"
                      onClick={() => {
                        setSelectedIdea(idea);
                        setShowIdea(true);
                      }}
                    >
                      Idea
                    </Link>
                  </td>
                  <td>
                    {idea.receiver?.name ?? ""} {idea.receiver?.surname ?? ""}
                  </td>
                  <td>{new Date(idea.createdAt).toLocaleDateString()}</td>
                  <td>
                    {idea.reviews && idea.reviews.length > 0
                      ? "Completed"
                      : "Pending"}
                  </td>
                  <td>
                    {idea.reviews && idea.reviews.length > 0
                      ? idea.reviews[0].rating
                      : "--"}
                  </td>
                  <td>
                    {idea.reviews && idea.reviews.length > 0 ? (
                      <Link
                        to="#"
                        onClick={() => {
                          showReviewHandler(idea.reviews[0]);
                        }}
                      >
                        View
                      </Link>
                    ) : idea.receiver?._id === userInfo._id ? (
                      <Link
                        to="#"
                        onClick={() => {
                          reviewIdea(idea);
                        }}
                      >
                        Give Feedback
                      </Link>
                    ) : (
                      <span>Pending</span>
                    )}
                  </td>
                </tr>
              ))}

            {/* Map all opportunities */}
            {allOpportunities &&
              allOpportunities.map((invoice) => (
                <tr key={invoice.id}>
                  <td>
                    {invoice.user?.name ?? ""} {invoice.user?.surname ?? ""}
                  </td>
                  <td>
                    <Link
                      to="#"
                      onClick={() => {
                        setSelectedOpportunity(invoice);
                        setShowOpportunity(true);
                      }}
                    >
                      Opportunity
                    </Link>
                  </td>
                  <td>
                    {invoice.client?.name ?? ""} {invoice.client?.surname ?? ""}
                  </td>
                  <td>{new Date(invoice.createdAt).toLocaleDateString()}</td>
                  <td>
                    {invoice.reviews && invoice.reviews.length > 0
                      ? "Completed"
                      : "Pending"}
                  </td>
                  <td>
                    {invoice.reviews && invoice.reviews.length > 0
                      ? invoice.reviews[0].rating
                      : "--"}
                  </td>
                  <td>
                    {invoice.reviews && invoice.reviews.length > 0 ? (
                      <Link
                        to="#"
                        onClick={() => {
                          showReviewHandler(invoice.reviews[0]);
                        }}
                      >
                        View
                      </Link>
                    ) : invoice.client?._id === userInfo._id ? (
                      <Link
                        to="#"
                        onClick={() => {
                          reviewOpportunity(invoice);
                        }}
                      >
                        Give Feedback
                      </Link>
                    ) : (
                      <span>Pending</span>
                    )}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>

      <GiveReviewModal
        moduleName="challenge"
        componentid={params.id}
        receiverid={selectedUserId}
        ideaid={selectedIdea ? selectedIdea._id : null}
        opportunityid={selectedOpportunity ? selectedOpportunity._id : null}
        setIsReviewDone={setIsReviewDone}
      />

      <UpdateIdea
        idea={selectedIdea}
        challengeId={params.id}
        showIdea={showIdea}
        setShowIdea={setShowIdea}
      />

      <UpdateOpportunity
        opportunity={selectedOpportunity}
        challengeId={params.id}
        showOpportunity={showOpportunity}
        setShowOpportunity={setShowOpportunity}
      />

      <ShowReviewModal
        reviewData={selectedReview}
        showReview={showReview}
        setShowReview={setShowReview}
      />
    </>
  );
};

export default FeedbackTab;

/* eslint-disable */
import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";

// import use translation ------------------------------
import { useTranslation } from "react-i18next";

/*------ import url and method ------*/
import * as url from "helper/UrlHelper";
import { postData } from "utils/Gateway";

const GiveReviewModal = ({
  moduleName,
  componentid,
  receiverid,
  ideaid,
  opportunityid,
  setIsReviewDone,
}) => {
  const { t } = useTranslation(); // translation marker
  const token = localStorage.getItem("token");

  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const params = useParams();

  // initial useState to save the comment
  const [comments, setComments] = useState("");

  // initial useState to save star rating
  const [starRating, setstarRating] = useState("1");

  /* ------------ Initial useStates for activating the starts starts here ------------ */
  const [activeFirstStar, setActiveFirstStar] = useState(true);
  const [activeSecondStar, setActiveSecondStar] = useState(false);
  const [activeThirdStar, setActiveThirdStar] = useState(false);
  const [activeFourthStar, setActiveFourthStar] = useState(false);
  const [activeFiveStar, setActiveFifthStar] = useState(false);
  const [ratingError, setRatingError] = useState(false);
  const [commentError, setCommentError] = useState(false);
  const [isReviewPosting, setIsReviewPosting] = useState(false);

  /*Initial useStates for activating the starts end*/

  // function to display the star ratings
  const highlightStars = (e, value) => {
    e.preventDefault();

    setActiveFirstStar(false);
    setActiveSecondStar(false);
    setActiveThirdStar(false);
    setActiveFourthStar(false);
    setActiveFifthStar(false);

    if (value == "1") {
      setActiveFirstStar(true);
    }

    if (value == "2") {
      setActiveFirstStar(true);
      setActiveSecondStar(true);
    }

    if (value == "3") {
      setActiveFirstStar(true);
      setActiveSecondStar(true);
      setActiveThirdStar(true);
    }

    if (value == "4") {
      setActiveFirstStar(true);
      setActiveSecondStar(true);
      setActiveThirdStar(true);
      setActiveFourthStar(true);
    }

    if (value == "5") {
      setActiveFirstStar(true);
      setActiveSecondStar(true);
      setActiveThirdStar(true);
      setActiveFourthStar(true);
      setActiveFifthStar(true);
    }
  };

  // Function to select the star ratings from the stars
  const selectedStar = (e, star) => {
    e.preventDefault();
    setstarRating(star);
  };

  // Function to submit the review to the API.
  const reviewSubmitHandler = async () => {
    try {
      let reviewData = {
        comment: comments,
        rating: starRating,
      };

      let requestURL =
        url.API_BASE_URL +
        url.API_SUBMIT_REVIEW +
        `?token=${token}&moduleslug=${moduleName}`;

      if (moduleName == "company") {
        reviewData.companyid = params.id;
      } else if (moduleName == "appuser") {
        reviewData.appuserid = params.id;
      } else if (moduleName == "event") {
        reviewData.eventid = params.id;
      } else if (moduleName == "group") {
        reviewData.groupid = params.id;
      } else if (moduleName == "course") {
        reviewData.courseid = params.id;
      } else if (moduleName == "challenge") {
        reviewData.challengeid = params.id;
        reviewData.ideaid = ideaid; // idea in challange response
        reviewData.invoiceid = opportunityid;
        reviewData.receiverid = receiverid; // receiver of the review
      }

      setIsReviewPosting(true);

      console.log("ReviewData---->", reviewData);

      const response = await postData(requestURL, reviewData);

      setIsReviewPosting(false);

      console.log("review response", response);

      if (response.status) {
        resetHandler();

        setIsReviewDone(true);

        //hide modal
        let reviewModal = document.querySelector("#feedback_mdl");
        let modal = bootstrap.Modal.getInstance(reviewModal);
        modal.hide();

        //display success modal
        let reviewSuccessModal = new bootstrap.Modal(
          document.getElementById("review_success_mdl")
        );
        reviewSuccessModal.show();
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  // function to reset the star and comments
  const resetHandler = () => {
    setActiveFirstStar(true);
    setActiveSecondStar(false);
    setActiveThirdStar(false);
    setActiveFourthStar(false);
    setActiveFifthStar(false);
    setComments("");
    setstarRating("1");
  };

  return (
    <div className="post_modal feedback_modal">
      <div
        className="modal fade"
        id="feedback_mdl"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h3 className="modal-title">{t("Give Feedback")}</h3>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span className="material-icons-outlined">close</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="course_rvw_row">
                <div className="rating_hdng">
                  <h5>{t("Give Ratings")}</h5>
                </div>
                <ul className="list_stye_none">
                  {/* ----------- 1 Star ----------- */}
                  <li className={activeFirstStar ? "active_star" : ""}>
                    <Link
                      to="#"
                      onClick={(e) => {
                        selectedStar(e, "1");
                        highlightStars(e, "1");
                      }}
                    >
                      <span className="material-icons-outlined">star</span>
                    </Link>
                  </li>

                  {/* ----------- 2 Star ----------- */}
                  <li className={activeSecondStar ? "active_star" : ""}>
                    <Link
                      to="#"
                      onClick={(e) => {
                        selectedStar(e, "2");
                        highlightStars(e, "2");
                      }}
                    >
                      <span className="material-icons-outlined">star</span>
                    </Link>
                  </li>

                  {/* ----------- 3 Star ----------- */}
                  <li className={activeThirdStar ? "active_star" : ""}>
                    <Link
                      to="#"
                      onClick={(e) => {
                        selectedStar(e, "3");
                        highlightStars(e, "3");
                      }}
                    >
                      <span className="material-icons-outlined">star</span>
                    </Link>
                  </li>
                  {/* ----------- 4 Star ----------- */}
                  <li className={activeFourthStar ? "active_star" : ""}>
                    <Link
                      to="#"
                      onClick={(e) => {
                        selectedStar(e, "4");
                        highlightStars(e, "4");
                      }}
                    >
                      <span className="material-icons-outlined">star</span>
                    </Link>
                  </li>
                  {/* ----------- 5 Star ----------- */}
                  <li className={activeFiveStar ? "active_star" : ""}>
                    <Link
                      to="#"
                      onClick={(e) => {
                        selectedStar(e, "5");
                        highlightStars(e, "5");
                      }}
                    >
                      <span className="material-icons-outlined">star</span>
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="post_description">
                <label>{t("Comments")}</label>
                <textarea
                  className="form-control"
                  placeholder="Enter comments"
                  value={comments}
                  onChange={(e) => setComments(e.target.value)}
                ></textarea>
              </div>
              <div className="post_btn">
                <button
                  className="btn"
                  style={{
                    cursor: isReviewPosting ? "not-allowed" : "pointer",
                  }}
                  disabled={isReviewPosting ? true : false}
                  onClick={reviewSubmitHandler}
                >
                  {t("Post Review")}
                  {isReviewPosting && (
                    <div
                      className="mx-2 spinner-border spinner-border-sm"
                      role="status"
                    >
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GiveReviewModal;

/* eslint-disable */
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import { ecosystemSlug } from "Config/Config";
import { getAllCategories } from "helper/CategoryHelper/CategoryHelper";

const BlogHeader = ({ isBlog = false }) => {
  const [categoryList, setCategoryList] = useState([]);

  //function for get all category
  const fetchAllCategories = async () => {
    try {
      const parentSlug = "blogpost";
      const response = await getAllCategories(ecosystemSlug, parentSlug);

      setCategoryList(response);
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    if (isBlog) {
      fetchAllCategories();
    }
  }, [isBlog]);

  return (
    <div className="news_bottm_menu">
      <div className="container">
        <ul className="list_stye_none">
          {categoryList.map((category, index) => {
            return (
              <li key={index}>
                <Link to={`/blog/${category.slug}`}>{category.label}</Link>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default BlogHeader;

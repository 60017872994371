/* eslint-disable */
import BillingAddressDetails from "components/BillingAddress/BillingAddressDetails";
import AuthHeader from "components/common/AuthHeader";
import React, { useContext, useEffect } from "react";

const BillingAddress = () => {
  return (
    <>
      <header className="aftre_login_header">
        <AuthHeader />
      </header>

      <BillingAddressDetails />
    </>
  );
};

export default BillingAddress;

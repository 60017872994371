/* eslint-disable */
import React from "react";
import { Link } from "react-router-dom";

// import use translation ------------------------------
import { useTranslation } from "react-i18next";
import { assetImages } from "constants";

import * as url from "helper/UrlHelper";

const AppUserCard = ({ userData }) => {
  const { t } = useTranslation(); // translation marker

  return (
    <div className="people_slideItem w-auto">
      <div className="people_slideInnr">
        <div className="people_profile">
          <img
            height="60"
            className="rounded-circle"
            src={
              userData.userimage == ""
                ? assetImages.defaultUser
                : url.SERVER_URL + userData.userimage
            }
            alt=""
          />
        </div>
        <div className="people_profile_info">
          <h3>{userData.username.slice(0, 15)}</h3>
          <h5>{userData?.position?.slice(0, 18)}</h5>
          <div className="event_tags">
            <ul className="list_stye_none">
              <li>
                <i className="material-icons-outlined ornge_icon">star</i>
                <span className="rating">
                  {userData.totalratings} <em>({userData.totalreviews})</em>
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppUserCard;

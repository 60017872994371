/* eslint-disable */

import React, { useContext, useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";

import "react-multi-carousel/lib/styles.css";

// import use translation ------------------------------
import { useTranslation } from "react-i18next";

//import context
import { GlobalProvider } from "context/GlobalContext";

/*import url and gateway methods */
import * as url from "helper/UrlHelper";
import { getData } from "utils/Gateway";

//import images
import { assetImages } from "constants";
import MedicineCard from "./Card/MedicineCard";

const MedicineMiddlePanel = () => {
  const { screenHeight, ecosystemList } = useContext(GlobalProvider);

  const limit = 10;

  const token = localStorage.getItem("token");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const moduleAccess = localStorage.getItem("moduleaccess");

  const searchUserListRef = useRef(null);

  const { t } = useTranslation(); // translation marker

  const glowCount = [1, 2, 3, 4, 5, 6];

  const [skillList, setSkillList] = useState([]);
  const [serviceList, setServiceList] = useState([]);
  const [tagList, setTagList] = useState([]);

  const [filterName, setfilterName] = useState("");
  const [filterCompany, setfilterCompany] = useState("");
  const [filterPosition, setFilterPosition] = useState("");
  const [filterStartCost, setFilterStartCost] = useState("");
  const [filterEndCost, setFilterEndCost] = useState("");
  const [filterStartDate, setfilterStartDate] = useState("");
  const [filterEndDate, setfilterEndDate] = useState("");
  const [filterRating, setFilterRating] = useState("");
  const [filterServices, setFilterServices] = useState([]);
  const [filterSkills, setfilterSkills] = useState([]);
  const [filterTags, setFilterTags] = useState([]);
  const [filterEcosystems, setFilterEcosystems] = useState([]);

  // the following useState is used to increment the page number and render it accordingly.
  const [page, setPage] = useState(0);

  const [medicineList, setMedicineList] = useState([]);

  const [selectedMedicine, setSelectedMedicine] = useState(null);

  const [isLoading, setIsLoading] = useState(false);

  const [searchedUserResults, setsearchedUserResults] = useState([]);

  const [params, setParams] = useState(null);
  const [searchedText, setsearchedText] = useState("");
  const [hideNextButton, sethideNextButton] = useState(false);

  const [reloadData, setReloadData] = useState(false);

  //function for get app user by search
  const getMedicineBySearch = async () => {
    if (filterName.length < 3) {
      return true;
    }

    console.log("filtername", filterName);

    try {
      setIsLoading(true);

      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_MEDICINES_BY_NAME +
        `?token=${token}&name=${filterName}`;

      console.log(requestUrl);

      const response = await getData(requestUrl);

      console.log(response);

      if (response.status) {
        setMedicineList(response.data);
      } else {
        setMedicineList([]);
      }

      setIsLoading(false);
    } catch (error) {
      console.log(error.message);
    }
  };

  //reset filter
  const resetFilterData = () => {
    setfilterName("");
    setfilterCompany("");
    setFilterPosition("");
    setFilterStartCost("");
    setFilterEndCost("");
    setfilterStartDate("");
    setfilterEndDate("");
    setFilterRating("");
    setFilterServices([]);
    setfilterSkills([]);
    setFilterTags([]);
    setFilterEcosystems([]);
    setPage(0);
    setParams(null);
  };

  //reset list
  const resetList = () => {
    sethideNextButton(false);
    setsearchedText("");
    setMedicineList([]);
    setReloadData(true);
  };

  // Step 2: Handle the click outside logic
  const hideUserListHandler = (event) => {
    if (
      searchUserListRef.current &&
      !searchUserListRef.current.contains(event.target)
    ) {
      setsearchedText("");
      setsearchedUserResults([]);
    }
  };

  // Step 3: Use effect to add the event listener on mount and remove it on unmount
  useEffect(() => {
    // Adding event listener
    document.addEventListener("click", hideUserListHandler);

    // Cleanup the event listener on unmount
    return () => {
      document.removeEventListener("click", hideUserListHandler);
    };
  }, []);

  // useEffect(() => {
  //   if (filterName !== "" && filterName.length > 1) {
  //     getMedicineBySearch();
  //   }
  // }, [filterName]);

  // useEffect(() => {
  //   if (reloadData) {
  //     getAllAppUsers();
  //     setReloadData(false);
  //   }
  // }, [reloadData]);

  // useEffect(() => {
  //   getAllAppUsers();
  // }, []);

  if (
    userInfo.role.slug === "ADMIN" ||
    userInfo.role.slug === "SUPER_ADMIN" ||
    moduleAccess.includes("MOD_USER")
  ) {
    return (
      <div className="pnl_mdlpnl">
        {/* <!-- ========== Start filter search Section ========== --> */}
        <div className="white_shadow_bx px-3 py-2 rounded-10 bg-white d-flex align-items-center justify-content-between mb-4 gap-3 filter_srch_item">
          <div className="filter_search_wrap d-flex align-items-center gap-3 w-100 p-3">
            <div className="dropdown">
              <Link
                to="#"
                className="dropdown-toggle text-gray"
                data-bs-toggle="dropdown"
              >
                Sort by
              </Link>
              <ul className="dropdown-menu px-2">
                <li className="mb-2">
                  <Link
                    to="#"
                    className="dropdown-item d-flex align-items-center gap-2 text-gray"
                    onClick={() => {
                      setParams({
                        sortby: "desc",
                      });
                    }}
                  >
                    {t("Rank")}
                    <i className="material-icons-outlined fs-sm">
                      arrow_upward
                    </i>
                  </Link>
                </li>
                <li className="mb-2">
                  <Link
                    to="#"
                    className="dropdown-item d-flex align-items-center gap-2 text-gray"
                    onClick={() => {
                      setParams({
                        sortby: "asc",
                      });
                    }}
                  >
                    {t("Rank")}
                    <i className="material-icons-outlined fs-sm">
                      arrow_downward
                    </i>
                  </Link>
                </li>
                <li>
                  <Link
                    to="#"
                    className="dropdown-item d-flex align-items-center gap-2 text-gray"
                    onClick={() => {
                      setParams({
                        sortby: "name",
                      });
                    }}
                  >
                    {t("Name")} (A-Z)
                  </Link>
                </li>
              </ul>
            </div>

            <div className="search_filter_form d-flex align-items-center justify-content-between w-100 position-relative">
              <input
                type="text"
                className="form-control"
                placeholder="Search by user name or email"
                value={filterName}
                onChange={(e) => {
                  setfilterName(e.target.value);
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    setfilterName(e.target.value);
                  }
                }}
              />
              <button
                className="filter_srch_btn"
                onClick={() => {
                  getMedicineBySearch();
                }}
              >
                <i className="material-icons-outlined">search</i>
              </button>

              <div
                ref={searchUserListRef}
                className={`search_result bg-white rounded-10 white_shadow_bx p-3 position-absolute ${
                  searchedUserResults.length > 0 ? "show_result" : ""
                }`}
              >
                <ul className="appuser_search_box">
                  {searchedUserResults.map((userData, index) => {
                    return (
                      <li
                        className="py-2 text-gray d-flex align-items-center gap-2"
                        key={index}
                      >
                        <Link to={`/peoples/details/${userData._id}`}>
                          <div className="usr_lftPnl">
                            <div className="chatting_user">
                              <img
                                src={
                                  userData.userimage == ""
                                    ? assetImages.defaultUser
                                    : url.SERVER_URL + userData.userimage
                                }
                                alt=""
                              />
                            </div>
                            <div className="user_name">
                              <h3>{userData.label}</h3>
                              <p>{userData.userposition}</p>
                            </div>
                          </div>
                        </Link>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          </div>
          <div className="filter_refresh_btn d-flex align-items-center gap-3">
            <Link
              to="#"
              className="d-block refresh_iocn"
              onClick={() => {
                resetFilterData();
                resetList();
              }}
            >
              <i className="material-icons-outlined">refresh</i>
            </Link>
            <Link
              to="#"
              data-bs-toggle="offcanvas"
              data-bs-target="#appuser_filter_offcanvas"
              role="button"
              aria-controls="appuser_filter_offcanvas"
              className="d-flex align-items-center gap-2"
            >
              <i className="material-icons-outlined">tune</i>
              <span className="d-block">Filter</span>
            </Link>
          </div>
        </div>
        {/* <!-- ========== End filter search Section ========== --> */}

        {/* <!-- ========== Start user list Section ========== --> */}
        <div className="post_item_outer sameheight_scroll">
          {isLoading ? (
            <div className="post_item">
              <table className="w-100 border-collapse border border-gray-300">
                <thead>
                  <tr className="bg-gray-200">
                    <th className="border p-2">#</th>
                    <th className="border p-2">Name</th>
                    <th className="border p-2">Company</th>
                    <th className="border p-2">Doses</th>
                    <th className="border p-2">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {[...Array(5)].map(
                    (
                      _,
                      index // Show 5 placeholder rows
                    ) => (
                      <tr key={index} className="bg-light">
                        <td className="border p-2">
                          <span className="placeholder placeholder-glow w-32 h-32 d-block"></span>
                        </td>
                        <td className="border p-2">
                          <span className="placeholder placeholder-glow w-50 d-block"></span>
                        </td>
                        <td className="border p-2">
                          <span className="placeholder placeholder-glow w-75 d-block"></span>
                        </td>
                        <td className="border p-2">
                          <span className="placeholder placeholder-glow w-50 d-block"></span>
                        </td>
                        <td className="border p-2">
                          <span className="placeholder placeholder-glow w-25 d-block"></span>
                        </td>
                      </tr>
                    )
                  )}
                </tbody>
              </table>
            </div>
          ) : (
            <div className="post_item">
              <div className="overflow-x-auto w-full">
                <table className="w-100 border-collapse border border-gray-300">
                  <thead>
                    <tr className="bg-gray-200">
                      <th className="border p-2">#</th>
                      <th className="border p-2">Name</th>
                      <th className="border p-2">Company</th>
                      <th className="border p-2">Doses</th>
                      <th className="border p-2">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {medicineList &&
                      medicineList.map((medicine, index) => (
                        <tr
                          key={index}
                          className="hover:bg-gray-100 cursor-pointer"
                          onClick={() => setSelectedMedicine(medicine)}
                        >
                          <td className="border p-2">
                            {medicine.fotos?.length > 0 ? (
                              <img
                                src={medicine.fotos?.[0]?.url}
                                alt="Medicine"
                                className="w-32 h-32 object-cover rounded-lg mt-2"
                              />
                            ) : (
                              <p>No image available</p>
                            )}
                          </td>
                          <td className="border p-2">
                            {medicine.nregistro} <br />
                            {medicine.nombre}
                          </td>
                          <td className="border p-2">{medicine.labtitular}</td>
                          <td className="border p-2">{medicine.dosis}</td>
                          <td className="border p-2">
                            <Link
                              to="#"
                              onClick={(e) => {
                                e.stopPropagation();
                                setSelectedMedicine(medicine);
                              }}
                            >
                              More
                            </Link>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          )}
        </div>
        {/* <!-- ========== End user list Section ========== --> */}

        {/* <!-- ========== Start next page button Section ========== --> */}
        {hideNextButton ? (
          <div className="d-flex align-items-center gap-3 mt-3">
            <p className="fw-semibold text-black-gray">
              Sorry! No more data available
            </p>
            <div className="filter_refresh_btn d-flex align-items-center gap-3">
              <Link
                to="#"
                className="d-block refresh_iocn"
                onClick={() => {
                  resetFilterData();
                  resetList();
                }}
              >
                <i className="material-icons-outlined">refresh</i>
              </Link>
            </div>
          </div>
        ) : (
          <div className="d-flex justify-content-end mt-1">
            <button
              className="btn"
              onClick={() => {
                setPage(page + 1);
              }}
            >
              Next
            </button>
          </div>
        )}
        {/* <!-- ========== End next page button Section ========== --> */}

        {/* <AppUserFilterPopup
          skillList={skillList}
          serviceList={serviceList}
          tagList={tagList}
          ecosystemList={ecosystemList}
          setfilterName={setfilterName}
          setfilterCompany={setfilterCompany}
          setFilterPosition={setFilterPosition}
          setFilterStartCost={setFilterStartCost}
          setFilterEndCost={setFilterEndCost}
          setfilterStartDate={setfilterStartDate}
          setfilterEndDate={setfilterEndDate}
          setFilterRating={setFilterRating}
          setfilterSkills={setfilterSkills}
          setFilterServices={setFilterServices}
          setFilterTags={setFilterTags}
          setFilterEcosystems={setFilterEcosystems}
          resetList={resetList}
        /> */}

        {/* Medicine Modal */}
        {selectedMedicine && (
          <MedicineModal
            medicine={selectedMedicine}
            onClose={() => setSelectedMedicine(null)}
          />
        )}
      </div>
    );
  } else {
    return (
      <div className="pnl_mdlpnl">
        <div className="empty_access text-center">
          <div className="empty_pic mb-4">
            {" "}
            <img src={assetImages.emptyVector} alt="" />
          </div>
          <div className="empty_text">
            <p className="fs-lg text-gray fw-semibold mb-4">
              Sorry....! You don't have privilege to see this content
            </p>
          </div>
        </div>
      </div>
    );
  }
};

export default MedicineMiddlePanel;

/* eslint-disable */
import React, { useContext, useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";

// import use translation ------------------------------
import { useTranslation } from "react-i18next";

/*import url and gateway methods */
import { getData, postData } from "utils/Gateway";
import * as url from "helper/UrlHelper";
import { storeOwnerEmail } from "Config/Config";

const MarketPlaceDetailsBody = () => {
  const params = useParams();
  const history = useHistory();

  const token = localStorage.getItem("token");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const moduleAccess = localStorage.getItem("moduleaccess");
  const invoiceId = localStorage.getItem("invoiceid"); // mongodb invoice id

  const [isLoading, setIsLoading] = useState(false);
  const [productDetails, setProductDetails] = useState({});
  const [cartCount, setCartCount] = useState(1);

  const [isAdding, setIsAdding] = useState(false);

  //function for get product details
  const getProductDetails = async () => {
    try {
      setIsLoading(true);

      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_PRODUCT_DETAILS +
        `/${params.id}?token=${token}`;

      const response = await getData(requestUrl);

      setIsLoading(false);

      console.log(response);

      if (response.status) {
        setProductDetails(response.data);
      }
    } catch (error) {}
  };

  //add to cart function
  const addToCartHandler = async () => {
    try {
      setIsAdding(true);

      // calculate total tax
      const productTax = (
        (Number((productDetails.price * cartCount).toFixed(2)) *
          Number(productDetails.taxpercent)) /
        100
      ).toFixed(2);

      // calculate total amount
      const totalAmount =
        Number((productDetails.price * cartCount).toFixed(2)) +
        Number(productTax);

      const currentItem = [
        {
          productid: params.id,
          description: productDetails.name,
          quantity: cartCount,
          price: productDetails.price,
          taxpercent: Number(productDetails.taxpercent),
          tax: productTax,
          discountpercent: 0,
          discount: 0,
          amount: totalAmount,
        },
      ];

      // create invoice
      let invoiceApiData = {
        clientid: userInfo._id,
        storeowneremail: storeOwnerEmail,
        items: currentItem,
        typeslug: "order",
        modulename: "Marketplace",
      };

      if (invoiceId && invoiceId !== "null") {
        invoiceApiData.invoiceid = invoiceId;

        const invoiceRequestUrl =
          url.API_BASE_URL +
          url.API_GET_INVOICE_DETAILS +
          `/${invoiceId}?token=${token}`;

        const invoiceDeatilsResponse = await getData(invoiceRequestUrl);

        if (invoiceDeatilsResponse.status && invoiceDeatilsResponse.data) {
          if (invoiceDeatilsResponse.invoiceitems) {
            const invoiceItemsExtended =
              invoiceDeatilsResponse.invoiceitems.map((item) => {
                return {
                  _id: item._id,
                  productid: params.id,
                  description: item.description,
                  quantity: item.unit,
                  price: item.price,
                  amount: item.totalprice,
                  taxpercent: item.taxpercent || 0,
                  tax: item.taxamount || 0,
                  discountpercent: 0,
                  discount: 0,
                };
              });

            invoiceApiData.items = [...invoiceItemsExtended, ...currentItem];
          }
        }
      }

      console.log("invoiceApiData ----->", invoiceApiData);

      let requestUrl =
        url.API_BASE_URL + url.API_CREATE_INVOICE_BY_CART + `?token=${token}`;

      const response = await postData(requestUrl, invoiceApiData);

      setIsAdding(false);

      console.log(response);

      if (response.status && response.data) {
        if (response.data._id) {
          // set invoice id in localstorage
          localStorage.setItem("invoiceid", response.data._id);

          localStorage.setItem(
            "cartitemcount",
            response.data.invoiceitems.length
          );

          setCartCount(1);

          history.push(`/marketplace/details/${params.id}`);
        }
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    if (params.id) {
      getProductDetails();
    }
  }, [params.id]);

  return (
    <section className="feed_pnltotal marketplace-details-wrapper">
      {isLoading ? (
        <div className="container-fluid px-3 px-md-4">Loading ...</div>
      ) : (
        <div className="container-fluid px-3 px-md-4">
          <div className="back_btn">
            <Link to="/marketplace">
              <i className="material-icons-outlined">arrow_back</i>
              <span>Back</span>
            </Link>
          </div>
          <div className="marketplace-details-head bg-white p-4 rounded-16 overflow-hidden shadow-sm">
            <div className="row gx-lg-5">
              <div className="col-lg-6 mb-4 mb-lg-0">
                <div className="product-slider-container row">
                  <div className="col-2">
                    <div className="product-slider-nav">
                      <div className="nav-item d-flex align-items-center justify-content-center py-2 border-0 bg-light rounded-10 overflow-hidden cursor-pointer">
                        <img
                          src={url.SERVER_URL + productDetails.productimagepath}
                          alt="Edifier Speaker"
                          className="img-fluid"
                        />
                      </div>
                      {/* <div className="nav-item d-flex align-items-center justify-content-center py-2 border-0 bg-light rounded-10 overflow-hidden cursor-pointer">
                      <img
                        src="/images/product-img-1.png"
                        alt="Edifier Speaker"
                        className="img-fluid"
                      />
                    </div>
                    <div className="nav-item d-flex align-items-center justify-content-center py-2 border-0 bg-light rounded-10 overflow-hidden cursor-pointer">
                      <img
                        src="/images/product-img-1.png"
                        alt="Edifier Speaker"
                        className="img-fluid"
                      />
                    </div>
                    <div className="nav-item d-flex align-items-center justify-content-center py-2 border-0 bg-light rounded-10 overflow-hidden cursor-pointer">
                      <img
                        src="/images/product-img-1.png"
                        alt="Edifier Speaker"
                        className="img-fluid"
                      />
                    </div> */}
                    </div>
                  </div>
                  <div className="col-10">
                    <div className="product-slider">
                      <div className="img-container h-100 d-flex align-items-center justify-content-center p-3 bg-light rounded-16 overflow-hidden">
                        <img
                          src={url.SERVER_URL + productDetails.productimagepath}
                          alt="Edifier Speaker"
                          className="img-fluid"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <h6 className="text-gray fs-xs text-uppercase mb-2">
                  By {productDetails.companyname}
                </h6>
                <h1 className="mb-2 fw-semibold">{productDetails.name}</h1>
                {/* <h5 className="text-black fs-md mb-3">15 Tablet(s) in Strip</h5> */}
                <ul className="d-flex flex-wrap align-items-center gap-2 list-style-none fs-sm fw-medium mb-3">
                  <li className="bg-gray-light d-flex align-items-center gap-1 text-black px-3 py-2 rounded-90">
                    <span className="d-block material-symbols-outlined fs-md icon-fill text-danger">
                      star
                    </span>
                    <span className="d-block">
                      {productDetails.totalratings} (
                      {productDetails.totalreviews})
                    </span>
                  </li>
                  <li className="bg-gray-light d-flex align-items-center gap-1 text-gray px-3 py-2 rounded-90">
                    {productDetails.producttypename}
                  </li>
                </ul>
                <ul className="price d-flex align-items-center gap-3 fw-medium list-style-none mb-3">
                  <li className="fs-xl text-green">
                    €{productDetails.price}/-
                  </li>
                  {/* <!-- <li>€255,990</li> --> */}
                </ul>

                <form
                  onSubmit={(e) => e.preventDefault()}
                  className="cart-quantity d-flex gap-2 mb-4"
                >
                  <div className="field-container position-relative bg-light rounded-90">
                    <Link
                      to="#"
                      onClick={() => {
                        setCartCount(cartCount > 1 ? cartCount - 1 : 1);
                      }}
                      className="remove position-absolute top-50 start-0 translate-middle-y ms-3"
                    >
                      <span className="d-block material-symbols-outlined fs-lg text-black">
                        remove_circle
                      </span>
                    </Link>
                    <input
                      type="number"
                      min={1}
                      className="form-control bg-light text-center border-0 rounded-90"
                      value={cartCount}
                      onChange={(e) => setCartCount(parseInt(e.target.value))}
                    />
                    <Link
                      to="#"
                      className="add position-absolute top-50 end-0 translate-middle-y me-3"
                      onClick={() => {
                        setCartCount(cartCount + 1);
                      }}
                    >
                      <span className="d-block material-symbols-outlined fs-lg text-black">
                        add_circle
                      </span>
                    </Link>
                  </div>
                  <button
                    type="button"
                    className="btn btn-primary add-to-cart"
                    disabled={isAdding ? true : false}
                    style={{
                      cursor: isAdding ? "not-allowed" : "pointer",
                    }}
                    onClick={addToCartHandler}
                  >
                    Add to Cart
                    {isAdding && (
                      <div
                        className="mx-2 spinner-border spinner-border-sm"
                        role="status"
                      >
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    )}
                  </button>
                </form>
                <ul className="fs-sm fw-medium d-flex gap-2 flex-wrap list-style-none">
                  <li className="">
                    <Link
                      to="#"
                      className="bg-light p-3 d-flex  align-items-center justify-content-center rounded-90 gap-2"
                    >
                      <span className="d-block material-symbols-outlined text-gray icon-fill fs-lg">
                        forum
                      </span>
                      <span className="d-block text-black">Contact</span>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="#"
                      className="bg-light p-3 d-flex  align-items-center justify-content-center rounded-90 gap-2"
                    >
                      <span className="d-block material-symbols-outlined text-gray icon-fill fs-lg">
                        hub
                      </span>
                      <span className="d-block text-black">Affiliate</span>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="#"
                      className="bg-light p-3 d-flex  align-items-center justify-content-center rounded-90 gap-2"
                    >
                      <span className="d-block material-symbols-outlined text-gray icon-fill fs-lg">
                        maps_ugc
                      </span>
                      <span className="d-block text-black">Feedback</span>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="#"
                      className="bg-light p-3 d-flex  align-items-center justify-content-center rounded-90 gap-2"
                    >
                      <span className="d-block material-symbols-outlined text-gray icon-fill fs-lg">
                        bug_report
                      </span>
                      <span className="d-block text-black">Report Issue</span>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="marketplace-details-content py-5">
            <div className="tab_links people_tablinks">
              <ul className="nav nav-tabs list_stye_none">
                <li className="nav-item">
                  {" "}
                  <Link
                    className="nav-link active show"
                    data-bs-toggle="tab"
                    to="#overview"
                  >
                    {" "}
                    About
                  </Link>{" "}
                </li>
                <li className="nav-item">
                  {" "}
                  <Link
                    className="nav-link "
                    data-bs-toggle="tab"
                    to="#reviews"
                  >
                    {" "}
                    Reviews
                  </Link>{" "}
                </li>
                <li className="nav-item">
                  {" "}
                  <Link className="nav-link" data-bs-toggle="tab" to="#faq">
                    {" "}
                    FAQs
                  </Link>{" "}
                </li>
              </ul>
            </div>
            <div className="tab-content">
              <div className="tab-pane fade show active" id="overview">
                <div className="people_detailspnl">
                  <div className="people_details_bx white_shadow_bx">
                    <div className="details_hdng">
                      <h4>Summary of {productDetails.name}</h4>
                    </div>
                    <div className="results_para">
                      <p>{productDetails.summary}</p>
                    </div>
                  </div>
                  <div className="people_details_bx white_shadow_bx">
                    <div className="details_hdng">
                      <h4>Details of {productDetails.name}</h4>
                    </div>
                    <div className="results_para">
                      <p
                        dangerouslySetInnerHTML={{
                          __html: productDetails.description,
                        }}
                      ></p>
                    </div>
                  </div>

                  {/* category  */}
                  {/* <div className="people_details_bx white_shadow_bx">
                  <div className="details_hdng">
                    <h4>Category of {productDetails.name}</h4>
                  </div>

                  <div className="training_ticklisting">
                    <ul className="list-style-none">
                      {productDetails.categoryidlist?.map((category, index) => {
                        return (
                          <li className="d-flex gap-2 mb-3" key={index}>
                            <i className="material-icons-outlined text-primary fs-lg">
                              {" "}
                              check_circle{" "}
                            </i>
                            <span>{category.name}</span>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div> */}

                  <div className="people_details_bx white_shadow_bx">
                    <div className="details_hdng">
                      <h4>Available variants of {productDetails.name}</h4>
                    </div>
                    <div className="d-flex flex-column gap-3">
                      {productDetails.productvarations?.map(
                        (variation, index) => {
                          return (
                            <div
                              className="border border-gray-300 p-3 white_shadow_bx"
                              key={index}
                            >
                              <div className="results_para mb-3">
                                <p>{variation.name}</p>
                              </div>
                              <div className="experts_skill">
                                <ul className="list_stye_none">
                                  {variation.options.map((option, index) => {
                                    return (
                                      <li key={index}>
                                        <Link to="#">{option}</Link>
                                      </li>
                                    );
                                  })}
                                </ul>
                              </div>
                            </div>
                          );
                        }
                      )}
                    </div>
                  </div>

                  <div className="people_details_bx white_shadow_bx">
                    <div className="details_hdng">
                      <h4>Other informaton</h4>
                    </div>

                    <ul className="product-details-info list-style-none d-flex flex-column gap-2">
                      <li className="p-2">
                        <span className="d-inline-block">Price</span>
                        <span className="d-inline-block">
                          €{productDetails.price}/-
                        </span>
                      </li>
                      <li className="p-2">
                        <span className="d-inline-block">Tax</span>
                        {/* <span className="d-inline-block">₹3.05 (12% on MRP)</span> */}
                        <span className="d-inline-block">
                          ₹ {productDetails.taxpercent}% on MRP
                        </span>
                      </li>
                      <li className="p-2">
                        <span className="d-inline-block">Manufacturer</span>
                        <span className="d-inline-block">
                          {productDetails.companyname}
                        </span>
                      </li>

                      <li className="p-2">
                        <span className="d-inline-block">Category</span>
                        {productDetails.categoryidlist
                          ?.slice(0, 4)
                          .map((category, index) => {
                            return (
                              <span className="d-inline-block me-2" key={index}>
                                {category.name},
                              </span>
                            );
                          })}
                      </li>

                      <li className="p-2">
                        <span className="d-inline-block">Length </span>
                        <span className="d-inline-block">
                          {productDetails.length} cms{" "}
                        </span>
                      </li>
                      <li className="p-2">
                        <span className="d-inline-block">Width </span>
                        <span className="d-inline-block">
                          {productDetails.width} cms{" "}
                        </span>
                      </li>
                      <li className="p-2">
                        <span className="d-inline-block">Height </span>
                        <span className="d-inline-block">
                          {productDetails.height} gms{" "}
                        </span>
                      </li>
                      <li className="p-2">
                        <span className="d-inline-block">Weight </span>
                        <span className="d-inline-block">
                          {productDetails.weight} gms{" "}
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="tab-pane fade" id="faq">
                <div className="people_details_bx white_shadow_bx">
                  <div className="accordian_area">
                    <div
                      className="accordion accordion-flush"
                      id="faq-accordian2"
                    >
                      <div className="accordion-item">
                        <h2 className="accordion-header">
                          <button
                            className="accordion-button collapsed fs-lg"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#flush-collapse1"
                            aria-expanded="false"
                          >
                            <strong>How is the working environment?</strong>
                          </button>
                        </h2>
                        <div
                          id="flush-collapse1"
                          className="accordion-collapse collapse"
                          data-bs-parent="#faq-accordian2"
                        >
                          <div className="accordion-body">
                            <div className="accor_para">
                              <p>
                                Lorem ipsum dolor sit amet, consectetur
                                adipisicing elit, sed do eiusmod tempor
                                incididunt ut labore et dolore magna aliqua. Ut
                                enim ad minim veniam, quis nostrud exercitation
                                ullamco laboris nisi ut aliquip ex ea commodo
                                consequat.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item">
                        <h2 className="accordion-header">
                          <button
                            className="accordion-button collapsed fs-lg"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#flush-collapse2"
                            aria-expanded="false"
                          >
                            <strong>
                              How many days of paid holidays are provider?
                            </strong>
                          </button>
                        </h2>
                        <div
                          id="flush-collapse2"
                          className="accordion-collapse collapse"
                          data-bs-parent="#faq-accordian2"
                        >
                          <div className="accordion-body">
                            <div className="accor_para">
                              <p>
                                Lorem ipsum dolor sit amet, consectetur
                                adipisicing elit, sed do eiusmod tempor
                                incididunt ut labore et dolore magna aliqua. Ut
                                enim ad minim veniam, quis nostrud exercitation
                                ullamco laboris nisi ut aliquip ex ea commodo
                                consequat.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item">
                        <h2 className="accordion-header">
                          <button
                            className="accordion-button fs-lg"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#flush-collapse3"
                            aria-expanded="true"
                          >
                            <strong>
                              Is there any free meals are provided?
                            </strong>
                          </button>
                        </h2>
                        <div
                          id="flush-collapse3"
                          className="accordion-collapse collapse show"
                          data-bs-parent="#faq-accordian2"
                        >
                          <div className="accordion-body">
                            <div className="accor_para">
                              <p>
                                Lorem ipsum dolor sit amet, consectetur
                                adipisicing elit, sed do eiusmod tempor
                                incididunt ut labore et dolore magna aliqua. Ut
                                enim ad minim veniam, quis nostrud exercitation
                                ullamco laboris nisi ut aliquip ex ea commodo
                                consequat.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="tab-pane fade" id="reviews">
                <div className="forum_bx white_shadow_bx">
                  <div className="rvw_rating_bx">
                    <div className="left_rvwpnl">
                      <h2>
                        4.3 <span>(2,503)</span>
                      </h2>
                      <ul className="list_stye_none">
                        <li>
                          <Link to="#">
                            <i className="material-icons-outlined ornge_icon">
                              star
                            </i>
                          </Link>
                        </li>
                        <li>
                          <Link to="#">
                            <i className="material-icons-outlined ornge_icon">
                              star
                            </i>
                          </Link>
                        </li>
                        <li>
                          <Link to="#">
                            <i className="material-icons-outlined ornge_icon">
                              star
                            </i>
                          </Link>
                        </li>
                        <li>
                          <Link to="#">
                            <i className="material-icons-outlined ornge_icon">
                              star
                            </i>
                          </Link>
                        </li>
                        <li>
                          <Link to="#">
                            <i className="material-icons-outlined ">star</i>
                          </Link>
                        </li>
                      </ul>
                      <div className="add_rvw_btn">
                        <Link
                          to="#"
                          className="btn outline"
                          data-bs-toggle="modal"
                          data-bs-target="#feedback_mdl"
                        >
                          Add Review
                        </Link>
                      </div>
                    </div>
                    <div className="right_rvwpnl">
                      <div className="rvw_progress_outer">
                        <div className="progress_row">
                          <div className="progress_star">
                            <span>5 Star</span>
                          </div>
                          <div className="progess_outer">
                            <span
                              className="progress_bar"
                              style={{ width: "63%" }}
                            ></span>
                          </div>
                          <div className="rvw_percent">
                            <span>63%</span>
                          </div>
                        </div>
                        <div className="progress_row">
                          <div className="progress_star">
                            <span>4 Star</span>
                          </div>
                          <div className="progess_outer">
                            <span
                              className="progress_bar"
                              style={{ width: "21%" }}
                            ></span>
                          </div>
                          <div className="rvw_percent">
                            <span>21%</span>
                          </div>
                        </div>
                        <div className="progress_row">
                          <div className="progress_star">
                            <span>3 Star</span>
                          </div>
                          <div className="progess_outer">
                            <span
                              className="progress_bar"
                              style={{ width: "6%" }}
                            ></span>
                          </div>
                          <div className="rvw_percent">
                            <span>6%</span>
                          </div>
                        </div>
                        <div className="progress_row">
                          <div className="progress_star">
                            <span>2 Star</span>
                          </div>
                          <div className="progess_outer">
                            <span
                              className="progress_bar"
                              style={{ width: "2%" }}
                            ></span>
                          </div>
                          <div className="rvw_percent">
                            <span>2%</span>
                          </div>
                        </div>
                        <div className="progress_row">
                          <div className="progress_star">
                            <span>1 Star</span>
                          </div>
                          <div className="progess_outer">
                            <span
                              className="progress_bar"
                              style={{ width: "9%" }}
                            ></span>
                          </div>
                          <div className="rvw_percent">
                            <span>9%</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="forum_commnts_pnl review_commnts">
                    <div className="forum_row">
                      <div className="forum_cmntsRow">
                        <div className="share_btn forum_shreBtn">
                          <Link to="#">
                            <span className="material-icons-outlined">
                              share
                            </span>
                          </Link>
                        </div>
                        <div className="team_userOuter forum_usrOuter">
                          <div className="team_usr_pic">
                            <img src="/images/expert1.png" alt="" />
                          </div>
                          <div className="team_usrTxt">
                            <h3>Roma Keller</h3>
                            <h5>20/07/2021 17:31</h5>
                            <div className="forum_para">
                              <p>
                                My bad, I don't actually have those Processor's
                                to test, but, the i5 9600k is about ( a little
                                worse) on par with the Ryzen 5 2600, so I am
                                just going to go with it. I read on some website
                                that the 1600 and 2600 was better, but, they are
                                both worse than the 9600k. And, anyways I
                                already have a B365 LGA 1151 Gen 2 motherboard,
                                so, it's the most logical upgrade.
                              </p>
                            </div>

                            <div className="form_share">
                              <Link to="#">
                                <i className="material-icons-outlined">
                                  thumb_up
                                </i>
                                <em>Likes</em>
                                <span>2</span>
                              </Link>
                              <Link to="#" className="commnt_forum">
                                <i className="material-icons-outlined">chat</i>
                                <em>Comment</em>
                                <span>3</span>
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="forum_row">
                      <div className="forum_cmntsRow">
                        <div className="share_btn forum_shreBtn">
                          <Link to="#">
                            <span className="material-icons-outlined">
                              share
                            </span>
                          </Link>
                        </div>
                        <div className="team_userOuter forum_usrOuter">
                          <div className="team_usr_pic">
                            <img src="/images/expert1.png" alt="" />
                          </div>
                          <div className="team_usrTxt">
                            <h3>Roma Keller</h3>
                            <h5>20/07/2021 17:31</h5>
                            <div className="forum_para">
                              <p>
                                My bad, I don't actually have those Processor's
                                to test, but, the i5 9600k is about ( a little
                                worse) on par with the Ryzen 5 2600, so I am
                                just going to go with it. I read on some website
                                that the 1600 and 2600 was better, but, they are
                                both worse than the 9600k. And, anyways I
                                already have a B365 LGA 1151 Gen 2 motherboard,
                                so, it's the most logical upgrade.
                              </p>
                            </div>

                            <div className="form_share">
                              <Link to="#">
                                <i className="material-icons-outlined">
                                  thumb_up
                                </i>
                                <em>Likes</em>
                                <span>2</span>
                              </Link>
                              <Link to="#" className="commnt_forum">
                                <i className="material-icons-outlined">chat</i>
                                <em>Comment</em>
                                <span>3</span>
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="forum_row">
                      <div className="forum_cmntsRow">
                        <div className="share_btn forum_shreBtn">
                          <Link to="#">
                            <span className="material-icons-outlined">
                              share
                            </span>
                          </Link>
                        </div>
                        <div className="team_userOuter forum_usrOuter">
                          <div className="team_usr_pic">
                            <img src="/images/expert1.png" alt="" />
                          </div>
                          <div className="team_usrTxt">
                            <h3>Roma Keller</h3>
                            <h5>20/07/2021 17:31</h5>
                            <div className="forum_para">
                              <p>
                                My bad, I don't actually have those Processor's
                                to test, but, the i5 9600k is about ( a little
                                worse) on par with the Ryzen 5 2600, so I am
                                just going to go with it. I read on some website
                                that the 1600 and 2600 was better, but, they are
                                both worse than the 9600k. And, anyways I
                                already have a B365 LGA 1151 Gen 2 motherboard,
                                so, it's the most logical upgrade.
                              </p>
                            </div>

                            <div className="form_share">
                              <Link to="#">
                                <i className="material-icons-outlined">
                                  thumb_up
                                </i>
                                <em>Likes</em>
                                <span>2</span>
                              </Link>
                              <Link to="#" className="commnt_forum">
                                <i className="material-icons-outlined">chat</i>
                                <em>Comment</em>
                                <span>3</span>
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </section>
  );
};

export default MarketPlaceDetailsBody;

/* eslint-disable */
import React, { useEffect } from "react";

import AuthHeader from "components/common/AuthHeader";
import FaqDetailsBody from "components/FaqComponents/FaqDetails/FaqDetailsBody";

const FaqDetails = () => {
  useEffect(() => {
    document.title = "FAQ";
  }, []);

  return (
    <>
      <AuthHeader moduleName={"faq"} />

      <FaqDetailsBody />
    </>
  );
};

export default FaqDetails;

/* eslint-disable */
import React, { useEffect } from "react";

import AuthHeader from "components/common/AuthHeader";
import FaqListBody from "components/FaqComponents/FaqList/FaqListBody";

const FaqList = () => {
  useEffect(() => {
    document.title = "FAQ";
  }, []);

  return (
    <>
      <AuthHeader moduleName={"faq"} />
      <FaqListBody />
    </>
  );
};

export default FaqList;

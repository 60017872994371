import React, { useEffect } from "react";
import Footer from "../../../components/common/Footer";
import BlogListByCategory from "components/BlogsComponents/BlogListByCategory/BlogListByCategory";
import Header from "components/common/Header";
import ContactNow from "components/common/ContactNow";
import AuthHeader from "components/common/AuthHeader";

const BlogListing = () => {
  const token = localStorage.getItem("token");

  useEffect(() => {
    document.title = "Blogs"; // Update the title when Register component mounts
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <>
      {/* ---- Header section ----- */}
      {token ? (
        <>
          <header className="aftre_login_header">
            <AuthHeader />
          </header>
        </>
      ) : (
        <Header isBlog={true} />
      )}

      {/* ---- blogs body section ---- */}
      <BlogListByCategory />

      {/* ---- connect now section ---- */}
      <ContactNow />

      {/* ---- footer section ---- */}
      <Footer />
    </>
  );
};

export default BlogListing;

/* eslint-disable */
import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";

/*import url and gateway methods */
import { getData, putData } from "utils/Gateway";
import * as url from "helper/UrlHelper";
import { fileDownloadHandler } from "utils/DownloadFile";
import ShowImageModal from "components/common/Modals/ShowImageModal";

const FaqDetailsBody = () => {
  const token = localStorage.getItem("token");

  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  const { id: selectedFaqId } = useParams();

  const [title, settitle] = useState("");
  const [details, setdetails] = useState("");
  // initial state for uploaded multiple files
  const [media, setMedia] = useState([]);

  const [categories, setCategories] = useState([]);
  const [tags, settags] = useState([]);
  const [experts, setexperts] = useState([]);
  const [courses, setcourses] = useState([]);
  const [blogPosts, setblogPosts] = useState([]);
  const [companyList, setcompanyList] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  const [displayImagePath, setdisplayImagePath] = useState("");

  //get faq details
  const getFaqDetails = async () => {
    setIsLoading(true);

    try {
      let requestURL =
        url.API_BASE_URL +
        url.API_FAQ_DETAILS +
        `/${selectedFaqId}?token=${token}`;

      const response = await getData(requestURL);

      console.log(response);

      setIsLoading(false);

      if (response.status) {
        const data = response.data;
        settitle(data.question);
        setdetails(data.answer);

        setCategories(data.categorylist);
        settags(data.usertags);
        setcourses(data.courselist);
        setexperts(data.expertlist);
        setblogPosts(data.postlist);
        setcompanyList(data.companylist);

        setMedia(
          data.uploadedfiles.map(({ name, path, _id, ...other }) => ({
            name,
            path: url.SERVER_URL + path,
            _id,
          }))
        );
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    if (selectedFaqId) {
      getFaqDetails();
    }
  }, [selectedFaqId]);

  return (
    <section className="feed_pnltotal">
      <div className="container-fluid">
        <div className="back_btn">
          <Link to="/faq">
            <i className="material-icons-outlined">arrow_back</i>
            <span>Back</span>
          </Link>
        </div>
        <div className="faq_pnl_wrapper">
          <div className="shadow-sm p-4 rounded-10 bg-white mb-3">
            {/* title  */}
            <h2 className="fs-xl text-black mb-3 fw-semibold">{title}</h2>

            {/* categories  */}
            <div
              className={
                categories.length > 0
                  ? "event_tags d-flex align-items-center gap-2 mb-4 flex-wrap"
                  : "d-none"
              }
            >
              <label className="text-gray fw-semibold mb-0">Categories</label>
              <ul className="list_stye_none mb-0">
                {categories.map((item, index2) => {
                  return (
                    <li key={index2}>
                      <Link to="#">{item.name}</Link>{" "}
                    </li>
                  );
                })}
              </ul>
            </div>

            {/* tags  */}
            <div
              className={
                tags.length > 0
                  ? "event_tags d-flex align-items-center gap-2 mb-4 flex-wrap"
                  : "d-none"
              }
            >
              <label className="text-gray fw-semibold mb-0">Tags</label>

              <ul className="list_stye_none mb-0">
                {tags.map((tag, index) => {
                  return (
                    <li key={index}>
                      <Link to="#">{tag.title}</Link>
                    </li>
                  );
                })}
              </ul>
            </div>

            {/* details  */}
            <div className="results_para">
              <p dangerouslySetInnerHTML={{ __html: details }}></p>
            </div>
          </div>

          {/* company  */}
          <div
            className={`${
              companyList.length === 0
                ? "d-none"
                : "shadow-sm p-4 rounded-10 bg-white mb-3"
            }`}
          >
            <h4 className="text-gray mb-2 fs-md fw-medium">Sponsors :</h4>

            <div className="upload_area w-100">
              {companyList.map((company, index) => {
                return (
                  <div className="upload_item mb-3" key={index}>
                    <figure
                      className="h-100 w-100 position-relative"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setdisplayImagePath(
                          url.SERVER_URL + company.companylogo
                        );
                        //show image modal
                        let myModal = new bootstrap.Modal(
                          document.getElementById("chatimagemodal")
                        );
                        myModal.show();
                      }}
                    >
                      <img
                        src={url.SERVER_URL + company.companylogo}
                        alt={company.name}
                        style={{ maxWidth: "150px" }}
                      />
                    </figure>
                    <div className="upload_txt">
                      <h4>
                        <Link
                          to={`/company/details/${company._id}`}
                          className="text-black"
                        >
                          {company.name}
                        </Link>
                      </h4>
                    </div>
                  </div>
                );
              })}

              {/* <div className="upload_item">
                <div className="uploaded_innrBx">
                  <Link to="#" className="delete_btn">
                    <span className="material-icons-outlined">delete</span>
                  </Link>
                  <div className="upload_icon">
                    <img src="images/meet.svg" alt="" />
                  </div>
                  <div className="upload_txt">
                    <h4>Myvideo.mp4</h4>
                    <h5>16mb</h5>
                  </div>
                </div>
              </div>
              <div className="upload_item">
                <div className="uploaded_innrBx">
                  <Link to="javascript:void(0);" className="delete_btn">
                    <span className="material-icons-outlined">delete</span>
                  </Link>
                  <div className="upload_icon">
                    <img src="images/pdf.svg" alt="" />
                  </div>
                  <div className="upload_txt">
                    <h4>MyResume.pdf</h4>
                    <h5>1.5mb</h5>
                  </div>
                </div>
              </div> */}
            </div>
          </div>

          {/* files  */}
          <div
            className={`${
              media.length === 0
                ? "d-none"
                : "shadow-sm p-4 rounded-10 bg-white mb-3"
            }`}
          >
            <h4 className="text-gray mb-2 fs-md fw-medium">Attached Media :</h4>

            <div className="upload_area w-100">
              {media.map((file, index) => {
                const fileExtension = file.name.split(".").pop();

                return (
                  <div className="upload_item mb-3" key={index}>
                    {fileExtension === "jpg" ||
                    fileExtension === "png" ||
                    fileExtension === "jpeg" ||
                    fileExtension === "gif" ||
                    fileExtension === "png" ? (
                      <figure
                        className="h-100 w-100 position-relative"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          if (
                            fileExtension === "jpg" ||
                            fileExtension === "jpeg" ||
                            fileExtension === "png" ||
                            fileExtension === "gif" ||
                            fileExtension === "bmp" ||
                            fileExtension === "tiff" ||
                            fileExtension === "tif" ||
                            fileExtension === "webp" ||
                            fileExtension === "heif" ||
                            fileExtension === "heic" ||
                            fileExtension === "raw" ||
                            fileExtension === "svg" ||
                            fileExtension === "apng" ||
                            fileExtension === "ico" ||
                            fileExtension === "exr"
                          ) {
                            setdisplayImagePath(file.path);
                            //show image modal
                            let myModal = new bootstrap.Modal(
                              document.getElementById("chatimagemodal")
                            );
                            myModal.show();
                          }
                        }}
                      >
                        <img
                          src={file.path}
                          alt="1737112588-cryp...jpg"
                          style={{ maxWidth: "250px" }}
                        />
                      </figure>
                    ) : fileExtension === "pdf" ? (
                      <>
                        <span className="d-flex align-items-center justify-content-center file-icon p-3 border-bottom">
                          <img
                            src="/images/pdf.svg"
                            alt="pdf"
                            style={{ width: "100px" }}
                          />
                        </span>
                        <div className="doc_info d-flex align-items-center gap-2 justify-content-between px-3 py-2">
                          <div className="left_info">
                            <p className="text-dark-primary fs-sm fw-regular">
                              {file.name}
                            </p>
                          </div>
                          <Link
                            to="#"
                            className="d-inline-block text-gray fs-md"
                            onClick={() => {
                              fileDownloadHandler(file);
                            }}
                          >
                            <span className="material-icons-outlined">
                              file_download
                            </span>
                          </Link>
                        </div>
                      </>
                    ) : fileExtension === "xlsx" ? (
                      <>
                        <span className="d-flex align-items-center justify-content-center file-icon p-3 border-bottom">
                          <img
                            src="/images/xls.svg"
                            alt="xlsx"
                            style={{ width: "100px" }}
                            onClick={() => {
                              fileDownloadHandler(file);
                            }}
                          />
                        </span>
                        <div className="doc_info d-flex align-items-center gap-2 justify-content-between px-3 py-2">
                          <div className="left_info">
                            <p className="text-dark-primary fs-sm fw-regular">
                              {file.name}
                            </p>
                          </div>
                          <Link
                            to="#"
                            className="d-inline-block text-gray fs-md"
                            onClick={() => {
                              fileDownloadHandler(file);
                            }}
                          >
                            <span className="material-icons-outlined">
                              file_download
                            </span>
                          </Link>
                        </div>
                      </>
                    ) : fileExtension === "doc" || fileExtension === "txt" ? (
                      <>
                        <span className="d-flex align-items-center justify-content-center file-icon p-3 border-bottom">
                          <img
                            src="/images/doc.svg"
                            alt="doc"
                            style={{ width: "100px" }}
                          />
                        </span>
                        <div className="doc_info d-flex align-items-center gap-2 justify-content-between px-3 py-2">
                          <div className="left_info">
                            <p className="text-dark-primary fs-sm fw-regular">
                              {file.name}
                            </p>
                          </div>
                          <Link
                            to="#"
                            className="d-inline-block text-gray fs-md"
                            onClick={() => {
                              fileDownloadHandler(file);
                            }}
                          >
                            <span className="material-icons-outlined">
                              file_download
                            </span>
                          </Link>
                        </div>
                      </>
                    ) : fileExtension === "mp4" || fileExtension === "mov" ? (
                      <figure className="h-100 w-100 position-relative">
                        <video
                          controls
                          className="w-100"
                          style={{ maxWidth: "500px" }}
                        >
                          <source src={file.path} type="video/mp4" />
                          Your browser does not support the video tag.
                        </video>
                      </figure>
                    ) : null}
                  </div>
                );
              })}

              {/* <div className="upload_item">
                <div className="uploaded_innrBx">
                  <Link to="#" className="delete_btn">
                    <span className="material-icons-outlined">delete</span>
                  </Link>
                  <div className="upload_icon">
                    <img src="images/meet.svg" alt="" />
                  </div>
                  <div className="upload_txt">
                    <h4>Myvideo.mp4</h4>
                    <h5>16mb</h5>
                  </div>
                </div>
              </div>
              <div className="upload_item">
                <div className="uploaded_innrBx">
                  <Link to="javascript:void(0);" className="delete_btn">
                    <span className="material-icons-outlined">delete</span>
                  </Link>
                  <div className="upload_icon">
                    <img src="images/pdf.svg" alt="" />
                  </div>
                  <div className="upload_txt">
                    <h4>MyResume.pdf</h4>
                    <h5>1.5mb</h5>
                  </div>
                </div>
              </div> */}
            </div>
          </div>

          <div
            className={
              courses.length === 0 &&
              blogPosts.length === 0 &&
              experts.length === 0
                ? "d-none"
                : "shadow-sm p-4 rounded-10 bg-white mb-3 faq_info_link"
            }
          >
            <h4 className="text-gray mb-3 fs-md fw-medium">Related to :</h4>

            <h5
              className={
                courses.length > 0
                  ? "text-black fw-semibold mb-2 fs-md mb-2"
                  : "d-none"
              }
            >
              Course
            </h5>

            {courses.map((course, index) => {
              return (
                <p className="d-flex align-iems-center gap-2 mb-2" key={index}>
                  <span className="material-icons-outlined text-primary">
                    link
                  </span>
                  <Link
                    to={`/courses/details/${course._id}`}
                    className="text-primary fs-md underline fw-medium"
                  >
                    {course.title}
                  </Link>
                </p>
              );
            })}

            <h5
              className={
                blogPosts.length > 0
                  ? "text-black fw-semibold mb-2 fs-md mb-2"
                  : "d-none"
              }
            >
              Post
            </h5>

            {blogPosts.map((post, index) => {
              return (
                <p className="d-flex align-iems-center gap-2 mb-2" key={index}>
                  <span className="material-icons-outlined text-primary">
                    link
                  </span>
                  <Link
                    to={`/blogdetails/${post._id}`}
                    className="text-primary fs-md underline fw-medium"
                  >
                    {post.name}
                  </Link>
                </p>
              );
            })}

            <h5
              className={
                experts.length > 0
                  ? "text-black fw-semibold mb-2 fs-md mb-2"
                  : "d-none"
              }
            >
              Experts
            </h5>

            {experts.map((expert, index) => {
              return (
                <p className="d-flex align-iems-center gap-2 mb-2" key={index}>
                  <span className="material-icons-outlined text-primary">
                    {" "}
                    link{" "}
                  </span>
                  <Link
                    to={`/peoples/details/${expert._id}`}
                    className="text-primary fs-md underline fw-medium"
                  >
                    {expert.name ?? expert.email} {expert.surname ?? ""}
                  </Link>
                </p>
              );
            })}
          </div>
        </div>
      </div>

      <ShowImageModal
        imagePath={displayImagePath}
        setImagePath={setdisplayImagePath}
      />
    </section>
  );
};

export default FaqDetailsBody;

/* eslint-disable */
import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";

import { useTranslation } from "react-i18next";

import * as url from "helper/UrlHelper";
import { assetImages } from "constants";

import ViewPublicDataModal from "components/JobsComponents/JobDetailsComponents/Modals/ViewPublicDataModal";
import SaveIdea from "components/JobsComponents/JobDetailsComponents/Popups/SaveIdea";
import SaveOpportunity from "components/JobsComponents/JobDetailsComponents/Popups/SaveOpportunity";

const AppliedTab = ({ responseList = [] }) => {

  const { t } = useTranslation();
  const token = localStorage.getItem("token");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  const params = useParams();

  const [cvId, setCvId] = useState(null);
  const [selectedResponseId, setSelectedResponseId] = useState(null);
  const [selectedUserId, setSelectedUserId] = useState(null);

  const [showPublicData, setShowPublicData] = useState(false);

  const [isIdeaSaved, setIsIdeaSaved ] = useState(false); // to show thanks modal
  const [showIdea, setShowIdea] = useState(false); // to show save form

  const [isOpportunitySaved, setIsOpportunitySaved ] = useState(false);
  const [showOpportunity, setShowOpportunity] = useState(false);

  const [isTaskSaved, setIsTaskSaved] = useState(false);
  const [showGoal, setShowGoal] = useState(false);


  // useEffect(() => {
  //   console.log('responseList', responseList);
  // }, [responseList]);

  const showPublicDataHandler = (cvId) => {    
    setCvId(cvId)
  }

  // show idea popup -------------------
  const showIdeaHandler = (responseId, submittedbyid) => {

    console.log('showIdeaHandler responseId', responseId);
    console.log('showIdeaHandler submittedbyid', submittedbyid);
    
    setSelectedResponseId(responseId);
    setSelectedUserId(submittedbyid);
    setShowIdea(true);

    setTimeout(() => {
      const offcanvasElement = document.getElementById('saveIdeaOffcanvas');
      const bsOffcanvas = new bootstrap.Offcanvas(offcanvasElement);
      bsOffcanvas.show();
      
    }, 100); // delay to ensure state is set

    setShowPublicData(false);
    setShowOpportunity(false);
    setShowGoal(false);
  }


  // show invoice popup ---------------
  const showOpportunityHandler = (responseId, submittedbyid) => {

    console.log('invoice responseId', responseId);
    console.log('invoice submittedbyid', submittedbyid);
    
    setSelectedResponseId(responseId);
    setSelectedUserId(submittedbyid);
    setShowOpportunity(true);

    setTimeout(() => {
      const offcanvasElement = document.getElementById('saveOpportunityOffcanvas');
      const bsOffcanvas = new bootstrap.Offcanvas(offcanvasElement);
      bsOffcanvas.show();
      
    }, 100); // delay to ensure state is set

    // setShowPublicData(false);
    // setShowIdea(false);
    // setShowGoal(false);

  }


  

  return (
    <>
    <div className="post_item_outer">
      {responseList.length === 0 ? (
        <div className="pnl_mdlpnl w-100">
          <div className="empty_access text-center">
            <div className="empty_pic mb-4">
              {" "}
              <img src={assetImages.emptyVector} alt="" />
            </div>
            <div className="empty_text">
              <p className="fs-md text-gray fw-semibold">
                Sorry....! No application submitted for this job
              </p>
            </div>
          </div>
        </div>
      ) : (
        <div className="post_item">
          {responseList.map((jobResponse, index) => {
            return (
              <div className="white_shadow_bx people_bx" key={index}>
                <div className="people_row">
                  <div className="left_people">
                    <div className="people_profile">
                      <img
                        src={
                          jobResponse.candidateimage === ""
                            ? assetImages.defaultUser
                            : url.SERVER_URL + jobResponse.candidateimage
                        }
                        alt={jobResponse.cvcandidatename}
                      />
                    </div>
                    <div className="people_profile_info">
                      <h3>{jobResponse.cvcandidatename}</h3>
                      <h5>{jobResponse.cvcandidateemail}</h5>
                      <h6 className="fw-semibold text-gray">
                        {jobResponse.candidateposition}
                      </h6>
                      <div className="event_tags mt-2">
                        <ul className="list_stye_none mb-0">
                          <li className="btn outline me-2">
                            <Link 
                              to="#"
                              onClick={() => showPublicDataHandler(jobResponse.candidatecvid)}
                              data-bs-toggle="modal"
                              data-bs-target="#view_publicdata_modal"
                            >
                              Profile
                            </Link>
                          </li>

                          <li className="btn outline me-2">
                            <Link 
                              to="#"
                              onClick={() => showIdeaHandler(jobResponse._id, jobResponse.submittedbyid)}
                            >
                              Idea
                            </Link>
                          </li>

                          <li className="btn outline me-2">
                            <Link 
                              to="#"
                              onClick={()=> showOpportunityHandler(jobResponse._id, jobResponse.submittedbyid)}
                            >
                              Opportunity
                            </Link>
                          </li>

                          {/* <li className="btn outline">
                            <Link 
                              to="#"
                            >
                              Create Goal
                            </Link>
                          </li> */}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>

      <ViewPublicDataModal 
        responseCvId = {cvId}
      />

      {/* <ListIdeas
        challengeResponseId={selectedResponseId}
        challengeId={params.id}
        userId={selectedUserId}
        showIdea={showIdea}
      /> */}

      <SaveIdea 
        idea={''} 
        challengeId={params.id}
        challengeResponseId={selectedResponseId}
        receiverId={selectedUserId} 
        setIsIdeaSaved={setIsIdeaSaved}
        showIdea = {showIdea}
        setShowIdea = {setShowIdea}
      />

      {/* save opportunity tab */}
      <SaveOpportunity
        opportunity={''} 
        challengeId={params.id}
        challengeResponseId={selectedResponseId}
        receiverId={selectedUserId} 
        setIsOpportunitySaved={setIsOpportunitySaved}
        showOpportunity = {showOpportunity}
        setShowOpportunity = {setShowOpportunity}
      />

    </>
  );
};

export default AppliedTab;

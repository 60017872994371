import React, { useEffect } from "react";
import Header from "components/common/Header";

import BlogListAllCategory from "components/BlogsComponents/BlogListAllCategory/BlogListAllCategory";
import ContactNow from "components/common/ContactNow";
import Footer from "components/common/Footer";
import AuthHeader from "components/common/AuthHeader";
// import BlogHeader from "components/common/BlogHeader";

const BlogLanding = () => {
  const token = localStorage.getItem("token");

  useEffect(() => {
    document.title = "Blogs"; // Update the title when Register component mounts
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <>
      {/* ---- Header section with condition ----- */}
      {token ? (
        <>
          <header className="aftre_login_header">
            <AuthHeader />
          </header>
        </>
      ) : (
        <Header isBlog={true} />
      )}

      {/* ---- blog list section ---- */}
      <BlogListAllCategory />

      {/* ---- connect now section ---- */}
      <ContactNow />

      {/* ---- footer section ---- */}
      <Footer />
    </>
  );
};

export default BlogLanding;

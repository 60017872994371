/* eslint-disable */
import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";

/*------ import url and method ------*/
import * as url from "helper/UrlHelper";
import { postData } from "utils/Gateway";

//import context
import { GlobalProvider } from "context/GlobalContext";

// import use translation ------------------------------
import { useTranslation } from "react-i18next";

import { ecosystemSlug, storeOwnerEmail } from "Config/Config";

const LoginModal = () => {
  const redirectToUrl = localStorage.getItem("redirectToUrl");

  const { t } = useTranslation(); // translation marker

  const { setcreateAccountMessage, setuserEmailforOtp } =
    useContext(GlobalProvider);

  /*----- UseState to send username and password for Login ----*/
  const [otpEmail, setOtpEmail] = useState("");
  const [otpEmailWarning, setOtpEmailWarning] = useState(false);
  const [notValidOtpEmail, setNotValidOtpEmail] = useState(false);
  const [userEmail, setUserEmail] = useState("");
  const [emailWarning, setEmailWarning] = useState(false);
  const [notValidEMail, setNotValidEMail] = useState(false);
  const [userPassword, setUserPassword] = useState("");
  const [passwordWarning, setPasswordWarning] = useState(false);
  const [isAgree, setIsAgree] = useState(false);
  const [agreeWarning, setAgreeWarning] = useState(false);
  const [otpSending, setotpSending] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [loginFailed, setLoginFailed] = useState(false);

  // function for change text type
  const toggleText = () => {
    setShowPassword(!showPassword);
  };

  //otp function
  const otpHandler = async () => {
    if (otpEmail == "") {
      setOtpEmailWarning(true);
    } else {
      setOtpEmailWarning(false);

      const mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
      if (otpEmail.match(mailformat)) {
        setNotValidOtpEmail(false);
        try {
          setotpSending(true);
          let userData = {
            email: otpEmail,
          };
          let requestURL =
            url.API_BASE_URL +
            url.API_GET_OTP +
            `?ecosystemslug=${ecosystemSlug}&storeowneremail=${storeOwnerEmail}`;

          const response = await postData(requestURL, userData);

          console.log(response);

          setotpSending(false);

          if (response.status) {
            setcreateAccountMessage("");
            setuserEmailforOtp(otpEmail);

            //hide login modal
            let loginModal = document.querySelector("#login_modal");
            let modal = bootstrap.Modal.getInstance(loginModal);
            modal.hide();

            //show otpconfirm modal
            let myModal = new bootstrap.Modal(
              document.getElementById("otp_confirm_login_mdl")
            );
            myModal.show();
          } else {
            setuserEmailforOtp("");
            setcreateAccountMessage(
              "Email does not exist.Please create a new account."
            );

            //hide modal
            let currentModal = document.querySelector("#login_modal");
            let modal = bootstrap.Modal.getInstance(currentModal);
            modal.hide();

            //show register modal
            let myModal = new bootstrap.Modal(
              document.getElementById("register_mdl")
            );
            myModal.show();
          }
          setUserEmail("");
        } catch (error) {
          console.log(error.message);
        }
      } else {
        setNotValidOtpEmail(true);
      }
    }
  };

  //login function
  const loginHandler = async (e) => {
    e.preventDefault();
    switch (true) {
      case userEmail == "" || userPassword == "":
        userEmail == "" ? setEmailWarning(true) : setEmailWarning(false);
        userPassword == ""
          ? setPasswordWarning(true)
          : setPasswordWarning(false);
        // isAgree==false?setAgreeWarning(true):setAgreeWarning(false)
        break;
      default:
        const mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        if (userEmail.match(mailformat)) {
          setNotValidEMail(false);
          setIsLoading(true);
          try {
            let userData = {
              email: userEmail,
              password: userPassword,
            };
            let requestURL =
              url.API_BASE_URL +
              url.API_LOGIN +
              `?ecosystemslug=${ecosystemSlug}&storeowneremail=${storeOwnerEmail}`;

            console.log("request url -->", requestURL);

            const response = await postData(requestURL, userData);

            console.log("response -->", response);

            if (response.status) {
              setLoginFailed(false);
              // assigning the loggedin user's id,token,all data to the browser's local storage.
              localStorage.setItem("token", response.token);
              localStorage.setItem("userInfo", JSON.stringify(response.data));
              localStorage.setItem("userID", JSON.stringify(response.data._id));
              localStorage.setItem("ecosystem", response.ecosystemid);
              localStorage.setItem("ecosystemlogo", response.ecosystemlogo);
              localStorage.setItem("ecosystemslug", response.ecosystemslug);
              localStorage.setItem(
                "moduleaccess",
                response.data.moduleaccessdata
              );
              localStorage.setItem("invoiceid", response.invoiceid);
              localStorage.setItem("cartitemcount", response.cartitemcount);

              resetLoginData();

              if (redirectToUrl === "") {
                window.location.reload();
              } else {
                window.location.href = redirectToUrl;
              }
            } else {
              setLoginFailed(true);
            }
            setIsLoading(false);
          } catch (error) {
            console.error(error.message);
          }
        } else {
          setNotValidEMail(true);
        }
    }
  };

  // function for reset the useState containing the email and password text fields
  const resetLoginData = () => {
    setOtpEmail("");
    setOtpEmailWarning(false);
    setNotValidOtpEmail(false);
    setUserEmail("");
    setUserPassword("");
    setEmailWarning(false);
    setNotValidEMail(false);
    setPasswordWarning(false);
    setLoginFailed(false);
    setcreateAccountMessage("");
    setuserEmailforOtp("");
  };

  return (
    <div className="registration_modal">
      <div
        className="modal fade show"
        id="login_modal"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
      >
        <div className="modal-dialog small_modal">
          <div className="modal-content">
            <div className="modal-header">
              {/* ----- modal title start ----- */}
              <div className="signin_hdng text-left">
                <h3>{t("Log in")}</h3>
                <h5>{t("Please enter details to proceed")}</h5>
              </div>
              {/* ----- modal title end ----- */}

              {/* ----- modal close button start ----- */}
              <button
                type="button"
                className="close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={resetLoginData}
              >
                <i className="material-icons-outlined">close</i>
              </button>
              {/* ----- modal close button end ----- */}
            </div>
            <div className="modal-body">
              <div className="login_body">
                <form onSubmit={(e) => e.preventDefault()}>
                  {/* ------ tab menu start ----- */}
                  <div className="addons_tabLinks jobdetails_tablinks login_tabs">
                    <ul className="nav nav-tabs list_stye_none">
                      <li className="nav-item">
                        <Link
                          className="nav-link active show"
                          data-bs-toggle="tab"
                          to="#password"
                          onClick={resetLoginData}
                        >
                          {t("Password")}
                        </Link>
                      </li>

                      <li className="nav-item">
                        <Link
                          className="nav-link "
                          data-bs-toggle="tab"
                          to="#otp"
                          onClick={resetLoginData}
                        >
                          {t("OTP")}
                        </Link>
                      </li>
                    </ul>
                  </div>
                  {/* ------ tab menu end ----- */}

                  {/* ------ tab section start ----- */}
                  <div className="tab-content">
                    {/* ----- password tab start ----- */}
                    <div className="tab-pane fade show active" id="password">
                      {/* ----- email and password section start ----- */}
                      <div className="landing_login_fillOuter">
                        {/* ----- email section start ----- */}
                        <div className="input_fill_landing">
                          <label>{t("Email")}</label>
                          <input
                            type="email"
                            className="form-control"
                            placeholder={t("Enter email")}
                            value={userEmail}
                            onChange={(e) => {
                              setEmailWarning(false);
                              setNotValidEMail(false);
                              setLoginFailed(false);
                              setUserEmail(e.target.value);
                            }}
                          />
                          {/* ------ email warning start ------ */}
                          {emailWarning && (
                            <p className="error">
                              <span className="material-icons-outlined">
                                info
                              </span>
                              {t("Please enter email")}
                            </p>
                          )}
                          {/* ----- valid email warning ------ */}
                          {notValidEMail && (
                            <p className="error">
                              <span className="material-icons-outlined">
                                info
                              </span>
                              {t("Please enter valid email")}
                            </p>
                          )}
                          {/* ------ email warning end ------ */}
                        </div>
                        {/* ----- email section end ----- */}

                        {/* ----- password section start ----- */}
                        <div className="input_fill_landing">
                          <label>{t("Password")}</label>
                          <div className="eye_btnfill">
                            <input
                              type={showPassword ? "text" : "password"}
                              className="form-control"
                              placeholder={t("Enter Password")}
                              value={userPassword}
                              onChange={(e) => {
                                setPasswordWarning(false);
                                setLoginFailed(false);
                                setUserPassword(e.target.value);
                              }}
                            />
                            <Link
                              to="#"
                              className="copy_link"
                              onClick={toggleText}
                            >
                              <span className="material-icons-outlined">
                                {" "}
                                visibility
                              </span>
                            </Link>
                          </div>
                          {passwordWarning && (
                            <p className="error">
                              <span className="material-icons-outlined">
                                info
                              </span>
                              {t("Please enter password")}
                            </p>
                          )}
                        </div>
                        {/* ----- password section end ----- */}
                      </div>
                      {/* ----- email and password section end ----- */}

                      {loginFailed && (
                        <p className="error">
                          <span className="material-icons-outlined">info</span>
                          {t("Login Failed")}
                        </p>
                      )}

                      {/* ----- agree and forgot passowrd link section start ----- */}
                      <div className="forgot_applyOuter mb-4 mt-4 justify-content-end">
                        {/* <div className="trms_check">
                          <label className="radio-check">
                            <input
                              type="checkbox"
                              checked={isAgree}
                              onChange={(e) => setIsAgree(e.target.checked)}
                            />
                            <span></span>
                            <p>
                              {t("Agree with the")} <Link to="#">T&amp;C</Link>
                            </p>
                          </label>
                        </div> */}
                        <div className="forgot_btn">
                          <Link
                            to="#"
                            className=""
                            data-bs-toggle="modal"
                            data-bs-dismiss="modal"
                            data-bs-target="#forgot_modal"
                          >
                            {t("Forgot Password")}
                          </Link>
                        </div>
                      </div>
                      {/* ----- agree and forgot passowrd link section end ----- */}

                      {/* ----- create and login section start ----- */}
                      <div className="create_login_outer d-flex align-items-center justify-content-between ">
                        {/* ----- create modal link start ----- */}
                        <div className="apply_btn">
                          <Link
                            to="#"
                            className="btn outline"
                            data-bs-toggle="modal"
                            data-bs-dismiss="modal"
                            data-bs-target="#register_mdl"
                          >
                            {t("Create One")}
                          </Link>
                        </div>
                        {/* ----- create modal link end ----- */}

                        {/* ----- login button start ----- */}
                        <div className="apply_btn">
                          <button
                            className="btn"
                            onClick={loginHandler}
                            disabled={isLoading ? true : false}
                            style={{
                              cursor: isLoading ? "not-allowed" : "pointer",
                            }}
                          >
                            {t("Login")}
                            {isLoading && (
                              <div
                                className="mx-2 spinner-border spinner-border-sm"
                                role="status"
                              >
                                <span className="visually-hidden">
                                  Loading...
                                </span>
                              </div>
                            )}
                          </button>
                        </div>
                        {/* ----- login button end ----- */}
                      </div>
                      {/* ----- create and login section end ----- */}

                      {/* <div className="or_text mb-4 mt-4">{t("Or")}</div> */}

                      {/* ----- linkdin and google login section start ----- */}
                      {/* <div className="create_userOuter d-flex align-items-center gap-2">
                        <div className="linkedin_btn w-100">
                          <Link to="#">
                            <span>
                              <img src="images/linkedin-login.svg" alt="" />
                            </span>
                            {t("Login with Linkedin")}
                          </Link>
                        </div>
                        <div className="linkedin_btn google_btn  mb-0 w-100">
                          <Link to="#">
                            <span>
                              <img src="images/google.svg" alt="" />
                            </span>
                            {t("Login with Google")}
                          </Link>
                        </div>
                      </div> */}
                      {/* ----- linkdin and google login section end ----- */}
                    </div>
                    {/* ----- password tab end ----- */}

                    {/* ----- otp tab start ----- */}
                    <div className="tab-pane fade " id="otp">
                      <div className="otp_fill_outer">
                        <div className="form-group">
                          <div className="input_fill_landing">
                            <label>{t("Email")}</label>
                            <input
                              type="email"
                              value={otpEmail}
                              onChange={(e) => {
                                setOtpEmailWarning(false);
                                setNotValidOtpEmail(false);
                                setLoginFailed(false);
                                setOtpEmail(e.target.value);
                              }}
                              className="form-control"
                              placeholder={t("Enter email")}
                            />
                            {/* ------ email warning start ------ */}
                            {otpEmailWarning && (
                              <p className="error mt-1">
                                <span className="material-icons-outlined me-1">
                                  info
                                </span>
                                {t("Please enter email")}
                              </p>
                            )}
                            {/* ----- valid email warning ------ */}
                            {notValidOtpEmail && (
                              <p className="error mt-1">
                                <span className="material-icons-outlined me-1">
                                  info
                                </span>
                                {t("Please enter valid email")}
                              </p>
                            )}

                            <button
                              type="button"
                              className="btn outline"
                              onClick={otpHandler}
                              disabled={otpSending ? true : false}
                              style={{
                                cursor: otpSending ? "not-allowed" : "pointer",
                              }}
                            >
                              {t("Send OTP")}
                              {otpSending && (
                                <div
                                  className="mx-2 spinner-border spinner-border-sm"
                                  role="status"
                                >
                                  <span className="visually-hidden">
                                    Loading...
                                  </span>
                                </div>
                              )}
                            </button>
                          </div>
                        </div>
                        {/* <div className="form-group">
                          <div className="input_fill">
                            <label>OTP</label>
                            <input
                              type="number"
                              value=""
                              className="form-control"
                              placeholder="Enter OTP"
                            />
                          </div>
                        </div> */}
                        {/* <div className="form-group">
                          <div className="trms_check">
                            <label className="radio-check">
                              <input type="checkbox" />
                              <span></span>
                              <p>
                                Agree with the <Link to="#">T&amp;C</Link>
                              </p>
                            </label>
                          </div>
                        </div> */}
                      </div>

                      {/* ----- create account section start ----- */}
                      <div className="create_login_outer d-flex align-items-center">
                        <div className="trms_check me-2">
                          <label className="radio-check">
                            <p>{t("Do not have an account")}?</p>
                          </label>
                        </div>
                        <div className="apply_btn">
                          <Link
                            to="#"
                            className="btn outline"
                            data-bs-toggle="modal"
                            data-bs-dismiss="modal"
                            data-bs-target="#register_mdl"
                          >
                            {t("Create One")}
                          </Link>
                        </div>
                        <div className="apply_btn">
                          {/* <Link to="#" className="btn">
                            Login
                          </Link> */}
                        </div>
                      </div>
                      {/* ----- create account section end ----- */}
                    </div>
                    {/* ----- otp tab end ----- */}
                  </div>
                  {/* ------ tab section end ----- */}
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginModal;

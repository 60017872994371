/* eslint-disable */
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import * as url from "helper/UrlHelper";
import { getData } from "utils/Gateway";

import { assetImages } from "constants";
import { getDateFormatInSpanish } from "helper/Common/CommonHelper";

const ViewPublicDataModal = ({
  responseCvId
}) => {

  const [fetchingProfileData, setFetchingProfileData] = useState(false);
  const [cvContent, setCvContent] = useState({});

  const token = localStorage.getItem("token");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  const [cvid, setcvId] = useState(null);


  //** function for get cv details of logged in user */
  const getCvDetails = async () => {

    console.log('getCvDetails in ViewPublicDate', cvid);

    try {
      setFetchingProfileData(true);

      let requestURL =
        url.API_BASE_URL + url.API_GET_CV_DETAILS + `/${cvid}?token=${token}`;

      const response = await getData(requestURL);

      console.log("response in cv details", response);

      if (response.status && response.data) {
        //const cvdata = response.data;
        const cvContentData = {
          title: response.data?.resumetitle,
          userImagePath: response.data?.profilepicture?.path,
          userName: response.data?.candidatename,
          userSurname: response.data?.candidatesurname,
          userEmail: response.data?.candidateemail,
          userPhone: response.data?.candidatephone,
          userPosition: response.data?.candidateposition,
          userRoles: response.data?.roles,
          userExperienceInYears: response.data?.experience,
          
          companyName: '',
          userAddress: '',
          userZipcode: '',
          userSummary: response.data?.summary,
          userDetails: response.data?.details,
          skillPointListBlock: response.data?.cvskillpoint,
          experienceListBlock: response.data?.cvexperience,
          projectListBlock: response.data?.projectexperiences,
          educationListBlock: response.data?.educationids,
          technicalExpBlock: response.data?.certifications,
          userLanguages: response.data?.languages,
          userOtherSkills: response.data?.optionalskills,
          userPortfolioLinks: response.data?.portfoliolink,
          userCarrierHighlights: response.data?.carrierhighlights,
          userHobbies: response.data?.hobbies,

          publicbasicinfo: response.data?.publicbasicinfo,
          publicuserroles: response.data?.publicuserroles,
          publiccarrierhighlight: response.data?.publiccarrierhighlight,
          publicusersummary: response.data?.publicusersummary,
          publicuserdetails: response.data?.publicuserdetails,
          publicskillpoints: response.data?.publicskillpoints,
          publicworkexperience: response.data?.publicworkexperience,
          publicprojectreference: response.data?.publicprojectreference,
          publiceducation: response.data?.publiceducation,
          publictechnicalcertificate: response.data?.publictechnicalcertificate,
          publiclanguages: response.data?.publiclanguages,
          publicsalaryinfo: response.data?.publicsalaryinfo,
          publicsoftskills: response.data?.publicsoftskills,
          publicportfolio: response.data?.publicportfolio,
          publichobbies: response.data?.publichobbies

        };

        setCvContent(cvContentData);

      }

      setFetchingProfileData(false);
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    console.log('responseCvId', responseCvId);

    if (responseCvId) {
      setcvId(responseCvId);
    }
  }, [responseCvId]);

  useEffect(() => {
    if (cvid) {
      getCvDetails();
    }
  }, [cvid]);



  return (
    <div className="post_modal">
      <div className="modal fade show" id="view_publicdata_modal">
        <div className="modal-dialog modal-xl">
          <div className="modal-content">
            <div className="modal-header">
              <h3 className="modal-title" id="exampleModalLabel">
                {cvContent.title}
              </h3>

              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  setcvId(null);
                }}
              >
                <span className="material-icons-outlined">close</span>
              </button>
            </div>
            <div className="modal-body">
              {/* <!-- ========== Start AI and skills Section ========== --> */}
              <div className="lobees_aibx px-3 py-4 white_shadow_bx mb-4">
                {/* <div className="logo_summery_top d-flex gap-3 align-items-start">
                          <span className="ai_logo d-block rounded-circle overflow-hidden">
                            <img src={assetImages.aiLogo} alt="" />
                          </span>
                          <div className="ai_info_top">
                            <h3>Ai Recommendation</h3>
                            <h6>Current CV is 80% completed</h6>
                          </div>
                        </div> */}

                {/* Basic info ------------------------ */}
                <div className={`summery_bx p-3 mb-4 mt-1 `}>
                  <div className="builder_usr_row d-flex justify-content-between">
                    <div className="build_usrleft_pnl d-flex gap-3">
                      <div className="build_usrpic position-relative">
                        <figure>
                          <img
                            src={
                              cvContent.userImagePath === ""
                                ? assetImages.defaultUser
                                : url.SERVER_URL + cvContent.userImagePath
                            }
                            alt=""
                          />
                        </figure>
                      </div>
                      <div className="build_usrinfo">
                        <h3>
                          {cvContent.userName} {cvContent.userSurname}
                          <span className="d-block">
                            {cvContent.userPosition}
                          </span>
                        </h3>
                        <ul className="list_stye_none d-flex align-items-center mt-3 gap-4 mb-3 flex-wrap">
                          <li className="d-flex align-items-center gap-2">
                            <i className="material-icons">email</i>
                            <span>{cvContent.userEmail}</span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                
                <div className={`summery_bx p-3 mb-4 mt-1 ${cvContent.publicbasicinfo ? "" : "d-none"}`}>
                  <div className="builder_usr_row d-flex justify-content-between">
                    <div className="build_usrleft_pnl d-flex gap-3">
                      
                      <div className="build_usrinfo">                        
                        <ul className="list_stye_none d-flex align-items-center mt-3 gap-4 mb-3 flex-wrap">
                          
                          <li className="d-flex align-items-center gap-2">
                            <i className="material-icons">phone</i>
                            <span>{cvContent.userPhone}</span>
                          </li>
                          <li className="d-flex align-items-center gap-2">
                            <i className="material-icons">work_history</i>
                            <span>{cvContent.userExperienceInYears}  + Years</span>
                          </li>
                        </ul>
                      </div>

                    </div>
                  </div>
                </div>

                {/* Summary ---------------------------- */}
                <div className={`skill_bx p-3 mb-2 ${cvContent.publicusersummary ? "" : "d-none"}`}>
                  <h3 className="mb-3">Summary</h3>
                  <p>{cvContent.userSummary}</p>
                </div>

                {/* <!-- ========== Career highlights ========== --> */}
                <div className={`white_shadow_bx previous_cvbx py-4 px-3 mb-4 ${cvContent.publiccarrierhighlight ? "" : "d-none"}`}>
                  <h3 className="mb-3">Career Highlights</h3>
                  <div className="cv_pnl">
                    {cvContent.userCarrierHighlights &&
                      cvContent.userCarrierHighlights.map(
                        (carrierhighlight, index) => {
                          return (
                            <div
                              className="cv_row p-3 d-flex align-items-center justify-content-between"
                              key={index}
                            >
                              <div className="cv_left_text">
                                <h5>{carrierhighlight}</h5>
                              </div>
                            </div>
                          );
                        }
                      )}
                  </div>
                </div>
                {/* <!-- ========== Career highlights ========== --> */}

           
                {/* Roles ---------------------------- */}
                <div className={`skill_bx p-3 mb-2 ${cvContent.publicuserroles ? "" : "d-none"}`}>
                  <h3 className="mb-3">Roles</h3>
                  <ul className="list_stye_none d-flex flex-wrap gap-2">
                    {cvContent.userRoles &&
                      cvContent.userRoles.map((roleData, index) => {
                        return (
                          <li key={index}>
                            <Link
                              to="#"
                              className="d-flex align-items-center gap-1 btn white_btn"
                            >
                              <span>{roleData}</span>
                            </Link>
                          </li>
                        );
                      })}
                  </ul>
                </div>

              </div>
              {/* <!-- ========== End AI and skills Section ========== --> */}

              {/* <!-- ========== Education section ========== --> */}
              <div className={`white_shadow_bx previous_cvbx py-4 px-3 mb-4 ${cvContent.publiceducation ? "" : "d-none"}`}>
                <h3 className="mb-3">Education</h3>
                <div className="cv_pnl">
                  {cvContent.educationListBlock &&
                    cvContent.educationListBlock.map((education, index) => {
                      return (
                        <div
                          className="cv_row p-3 d-flex align-items-center justify-content-between"
                          key={index}
                        >
                          <div className="cv_left_text">
                            <h4 className="mb-1">{education.degree}</h4>
                            <h5>
                              from {education.institute} in year{" "}
                              {education.year}{" "}
                            </h5>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
              {/* <!-- ========== Education section ========== --> */}

              {/* <!-- ========== Experience section ========== --> */}
              <div className={`white_shadow_bx previous_cvbx py-4 px-3 mb-4 ${cvContent.publicworkexperience ? "" : "d-none"}`}>
                <h3 className="mb-3">Work Experience</h3>
                <div className="cv_pnl">
                  {cvContent.experienceListBlock &&
                    cvContent.experienceListBlock.map((experience, index) => {
                      return (
                        <div
                          className="cv_row p-3 d-flex align-items-center justify-content-between"
                          key={index}
                        >
                          <div className="cv_left_text">
                            <h4 className="mb-1">{experience.position}</h4>
                            <h5>
                              at{" "}
                              {experience.companyid
                                ? experience.companyid.label
                                : experience.company}
                              <i className="material-icons ms-2">date_range</i>{" "}
                              {experience.start.length > 5
                                ? getDateFormatInSpanish(experience.start) +
                                  " - " +
                                  getDateFormatInSpanish(experience.end)
                                : experience.start + " - " + experience.end}
                            </h5>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
              {/* <!-- ========== Experience section ========== --> */}

              {/* <!-- ========== Languages section ========== --> */}
              <div className={`white_shadow_bx previous_cvbx py-4 px-3 mb-4 ${cvContent.publiclanguages ? "" : "d-none"}`}>
                <h3 className="mb-3">Languages</h3>
                <div className="skill_bx p-3">
                  <ul className="list_stye_none d-flex flex-wrap gap-2">
                    {cvContent.userLanguages &&
                      cvContent.userLanguages.map((language, index) => {
                        return (
                          <li key={index}>
                            <Link
                              to="#"
                              className="d-flex align-items-center gap-1 btn white_btn"
                            >
                              <span>{language.languagetext} </span>
                            </Link>
                          </li>
                        );
                      })}
                  </ul>
                </div>
              </div>
              {/* <!-- ========== Languages section ========== --> */}

              {/* <!-- ========== Soft skills section ========== --> */}
              <div className={`white_shadow_bx previous_cvbx py-4 px-3 mb-4 ${cvContent.publicsoftskills ? "" : "d-none"}`}>
                <h3 className="mb-3">Soft Skills</h3>
                <div className="skill_bx p-3">
                  <ul className="list_stye_none d-flex flex-wrap gap-2">
                    {cvContent.userOtherSkills &&
                      cvContent.userOtherSkills.map((skillData, index) => {
                        return (
                          <li key={index}>
                            <Link
                              to="#"
                              className="d-flex align-items-center gap-1 btn white_btn"
                            >
                              <span>{skillData.skilltext} </span>
                            </Link>
                          </li>
                        );
                      })}
                  </ul>
                </div>
              </div>
              {/* <!-- ========== Soft skill section ========== --> */}

              {/* <!-- ========== Hobbies section ========== --> */}
              <div className={`white_shadow_bx previous_cvbx py-4 px-3 mb-4 ${cvContent.publichobbies ? "" : "d-none"} `}>
                <h3 className="mb-3">Hobbies</h3>
                <div className="skill_bx p-3">
                  <ul className="list_stye_none d-flex flex-wrap gap-2">
                    {cvContent.userHobbies &&
                      cvContent.userHobbies.map((hobbiesData, index) => {
                        return (
                          hobbiesData && (
                            <li key={index}>
                              <Link
                                to="#"
                                className="d-flex align-items-center gap-1 btn white_btn"
                              >
                                <span>{hobbiesData} </span>
                              </Link>
                            </li>
                          )
                        );
                      })}
                  </ul>
                </div>
              </div>
              {/* <!-- ========== Hobbies section ========== --> */}

              {/* <!-- ========== Certification section ========== --> */}
              <div className={`white_shadow_bx previous_cvbx py-4 px-3 mb-4  ${cvContent.publictechnicalcertificate ? "" : "d-none"} `}>
                <h3 className="mb-3">Technical Certificates</h3>
                <div className="cv_pnl">
                  {cvContent.technicalExpBlock &&
                    cvContent.technicalExpBlock.map((techExpData, index) => {
                      return (
                        <div
                          className="cv_row p-3 d-flex align-items-center justify-content-between"
                          key={index}
                        >
                          <div className="cv_left_text">
                            <h4 className="mb-1">
                              {techExpData.name}{" "}
                              <p>
                                From {techExpData.companyname}
                                <span> with grade: {techExpData.grade}</span>
                              </p>
                            </h4>
                            <h5>
                              {getDateFormatInSpanish(techExpData.start)} -{" "}
                              {getDateFormatInSpanish(techExpData.end)}
                            </h5>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
              {/* <!-- ========== certification section ========== --> */}

              {/* <!-- ========== Projects section ========== --> */}
              <div className={`white_shadow_bx previous_cvbx py-4 px-3 mb-4 ${cvContent.publicprojectreference ? "" : "d-none"}`}>
                <h3 className="mb-3">Project References</h3>
                <div className="cv_pnl">
                  {cvContent.projectListBlock &&
                    cvContent.projectListBlock.map((project, index) => {
                      return (
                        <div
                          className="cv_row p-3 d-flex align-items-center justify-content-between"
                          key={index}
                        >
                          <div className="cv_left_text">
                            <h4 className="mb-1">
                              {project.company}
                              <span>
                                {" "}
                                ||{" "}
                                {project.start.length > 5
                                  ? getDateFormatInSpanish(project.start) +
                                    " - " +
                                    getDateFormatInSpanish(project.end)
                                  : project.start + " - " + project.end}
                              </span>

                              <p>Role : {project.position}</p>
                              <p>Technologies : {project.technologies}</p>
                            </h4>
                            <h5>{project.responsibilities}</h5>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
              {/* <!-- ========== Projects section ========== --> */}

              {/* <!-- ========== Skill with points section ========== --> */}
              <div className={`white_shadow_bx previous_cvbx py-4 px-3 mb-4  ${cvContent.publicskillpoints ? "" : "d-none"} `}>
                <h3 className="mb-3">Skill Points</h3>
                <div className="cv_pnl">
                  {cvContent.skillPointListBlock &&
                    cvContent.skillPointListBlock.map((skillBlock, index) => {
                      return (
                        <div
                          className="cv_row p-3 d-flex align-items-center justify-content-between"
                          key={index}
                        >
                          <div className="cv_left_text">
                            <h4 className="mb-1">
                              {skillBlock?.skill
                                ? skillBlock?.skill?.label
                                : skillBlock?.skillname}{" "}
                              <span>with rating {skillBlock.points}/10 </span>
                            </h4>
                            <h5>
                              Experience
                              <span> {skillBlock.experienceyear} year</span>
                            </h5>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
              {/* <!-- ========== Skill with points section ========== --> */}

            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewPublicDataModal;

/* eslint-disable */
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import JobLandingRightPanel from "../JobLandingRightPanel/JobLandingRightPanel";
// import use translation ------------------------------
import { useTranslation } from "react-i18next";
import JobListCard from "./Card/JobListCard";

/*------ import url and method ------*/
import * as url from "helper/UrlHelper";
import { getData, putData } from "utils/Gateway";
import JobListPlaceHolder from "./PlaceHolder/JobListPlaceHolder";
import { assetImages } from "constants";
import JobListFilterPopup from "../Popup/JobListFilterPopup";

const JobLandingMiddlePanel = () => {
  const { t } = useTranslation(); // translation marker
  const token = localStorage.getItem("token");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const moduleAccess = localStorage.getItem("moduleaccess");

  const [tagList, setTagList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [jobList, setJobList] = useState([]);

  const [selectedJobId, setselectedJobId] = useState(null);

  const [filterTitle, setFilterTitle] = useState("");
  const [filterTags, setFilterTags] = useState([]);
  const [filterEcosystems, setFilterEcosystems] = useState([]);

  const [params, setParams] = useState(null);
  const [searchedText, setsearchedText] = useState("");
  const [hideNextButton, sethideNextButton] = useState(false);

  const [reloadData, setReloadData] = useState(false);

  //function for get all tags
  const getAllTags = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL + url.API_GET_ALL_TAGS + `?token=${token}`;

      const response = await getData(requestUrl);

      // console.log(response);

      if (response.status) {
        setTagList(response.data);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for get project list
  const getAllChallenges = async (
    start = "0",
    limit = "100",
    sortingBy = null
  ) => {
    setIsLoading(true);
    try {
      let requestUrl = `${url.API_BASE_URL}${url.API_GET_ALL_CHALLENGE}?token=${token}&start=${start}&limit=${limit}&sortingby=${sortingBy}&modulefrom=frontend`;

      if (filterTitle != "") {
        requestUrl = requestUrl + `&filtertitle=${filterTitle}`;
      }

      console.log(requestUrl);

      const response = await getData(requestUrl);
      setIsLoading(false);

      console.log(response);

      if (response.status) {
        setJobList(response.data);
        if (response.data.length > 0) {
          setselectedJobId(response.data[0]._id);
        }

        if (response.data.length < limit) {
          sethideNextButton(true);
        }
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const onJobSelect = (id) => {
    setselectedJobId(id);
  };

  //reset filer search
  const resetFilterData = () => {
    setFilterTitle("");
  };

  const resetList = () => {
    sethideNextButton(false);
    setsearchedText("");
    setReloadData(true);
  };

  //use effect for params
  useEffect(() => {
    if (params?.sortby) {
      getAllChallenges("0", "10", params?.sortby);
    }
  }, [params]);

  useEffect(() => {
    if (reloadData) {
      getAllChallenges("0", "10", null);
      setReloadData(false);
    }
  }, [reloadData]);

  //use effect for filter search
  useEffect(() => {
    if (filterTitle != "") {
      getAllChallenges("0", "10", null);
    }
  }, [filterTitle]);

  useEffect(() => {
    getAllChallenges("0", "10", null);
  }, []);

  if (
    userInfo.role.slug === "ADMIN" ||
    userInfo.role.slug === "SUPER_ADMIN" ||
    moduleAccess.includes("MOD_CHALLENGE")
  ) {
    return (
      <div className="pnl_mdlpnl job_rightpnl">
        {/* <div className="back_btn">
          <Link to="#">
            <i className="material-icons-outlined">arrow_back</i>
            <span>{t("Back")}</span>
          </Link>
        </div> */}

        <div className="post_item_outer">
          {/* <!-- ========== Start filter search Section ========== --> */}
          <div className="white_shadow_bx px-3 py-2 rounded-10 bg-white d-flex align-items-center justify-content-between mb-4 gap-3 filter_srch_item">
            <div className="filter_search_wrap d-flex align-items-center gap-3 w-100 p-3">
              <div className="dropdown">
                <Link
                  to="#"
                  className="dropdown-toggle text-gray"
                  data-bs-toggle="dropdown"
                >
                  Sort by
                </Link>
                <ul className="dropdown-menu px-2">
                  <li className="mb-2">
                    <Link
                      to="#"
                      className="dropdown-item d-flex align-items-center gap-2 text-gray"
                      onClick={() => {
                        setParams({
                          sortby: "desc",
                        });
                      }}
                    >
                      {t("Rank")}
                      <i className="material-icons-outlined fs-sm">
                        arrow_upward
                      </i>
                    </Link>
                  </li>
                  <li className="mb-2">
                    <Link
                      to="#"
                      className="dropdown-item d-flex align-items-center gap-2 text-gray"
                      onClick={() => {
                        setParams({
                          sortby: "asc",
                        });
                      }}
                    >
                      {t("Rank")}
                      <i className="material-icons-outlined fs-sm">
                        arrow_downward
                      </i>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="#"
                      className="dropdown-item d-flex align-items-center gap-2 text-gray"
                      onClick={() => {
                        setParams({
                          sortby: "name",
                        });
                      }}
                    >
                      {t("Name")} (A-Z)
                    </Link>
                  </li>
                </ul>
              </div>

              <div className="search_filter_form d-flex align-items-center justify-content-between w-100">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search by job title"
                  value={searchedText}
                  onChange={(e) => {
                    setsearchedText(e.target.value);
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      setfilterTitle(searchedText);
                    }
                  }}
                />
                <button
                  className="filter_srch_btn"
                  onClick={() => {
                    setfilterTitle(searchedText);
                  }}
                >
                  <i className="material-icons-outlined">search</i>
                </button>
              </div>
            </div>
            <div className="filter_refresh_btn d-flex align-items-center gap-3">
              <Link
                to="#"
                className="d-block refresh_iocn"
                onClick={() => {
                  resetFilterData();
                  resetList();
                }}
              >
                <i className="material-icons-outlined">refresh</i>
              </Link>
              {/* <Link
                to="#"
                data-bs-toggle="offcanvas"
                data-bs-target="#job_filter_offcanvas"
                role="button"
                aria-controls="job_filter_offcanvas"
                className="d-flex align-items-center gap-2"
              >
                <i className="material-icons-outlined">tune</i>
                <span className="d-block">Filter</span>
              </Link> */}
            </div>
          </div>
          {/* <!-- ========== End filter search Section ========== --> */}

          {isLoading ? (
            <div className="post_item">
              <div className="job_outer">
                <div className="job_leftpnl">
                  {[1, 2, 3].map((count, index) => {
                    return <JobListPlaceHolder key={index} />;
                  })}
                </div>
                <div className="results_rightPnl white_shadow_bx">
                  <div className="progres_tagsouter">
                    <div className="tags_lftpnl">
                      <div className="event_tags">
                        <ul className="list_stye_none">
                          <span
                            className="placeholder"
                            style={{ width: "60px" }}
                          ></span>
                          <span
                            className="placeholder"
                            style={{ width: "60px" }}
                          ></span>
                          <span
                            className="placeholder"
                            style={{ width: "60px" }}
                          ></span>
                        </ul>
                      </div>
                    </div>
                    <div className="tags_prgrsspnl">
                      <h5>
                        <span
                          className="placeholder"
                          style={{ width: "60px" }}
                        ></span>{" "}
                        <span
                          className="placeholder"
                          style={{ width: "40px" }}
                        ></span>
                      </h5>
                      <div className="progess_outer">
                        <span
                          className="progress_bar "
                          style={{ width: "40%" }}
                        ></span>
                      </div>
                    </div>
                  </div>
                  <div className="job_hdng">
                    <h3>
                      <span
                        className="placeholder"
                        style={{ width: "80%" }}
                      ></span>
                    </h3>
                    <h5>
                      <span
                        className="placeholder"
                        style={{ width: "20%", color: "#AAB3BD" }}
                      ></span>
                    </h5>
                  </div>

                  <div className="results_innr">
                    <div className="results_item">
                      <div className="results_para">
                        <h3>
                          <span
                            className="placeholder"
                            style={{ width: "20%" }}
                          ></span>
                        </h3>
                        <p>
                          <span
                            className="placeholder"
                            style={{ width: "100%" }}
                          ></span>
                          <span
                            className="placeholder"
                            style={{ width: "100%" }}
                          ></span>
                          <span
                            className="placeholder"
                            style={{ width: "100%" }}
                          ></span>
                          <span
                            className="placeholder"
                            style={{ width: "60%" }}
                          ></span>
                        </p>
                      </div>
                    </div>
                    <div className="results_item">
                      <div className="results_para">
                        <h3>
                          <span
                            className="placeholder"
                            style={{ width: "20%" }}
                          ></span>
                        </h3>
                        <p>
                          <span
                            className="placeholder"
                            style={{ width: "100%" }}
                          ></span>
                          <span
                            className="placeholder"
                            style={{ width: "100%" }}
                          ></span>
                          <span
                            className="placeholder"
                            style={{ width: "100%" }}
                          ></span>
                          <span
                            className="placeholder"
                            style={{ width: "60%" }}
                          ></span>
                        </p>
                      </div>
                    </div>
                    <div className="results_item">
                      <div className="results_para">
                        <h3>
                          <span
                            className="placeholder"
                            style={{ width: "20%" }}
                          ></span>
                        </h3>
                        <p>
                          <span
                            className="placeholder"
                            style={{ width: "100%" }}
                          ></span>
                          <span
                            className="placeholder"
                            style={{ width: "100%" }}
                          ></span>
                          <span
                            className="placeholder"
                            style={{ width: "100%" }}
                          ></span>
                          <span
                            className="placeholder"
                            style={{ width: "60%" }}
                          ></span>
                        </p>
                      </div>
                    </div>

                    <div className="results_item">
                      <h3>
                        <span
                          className="placeholder"
                          style={{ width: "20%" }}
                        ></span>
                      </h3>
                      <div className="results_para">
                        <p>
                          <span
                            className="placeholder"
                            style={{ width: "60px" }}
                          ></span>{" "}
                          —
                          <span
                            className="placeholder ms-1"
                            style={{ width: "60px" }}
                          ></span>
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="result_btnArea">
                    <span
                      className="placeholder btn"
                      style={{
                        height: "50px",
                        borderRadius: "30px",
                      }}
                    ></span>

                    <span
                      className="placeholder"
                      style={{
                        width: "110px",
                        height: "50px",
                        borderRadius: "30px",
                      }}
                    ></span>
                  </div>
                </div>
              </div>
            </div>
          ) : jobList.length > 0 ? (
            <div className="post_item">
              <div className="job_outer">
                <div className="job_leftpnl">
                  {jobList.map((job, index) => {
                    return (
                      <JobListCard
                        key={index}
                        job={job}
                        onJobSelect={onJobSelect}
                      />
                    );
                  })}
                </div>

                {/* ----------- right panel ----------- */}

                <JobLandingRightPanel selectedJobId={selectedJobId} />
              </div>
            </div>
          ) : (
            <div className="empty_access text-center">
              <div className="empty_pic mb-4">
                {" "}
                <img src={assetImages.emptyVector} alt="" />
              </div>
              <div className="empty_text">
                <p className="fs-lg text-gray fw-semibold mb-4">
                  Sorry....! No job available at this moment
                </p>
              </div>
            </div>
          )}

          <JobListFilterPopup
            setFilterEcosystems={setFilterEcosystems}
            setFilterTitle={setFilterTitle}
            setFilterTags={setFilterTags}
            tagList={tagList}
            resetList={resetFilterData}
          />
        </div>
      </div>
    );
  } else {
    return (
      <div className="pnl_mdlpnl">
        <div className="empty_access text-center">
          <div className="empty_pic mb-4">
            {" "}
            <img src={assetImages.emptyVector} alt="" />
          </div>
          <div className="empty_text">
            <p className="fs-lg text-gray fw-semibold mb-4">
              Sorry....! You don't have privilege to see this content
            </p>
          </div>
        </div>
      </div>
    );
  }
};

export default JobLandingMiddlePanel;

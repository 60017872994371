export const getModuleName = (slug = "") => {
  const moduleMap = {
    MOD_OPPORTUNITY: "Opportunity",
    MOD_LOG: "Log",
    MOD_TASK: "Task",
    MOD_CONTACT: "Contact",
    MOD_LEAD: "Lead",
    MOD_PROJECT: "Project",
    MOD_CHALLENGE: "Challenge",
  };

  return moduleMap[slug] || slug;
};

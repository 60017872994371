/* eslint-disable */
import CheckoutDeatils from "components/CheckoutComponent/CheckoutDeatils";
import AuthHeader from "components/common/AuthHeader";
import React, { useContext, useEffect } from "react";

const Checkout = () => {
  return (
    <>
      <header className="aftre_login_header">
        <AuthHeader />
      </header>

      <CheckoutDeatils />
    </>
  );
};

export default Checkout;

import chatTone from "assets/audio/chat_tone03.mp3";

export const getDateFormatInSpanish = (dateString) => {
  if (dateString && dateString !== "") {
    const parts = dateString.split("-");
    const formattedDate = `${parts[2]}/${parts[1]}/${parts[0]}`;
    return formattedDate; // Output: 17/10/2018
  } else {
    return "";
  }
};

export const getUpdatedConvertedDate = (dateString) => {
  if (dateString && dateString !== "") {
    const createdAt = new Date(dateString);

    // Formatting date
    const formattedDate = createdAt.toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });

    // Formatting time
    const formattedTime = createdAt.toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
    });

    // Adjusting time format to AM/PM
    const amPmTime = formattedTime.replace(
      /^(\d{1,2}:\d{2}) (AM|PM)$/,
      "$1 $2"
    );

    // Combining date and time
    const formattedDateTime = `${formattedDate} at ${amPmTime}`;

    return formattedDateTime; // Output: 05/03/2024 at 01:04 PM
  } else {
    return "";
  }
};

export const formatNumber = (number = 0) => {
  return new Intl.NumberFormat("de-DE").format(number);
};

export const generateUniqueSlug = () => {
  const words = [
    "apple",
    "banana",
    "cherry",
    "date",
    "elderberry",
    "fig",
    "grape",
    "honeydew",
    "kiwi",
    "lemon",
    "mango",
    "nectarine",
    "orange",
    "papaya",
    "quince",
    "raspberry",
    "strawberry",
    "tangerine",
    "ugli",
    "vanilla",
    "watermelon",
    "xigua",
    "yam",
    "zucchini",
  ];

  const getRandomWord = () => words[Math.floor(Math.random() * words.length)];

  const slug = `${getRandomWord()}-${getRandomWord()}-${getRandomWord()}-${getRandomWord()}`;
  return slug;
};

export const playAudioTone = () => {
  new Audio(chatTone).play();
};

export const languageList = [
  {
    flagimageurl: "/images/flag1.svg",
    label: "EN",
    value: "en",
  },
  {
    flagimageurl: "/images/flag2.svg",
    label: "ES",
    value: "es",
  },
  {
    flagimageurl: "/images/flag3.svg",
    label: "CAT",
    value: "cat",
  },
];

// Function to add days to a given date
export const getNextDate = (dateStr = "", daysToAdd = 0) => {
  if (dateStr !== "") {
    // Parse the input date string
    const date = new Date(dateStr);

    // Add the number of days
    date.setDate(date.getDate() + daysToAdd);

    // Format the date as YYYY-MM-DD
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const day = String(date.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  } else {
    return "";
  }
};

export const todayValue = new Date().toISOString().split("T")[0];

export const getNextSevenDays = () => {
  const nextSevenDays = [];
  const currentDate = new Date();

  for (let i = 0; i < 7; i++) {
    const nextDate = new Date(currentDate);
    nextDate.setDate(currentDate.getDate() + i); // Add "i" days to the current date

    // Format to YYYY-MM-DD
    const year = nextDate.getFullYear();
    const month = String(nextDate.getMonth() + 1).padStart(2, "0"); // Months are 0-based
    const day = String(nextDate.getDate()).padStart(2, "0");

    nextSevenDays.push(`${year}-${month}-${day}`);
  }

  return nextSevenDays;
};

export const getDayAbbreviation = (dateString = "") => {
  const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  const date = new Date(dateString);
  return days[date.getDay()];
};

export const ecosystemSlug = localStorage.getItem("ecosystemslug") || "LOBEES";

export const storeOwnerEmail = "admin@mpforall.com";

export const ckEditorConfig = (placeholderText = "") => {
  return {
    toolbar: [
      "bold",
      "italic",
      "|",
      "bulletedList",
      "numberedList",
      "|",
      "outdent",
      "indent",
      "|",
      "undo",
      "redo",
    ],
    placeholder: placeholderText,
    shouldNotGroupWhenFull: false,
  };
};

/* eslint-disable */

import React, { useState, useEffect } from "react";
import Select from "react-select";
import { Link } from "react-router-dom";
// import use translation ------------------------------
import { useTranslation } from "react-i18next";
import LoginModal from "./Modals/LoginModal";
import RegisterModal from "./Modals/RegisterModal";
import VideoModal from "./Modals/VideoModal";
import ForgotPasswordModal from "./Modals/ForgotPasswordModal";
import ResetPassowordModal from "./Modals/ResetPassowordModal";
import CongratsModal from "./Modals/CongratsModal";
import OtpConfirmLoginModal from "./Modals/OtpConfirmLoginModal";
import OtpValidateModal from "./Modals/OtpValidateModal";
import LoginSuccessModal from "./Modals/LoginSuccessModal";

//import languages ------------------------------------------------
import languageOptions from "../../Data/Language.json";

const Footer = () => {
  const { i18n, t } = useTranslation(); // translation marker
  const [languageValue, setLanguageValue] = useState(null);

  //function for select language
  const languageSelectionHandler = (val) => {
    setLanguageValue(val);
    i18n.changeLanguage(val.value);
  };

  useEffect(() => {
    for (let language of languageOptions) {
      if (i18n.language.toString() === language.value.toString()) {
        languageSelectionHandler(language);
      }
    }
  }, []);

  return (
    <footer>
      

      {/* ==== all modals render here ==== */}
      {/* login modal */}
      <LoginModal />

      {/* login success modal */}
      <LoginSuccessModal />

      {/* otplogin modal for login with otp */}
      <OtpConfirmLoginModal />

      {/* sign up modal */}
      <RegisterModal />

      {/* forgot password modal */}
      <ForgotPasswordModal />

      {/* otpvalidation modal for confirm email with otp input */}
      <OtpValidateModal />

      {/* reset password modal */}
      <ResetPassowordModal />

      {/* congrats modal */}
      <CongratsModal />

      {/* video modal in home */}
      <VideoModal />
    </footer>
  );
};

export default Footer;

/* eslint-disable */
import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";

import * as url from "helper/UrlHelper";

import { assetImages } from "constants";
import ChatLeftPanelPlaceholder from "../ConversationLeftPanel/Placeholder/ChatLeftPanelPlaceholder";

const TaskModal = ({
  myTasks = [],
  chatSelectionHandler = () => {},
  taskLoading = false,
  glowPlaceholderCount = [],
}) => {
  const [searchValue, setSearchValue] = useState("");

  //function for set filter leads
  const filterSearchGroups = () => {
    return myTasks.filter((task) => {
      const searchTerm = searchValue.toLowerCase().trim();
      const nameMatch = task.contactname.toLowerCase().includes(searchTerm);

      return nameMatch;
    });
  };

  return (
    <div className="chat_modalbx">
      <div
        className="modal fade"
        id="task_modal"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header mdl_hdng">
              <h5 className="modal-title">Start New Task Chat</h5>

              {/* <!-- ========== Start modal close button Section ========== --> */}
              <button
                type="button"
                className="close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span className="material-icons-round">close </span>
              </button>
              {/* <!-- ========== End modal close button Section ========== --> */}
            </div>
            <div className="modal-body">
              {/* <!-- ========== Start search by input Section ========== --> */}
              <div className="group_srchfill">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search for task"
                  value={searchValue}
                  onChange={(e) => setSearchValue(e.target.value)}
                />
              </div>
              {/* <!-- ========== End search by input Section ========== --> */}

              {/* <!-- ========== Start group list Section ========== --> */}
              <div className="group_innrScroll">
                {taskLoading
                  ? glowPlaceholderCount.map((count, index) => {
                      return <ChatLeftPanelPlaceholder key={index} />;
                    })
                  : myTasks.map((task, index) => {
                      return (
                        <div
                          className={
                            filterSearchGroups().includes(task)
                              ? "chat_usrRow group_msg_row cursor-pointer"
                              : "d-none"
                          }
                          key={index}
                        >
                          <Link
                            to="#"
                            data-bs-dismiss="modal"
                            onClick={() => {
                              chatSelectionHandler(
                                task.userid,
                                task.contactname,
                                task.contactimage,
                                task.contactemail,
                                "task",
                                task._id,
                                task.contactphone,
                                task.location,
                                task.contactcompany,
                                "",
                                "",
                                task.moderatorname,
                                task.moderatorimage,
                                task.groupmemberlist,
                                task.uploads,
                                task.moderatorid,
                                task.groupmemberstring,
                                null
                              );
                            }}
                          >
                            <div className="usr_lftPnl">
                              <div className="chatting_user">
                                {task.contactimage === "" ? (
                                  <>
                                    <figure className="group_usr">
                                      <img
                                        src={
                                          task.moderatorimage === ""
                                            ? assetImages.defaultUser
                                            : url.SERVER_URL +
                                              task.moderatorimage
                                        }
                                        alt=""
                                      />
                                    </figure>
                                    {task.groupmemberlist
                                      .slice(0, 2)
                                      .map((member, index2) => {
                                        return (
                                          <figure
                                            className="group_usr"
                                            key={index2}
                                          >
                                            <img
                                              src={
                                                member.groupmemberimage === ""
                                                  ? assetImages.defaultUser
                                                  : url.SERVER_URL +
                                                    member.groupmemberimage
                                              }
                                              alt=""
                                            />
                                          </figure>
                                        );
                                      })}
                                    {task.groupmemberlist.length + 1 > 3 ? (
                                      <span className="group_usr plus_usr">
                                        {task.groupmemberlist.length + 1 - 3}{" "}
                                        <em>+</em>
                                      </span>
                                    ) : null}
                                  </>
                                ) : (
                                  <figure className="">
                                    <img
                                      className="rounded-circle"
                                      height={40}
                                      width={40}
                                      src={url.SERVER_URL + task.contactimage}
                                      alt=""
                                    />{" "}
                                  </figure>
                                )}
                              </div>
                              <div className="user_name">
                                <h3>{task.contactname}</h3>
                                <p>
                                  {task.moderatorname}, {task.groupmemberstring}
                                </p>
                              </div>
                            </div>
                            {/* <div className="members_check">
                            <span></span>
                          </div> */}
                          </Link>
                        </div>
                      );
                    })}
              </div>
              {/* <!-- ========== End group list Section ========== --> */}

              {/* <!-- ========== Start create button Section ========== --> */}
              {/* <div className="group_btnOuter">
                <ul>
                  <li className="w_100">
                    <Link
                      to="#"
                      className="blue_btn"
                      data-bs-dismiss="modal"
                      data-bs-toggle="modal"
                      data-bs-target="#group_people_mdl"
                    >
                      <i className="material-icons-round">add_circle</i>
                      <span>Create New</span>
                    </Link>
                  </li>
                  
                </ul>
              </div> */}
              {/* <!-- ========== End create button Section ========== --> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TaskModal;

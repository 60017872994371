/* eslint-disable */
import AuthHeader from "components/common/AuthHeader";
import MarketPlaceListBody from "components/MarketPlaceComponents/List/MarketPlaceListBody";

const MarketPlaceList = () => {
  return (
    <>
      <AuthHeader moduleName={"marketplace"} />
      <MarketPlaceListBody />
    </>
  );
};

export default MarketPlaceList;

/* eslint-disable */
import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";

// import use translation ------------------------------
import { useTranslation } from "react-i18next";

//import context
// import { GlobalProvider } from "context/GlobalContext";

/*import url and gateway methods */
import { getData } from "utils/Gateway";
import * as url from "helper/UrlHelper";

import EventListPlaceHolder from "./PlaceHolder/EventListPlaceHolder";
import EventListCard from "./Card/EventListCard";
import EventListFilterPopup from "../Popup/EventListFilterPopup";
import { assetImages } from "constants";
import { getAllCategories } from "helper/CategoryHelper/CategoryHelper";
import { ecosystemSlug } from "Config/Config";

const EventLandingMiddlePanel = () => {
  // const { screenHeight } = useContext(GlobalProvider);
  const token = localStorage.getItem("token");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const moduleAccess = localStorage.getItem("moduleaccess");

  const { t } = useTranslation(); // translation marker

  const placeHolderCount = [1, 2];

  const [eventTypeList, seteventTypeList] = useState([]);
  const [tagList, setTagList] = useState([]);
  const [categoryList, setcategoryList] = useState([]);
  const [searchingList, setsearchingList] = useState([]);
  const [interestList, setinterestList] = useState([]);

  const [eventList, seteventList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [filterRating, setFilterRating] = useState("");
  const [filterDateRange, setFilterDateRange] = useState("");
  const [filterEventTitle, setFilterEventTitle] = useState("");
  const [filterEventType, setFilterEventType] = useState([]);
  const [filterEventCategories, setfilterEventCategories] = useState([]);
  const [filterEventInterests, setfilterEventInterests] = useState([]);
  const [filterEventSearchings, setfilterEventSearchings] = useState([]);
  const [filterEventTags, setFilterEventTags] = useState([]);
  const [filterEventCity, setfilterEventCity] = useState("");
  const [filterEventCountry, setfilterEventCountry] = useState("");

  const [params, setParams] = useState(null);
  const [searchedText, setsearchedText] = useState("");
  const [hideNextButton, sethideNextButton] = useState(false);

  const [reloadData, setReloadData] = useState(false);

  //function for get all event type
  const getEventTypes = async () => {
    try {
      let requestUrl = url.API_BASE_URL + url.API_GET_ALL_EVENT_TYPES;

      const response = await getData(requestUrl);

      if (response.status) {
        seteventTypeList(response.data);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for get all category
  const fetchAllEventCategories = async () => {
    try {
      const parentSlug = "EVENT";
      const response = await getAllCategories(ecosystemSlug, parentSlug);

      setcategoryList(response);
    } catch (error) {
      console.log(error.message);
    }
  };

  const fetchAllSearchingInterest = async () => {
    try {
      const parentSlug = "skills";
      const response = await getAllCategories(ecosystemSlug, parentSlug);

      setsearchingList(response);
      setinterestList(response);
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for get all tags
  const getAllTags = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL + url.API_GET_ALL_TAGS + `?token=${token}`;

      const response = await getData(requestUrl);

      // console.log(response);

      if (response.status) {
        setTagList(response.data);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //get eventlist function
  const getAllEvent = async (start, limit, sortingBy) => {
    try {
      setIsLoading(true);
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_ALL_EVENTS +
        `?token=${token}&start=${start}&limit=${limit}&sortingby=${sortingBy}`;

      if (filterEventTitle != "") {
        requestUrl = requestUrl + `&filtertitle=${filterEventTitle}`;
      }
      if (filterEventCity != "") {
        requestUrl = requestUrl + `&filtercity=${filterEventCity}`;
      }
      if (filterEventCountry != "") {
        requestUrl = requestUrl + `&filtercountry=${filterEventCountry}`;
      }
      if (filterDateRange != "") {
        requestUrl = requestUrl + `&filterdates=${filterDateRange}`;
      }
      if (filterRating != "") {
        requestUrl = requestUrl + `&filterrating=${filterRating}`;
      }
      if (filterEventTags.length > 0) {
        requestUrl = requestUrl + `&filtertags=${filterEventTags}`;
      }
      if (filterEventType.length > 0) {
        requestUrl = requestUrl + `&filtertype=${filterEventType}`;
      }
      if (filterEventCategories.length > 0) {
        requestUrl = requestUrl + `&filtercategory=${filterEventCategories}`;
      }
      if (filterEventInterests.length > 0) {
        requestUrl = requestUrl + `&filterinterest=${filterEventInterests}`;
      }
      if (filterEventSearchings.length > 0) {
        requestUrl = requestUrl + `&filtersearchings=${filterEventSearchings}`;
      }

      // console.log("request url for event list----->", requestUrl);

      const response = await getData(requestUrl);

      console.log(response);

      setIsLoading(false);
      if (response.status) {
        seteventList(response.data);

        if (response.data.length < limit) {
          sethideNextButton(true);
        }
        // resetFilterData();
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for reset filter
  const resetFilterData = () => {
    setFilterRating("");
    setFilterDateRange("");
    setFilterEventTitle("");
    setfilterEventCity("");
    setfilterEventCountry("");
    setFilterEventTags([]);
    setFilterEventType([]);
    setfilterEventCategories([]);
    setfilterEventInterests([]);
    setfilterEventSearchings([]);
  };

  const resetList = () => {
    sethideNextButton(false);
    setsearchedText("");
    setReloadData(true);
    // getAllEvent("0", "10", null);
  };

  //use effect for filter search
  useEffect(() => {
    if (
      filterDateRange != "" ||
      filterEventTitle != "" ||
      filterEventCity != "" ||
      filterEventCountry != "" ||
      filterRating != "" ||
      filterEventType.length > 0 ||
      filterEventCategories.length > 0 ||
      filterEventInterests.length > 0 ||
      filterEventSearchings.length > 0 ||
      filterEventTags.length > 0
    ) {
      getAllEvent("0", "10", null);
    }
  }, [
    filterDateRange,
    filterEventTitle,
    filterEventType,
    filterRating,
    filterEventCategories,
    filterEventInterests,
    filterEventSearchings,
    filterEventTags,
    filterEventCity,
    filterEventCountry,
  ]);

  useEffect(() => {
    if (reloadData) {
      getAllEvent("0", "10", null);
      setReloadData(false);
    }
  }, [reloadData]);

  //use effect for params
  useEffect(() => {
    if (params?.sortby) {
      getAllEvent("0", "10", params?.sortby);
    }
  }, [params]);

  useEffect(() => {
    getEventTypes();
    getAllTags();
    fetchAllEventCategories();
    fetchAllSearchingInterest();
    getAllEvent("0", "10", null);
  }, []);

  if (
    userInfo.role.slug === "ADMIN" ||
    userInfo.role.slug === "SUPER_ADMIN" ||
    moduleAccess.includes("MOD_EVENT")
  ) {
    return (
      <div className="pnl_mdlpnl">
        {/* <!-- ========== Start filter search Section ========== --> */}
        <div className="white_shadow_bx px-3 py-2 rounded-10 bg-white d-flex align-items-center justify-content-between mb-4 gap-3 filter_srch_item">
          <div className="filter_search_wrap d-flex align-items-center gap-3 w-100 p-3">
            <div className="dropdown">
              <Link
                to="#"
                className="dropdown-toggle text-gray"
                data-bs-toggle="dropdown"
              >
                Sort by
              </Link>
              <ul className="dropdown-menu px-2">
                {/* <li className="mb-2">
                  <Link
                    to="#"
                    className="dropdown-item d-flex align-items-center gap-2 text-gray"
                    onClick={() => {
                      setParams({
                        sortby: "desc",
                      });
                    }}
                  >
                    {t("Rank")}
                    <i className="material-icons-outlined fs-sm">
                      arrow_upward
                    </i>
                  </Link>
                </li> */}
                {/* <li className="mb-2">
                  <Link
                    to="#"
                    className="dropdown-item d-flex align-items-center gap-2 text-gray"
                    onClick={() => {
                      setParams({
                        sortby: "asc",
                      });
                    }}
                  >
                    {t("Rank")}
                    <i className="material-icons-outlined fs-sm">
                      arrow_downward
                    </i>
                  </Link>
                </li> */}
                <li>
                  <Link
                    to="#"
                    className="dropdown-item d-flex align-items-center gap-2 text-gray"
                    onClick={() => {
                      setParams({
                        sortby: "name",
                      });
                    }}
                  >
                    {t("Name")} (A-Z)
                  </Link>
                </li>
              </ul>
            </div>

            <div className="search_filter_form d-flex align-items-center justify-content-between w-100">
              <input
                type="text"
                className="form-control"
                placeholder="Search by event title"
                value={searchedText}
                onChange={(e) => {
                  setsearchedText(e.target.value);
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    setFilterEventTitle(searchedText);
                  }
                }}
              />
              <button
                className="filter_srch_btn"
                onClick={() => {
                  setFilterEventTitle(searchedText);
                }}
              >
                <i className="material-icons-outlined">search</i>
              </button>
            </div>
          </div>
          <div className="filter_refresh_btn d-flex align-items-center gap-3">
            <Link
              to="#"
              className="d-block refresh_iocn"
              onClick={() => {
                resetFilterData();
                resetList();
              }}
            >
              <i className="material-icons-outlined">refresh</i>
            </Link>
            <Link
              to="#"
              data-bs-toggle="offcanvas"
              data-bs-target="#event_filter_offcanvas"
              role="button"
              aria-controls="event_filter_offcanvas"
              className="d-flex align-items-center gap-2"
            >
              <i className="material-icons-outlined">tune</i>
              <span className="d-block">Filter</span>
            </Link>
          </div>
        </div>
        {/* <!-- ========== End filter search Section ========== --> */}

        <div className="post_item_outer">
          <div className="post_item">
            {isLoading ? (
              <div className="activity_innr">
                {placeHolderCount.map((count, index) => {
                  return <EventListPlaceHolder key={index} />;
                })}
              </div>
            ) : eventList.length > 0 ? (
              <div className="activity_innr">
                {eventList.map((eventData, index) => {
                  return <EventListCard eventData={eventData} key={index} />;
                })}
              </div>
            ) : (
              <div className="empty_access text-center">
                <div className="empty_pic mb-4">
                  {" "}
                  <img src={assetImages.emptyVector} alt="" />
                </div>
                <div className="empty_text">
                  <p className="fs-lg text-gray fw-semibold mb-4">
                    Sorry....! No events availbale at this moment
                  </p>
                </div>
              </div>
            )}
          </div>
        </div>

        {/* <!-- ========== Start next page button Section ========== --> */}
        {/* {hideNextButton ? (
          <div className="d-flex align-items-center gap-3 mt-3">
            <p className="fw-semibold text-black-gray">
              Sorry! No more data available
            </p>
            <Link
              to="#"
              className="btn outline"
              onClick={() => {
                resetFilterData();
                resetList();
              }}
            >
              {" "}
              <i className="material-icons-outlined">refresh</i>
            </Link>
          </div>
        ) : (
          <button
            className="mt-3 btn btn-primary"
            onClick={() => {
              getAllAppUsers(
                appUserList.length.toString(),
                "10",
                params?.sortby ? params?.sortby : null
              );
            }}
          >
            Next Page
          </button>
        )} */}
        {/* <!-- ========== End next page button Section ========== --> */}

        <EventListFilterPopup
          eventTypeList={eventTypeList}
          tagList={tagList}
          categoryList={categoryList}
          searchingList={searchingList}
          interestList={interestList}
          setfilterEventCategories={setfilterEventCategories}
          setfilterEventInterests={setfilterEventInterests}
          setfilterEventSearchings={setfilterEventSearchings}
          setfilterEventCity={setfilterEventCity}
          setfilterEventCountry={setfilterEventCountry}
          setFilterDateRange={setFilterDateRange}
          setFilterEventTags={setFilterEventTags}
          setFilterEventType={setFilterEventType}
          setFilterEventTitle={setFilterEventTitle}
          setFilterRating={setFilterRating}
          resetList={resetList}
        />
      </div>
    );
  } else {
    return (
      <div className="pnl_mdlpnl">
        <div className="empty_access text-center">
          <div className="empty_pic mb-4">
            {" "}
            <img src={assetImages.emptyVector} alt="" />
          </div>
          <div className="empty_text">
            <p className="fs-lg text-gray fw-semibold mb-4">
              Sorry....! You don't have privilege to see this content
            </p>
          </div>
        </div>
      </div>
    );
  }
};

export default EventLandingMiddlePanel;

/* eslint-disable */
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import { loadStripe } from "@stripe/stripe-js";

/*------ import url and method ------*/
import * as url from "helper/UrlHelper";
import { getData, postData, putData } from "utils/Gateway";

const CheckoutDeatils = () => {
  const token = localStorage.getItem("token");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const invoiceId = localStorage.getItem("invoiceid"); // mongodb invoice id

  const [orderId, setOrderId] = useState(""); // invoiceid to show from API
  const [totalPrice, setTotalPrice] = useState(0);
  const [totalTax, setTotalTax] = useState(0);
  const [totalDiscount, setTotalDiscount] = useState(0);
  const [totalPayable, setTotalPayable] = useState(0);
  const [currency, setCurrency] = useState("€");
  const [invoiceDate, setInvoiceDate] = useState("");
  const [invoiceStatus, setInvoiceStatus] = useState("0");
  const [addressId, setAddressId] = useState(null);

  const [subscriptionPlan, setSubscriptionPlan] = useState(null);

  const [isLoading, setIsLoading] = useState(false);
  const [cartItems, setCartItems] = useState([]);
  const [stripeProductItems, setStripeProductItems] = useState([]);

  const [billingName, setBillingName] = useState("");
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [address, setAddress] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [mapurl, setMapurl] = useState("");
  const [phone, setPhone] = useState("");

  const [isAdding, setIsAdding] = useState(false);

  //function for company details
  const getInvoiceDetails = async () => {
    try {
      setIsLoading(true);

      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_INVOICE_DETAILS +
        `/${invoiceId}?token=${token}`;

      console.log(requestUrl);

      const response = await getData(requestUrl);

      setIsLoading(false);

      console.log("response", response);

      if (response.data) {
        setOrderId(response.data.orderid);
        setInvoiceDate(response.data.invoicedate);
        setSubscriptionPlan(response.data.plan);

        setTotalPrice(response.data.totalprice);
        setTotalTax(response.data.totaltax);
        setTotalDiscount(response.data.totaldiscount);
        setTotalPayable(response.data.totalpayble);

        setCartItems(response.data.invoiceitems || []);

        setStripeProductItems(response.data.stripecartitems);

        setInvoiceStatus(response.data.status);

        if (response.data.address) {
          setBillingName(response.data.address.name);
          setAddressId(response.data.address._id);
          setAddress(response.data.address.address);
          setCity(response.data.address.city);
          setCountry(response.data.address.country);
          setState(response.data.address.state);
          setZipCode(response.data.address.zipcode);
          setPhone(response.data.address.phone);
          setMapurl(response.data.address.mapurl);
        }
      }
    } catch (error) {
      console.log("Error", error.message);
    }
  };

  // payment gateway through Stripe
  const makePayment = async () => {
    try {
      setIsAdding(true);

      let orderData = {
        status: "1",
      };

      let orderRequestUrl =
        url.API_BASE_URL +
        url.API_UPDATE_INVOICE +
        `/${invoiceId}?token=${token}`;

      const orderUpdateResponse = await putData(orderRequestUrl, orderData);

      if (orderUpdateResponse.status) {
        const orderGetRequestUrl =
          url.API_BASE_URL +
          url.API_GET_INVOICE_DETAILS +
          `/${invoiceId}?token=${token}`;

        const orderGetResponse = await getData(orderGetRequestUrl);

        if (orderGetResponse.status) {
          const invoiceItems = orderGetResponse.data.invoiceitems.map(
            (item) => ({
              productid: item.productid._id || null,
              description: item.description,
              quantity: item.unit,
              price: item.price,
              taxpercent: item.taxpercent,
              tax: item.taxamount,
              discountpercent: item.discountpercent || 0,
              discountamount: item.discountamount || 0,
              amount: item.totalprice || 0,
            })
          );

          const invoiceCopy = {
            addressid: orderGetResponse.data.addressid || null,
            storeowneremail: orderGetResponse.data.storeowneremail || "",
            user: orderGetResponse.data.user,
            clientid: userInfo._id,
            title: orderGetResponse.data.title,
            description: orderGetResponse.data.description,
            promisepercent: orderGetResponse.data.promisepercent,
            typeslug: "invoice",
            modulename: orderGetResponse.data.modulename || "Marketplace",
            items: invoiceItems,
            amount: orderGetResponse.data.amount,
            orderid: orderGetResponse.data.orderid,
          };

          const invoiceRequestUrl =
            url.API_BASE_URL + url.API_CREATE_INVOICE + `?token=${token}`;

          const invoiceResponse = await postData(
            invoiceRequestUrl,
            invoiceCopy
          );

          console.log("invoiceResponse", invoiceResponse);

          if (invoiceResponse.status) {
            localStorage.removeItem("invoiceid");
            localStorage.removeItem("cartitemcount");

            const invoiceResponseGetRequestUrl =
              url.API_BASE_URL +
              url.API_GET_INVOICE_DETAILS +
              `/${invoiceResponse.data._id}?token=${token}`;

            const invoiceGetResponse = await getData(
              invoiceResponseGetRequestUrl
            );

            console.log("make payment");

            let successUrl = url.FRONTEND_BASE_URL + "/payment/success";
            let failureUrl = url.FRONTEND_BASE_URL + "/payment/failure";

            if (invoiceResponse.data._id) {
              successUrl = successUrl + `?ref=${invoiceResponse.data._id}`;
            }

            console.log("userinfo._id", userInfo._id);

            const stripe = await loadStripe(
              "pk_test_51IJm7YL6PquBzXKuvGXoEkT32UbYTvpafT4Wf6naaoNezsrD9Lt1juEzQd4SJzJWscPci5jr2LRbUcUxu5wUu2Pn00kTokCrRA"
            );

            let requestUrl =
              url.API_BASE_URL +
              url.API_CART_CHECKOUT_SESSION +
              `?token=${token}`;

            const paymentInfo = {
              cartItems: invoiceGetResponse.data.stripecartitems,
              userId: userInfo._id,
              invoiceid: invoiceResponse.data._id,
              successurl: successUrl,
              failureurl: failureUrl,
            };

            const response = await postData(requestUrl, paymentInfo);

            setIsAdding(false);

            console.log("response", response);

            if (response.id) {
              stripe.redirectToCheckout({
                sessionId: response.id,
              });
            }
          }
        }
      }
    } catch (error) {
      console.log("error message", error);
    }
  };

  useEffect(() => {
    getInvoiceDetails();
  }, []);

  return (
    <section className="pt-70">
      <div className="container-fluid">
        <div className="py-4 px-3 cart_sec">
          <div className="row">
            <div className="col-lg-8">
              <div
                className={`${
                  isLoading
                    ? "d-none"
                    : "cart_leftpnl px-4 rounded-10 overflow-hidden"
                }`}
              >
                <div className="address_outer">
                  <div className="address_row py-4 d-flex gap-4 align-items-center">
                    <div className="address_radio">
                      <span className="material-icons text-green fs-lg">
                        {" "}
                        radio_button_checked
                      </span>
                    </div>
                    <div className="address_info">
                      <h4 className="text-black fw-semibold mb-2 fs-md">
                        {billingName}
                      </h4>
                      <ul className="list_stye_none d-flex align-items-center gap-2 flex-wrap">
                        <li className="d-flex align-items-center gap-1">
                          <i className="material-icons-outlined fs-lg text-gray">
                            {" "}
                            wifi_calling_3
                          </i>
                          <span className="text-black fs-sm">{phone}</span>
                        </li>
                        <li className="d-flex align-items-center gap-1">
                          <i className="material-icons-outlined fs-lg text-gray">
                            {" "}
                            place
                          </i>
                          <span className="text-black fs-sm">
                            {address}, {zipCode} {city}, {state}, {country}
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="billing_form py-5">
                <div className="row">
                  <div className="col-md-12 mb-3">
                    <div className="form-group mt-3">
                      <Link to="/billings" className="btn">
                        Back
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-4">
              {cartItems.length > 0 ? (
                <div className="cart_rightPnl p-4 bg-white rounded-10 overflow-hidden border-0">
                  <div className="oredr-hdng mb-3">
                    <h4 className="text-gray text-uppercase fs-sm">
                      Order ID : {orderId}
                    </h4>
                  </div>
                  <div className="order_infopnl">
                    <div className="oredr_row d-flex align-items-center justify-content-between gap-2 mb-3">
                      <h5 className="fs-sm text-black mb-0">Total Price</h5>
                      <h5 className="fs-sm text-black mb-0">
                        {totalPrice} {currency}
                      </h5>
                    </div>
                    <div className="oredr_row d-flex align-items-center justify-content-between gap-2 mb-3">
                      <h5 className="fs-sm text-black mb-0">VAT</h5>
                      <h5 className="fs-sm text-gray mb-0">
                        {totalTax}% {currency}
                      </h5>
                    </div>
                    <div className="oredr_row d-flex align-items-center justify-content-between gap-2 mb-3">
                      <h5 className="fs-sm text-black mb-0">Discount</h5>
                      <h5 className="fs-sm  mb-0 text-gray">
                        - {totalDiscount}% {currency}
                      </h5>
                    </div>
                    <div className="oredr_row d-flex align-items-center justify-content-between gap-2 pt-3">
                      <h5 className="fs-sm text-black mb-0">Total Payable</h5>
                      <h3 className="fs-xl  mb-0 text-black fw-semibold">
                        {totalPayable} {currency}
                      </h3>
                    </div>
                  </div>
                  <div className={`${addressId ? "mt-4" : "d-none"}`}>
                    <button
                      className="btn w-100"
                      onClick={makePayment}
                      disabled={isAdding ? true : false}
                      style={{
                        cursor: isAdding ? "not-allowed" : "pointer",
                      }}
                    >
                      Continue Payment
                      {isAdding && (
                        <div
                          className="mx-2 spinner-border spinner-border-sm"
                          role="status"
                        >
                          <span className="visually-hidden">Loading...</span>
                        </div>
                      )}
                    </button>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CheckoutDeatils;

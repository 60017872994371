/* eslint-disable */
import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";

// import use translation ------------------------------
import { useTranslation } from "react-i18next";

/*import url and gateway methods */
import { getData, putData } from "utils/Gateway";
import * as url from "helper/UrlHelper";

import AuthContactPanel from "components/common/AuthContactPanel";
import SaveFaqModal from "./Modal/SaveFaqModal";
import FaqFilterPopup from "./Popup/FaqFilterPopup";
import FaqPlaceholder from "./PlaceHolder/FaqPlaceholder";

const FaqListBody = () => {
  const { t } = useTranslation();

  // user information
  const token = localStorage.getItem("token");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  const limit = 10;

  const glowCount = [1, 2, 3, 4, 5, 6];

  const faqListRef = useRef(null);

  // states for faq search result
  const [isLoading, setIsLoading] = useState(false);
  const [faqList, setFaqList] = useState([]);
  const [page, setPage] = useState(0);

  // props states for FaqSearch and FaqSearchResult
  const [searchQuery, setSearchQuery] = useState("");

  const [filterStartDate, setfilterStartDate] = useState("");
  const [filterEndDate, setfilterEndDate] = useState("");
  const [filterCategories, setfilterCategories] = useState([]);
  const [filterTags, setfilterTags] = useState([]);

  const [params, setParams] = useState(null);
  const [hideNextButton, sethideNextButton] = useState(false);
  const [reloadList, setReloadList] = useState(false);

  //initial for paginations
  const [totalPages, setTotalPages] = useState([]);
  const [lastPageNo, setLastPageNo] = useState(1);

  //function for blogref toggle
  const faqRefToggle = () => {
    faqListRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  // get request to API to fetch faq data
  const getAllFaqs = async () => {
    setIsLoading(true);
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_FAQ_LIST +
        `?token=${token}&start=${
          page * limit
        }&limit=${limit}&modulefrom=frontend`;

      if (searchQuery.length > 2 && reloadList === false) {
        requestUrl += `&filterquery=${searchQuery}`;
      }

      if (filterStartDate !== "" && filterEndDate !== "") {
        requestUrl += `&filterstartdate=${filterStartDate}&filterenddate=${filterEndDate}`;
      }

      if (filterCategories.length > 0) {
        requestUrl = requestUrl + `&filtercategories=${filterCategories}`;
      }

      if (filterTags.length > 0) {
        requestUrl = requestUrl + `&filtertags=${filterTags}`;
      }

      if (params) {
        requestUrl = requestUrl + `&sortingby=${params?.sortby}`;
      }

      console.log("requestUrl", requestUrl);

      const response = await getData(requestUrl);

      setIsLoading(false);

      console.log("FAQ data response", response);

      if (response.status) {
        if (page === 0) {
          setFaqList(response.data);
        } else {
          setFaqList((faqList) => [...faqList, ...response.data]);
        }

        if (response.data.length < limit) {
          sethideNextButton(true);
        } else {
          sethideNextButton(false);
        }
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const resetFilter = () => {
    setPage(0);
    setParams(null);
    setSearchQuery("");
    setfilterStartDate("");
    setfilterEndDate("");
    setfilterCategories([]);
    setfilterTags([]);
    sethideNextButton(false);
    setReloadList(true);
  };

  useEffect(() => {
    if (reloadList) {
      getAllFaqs();
      setReloadList(false); // after reload, set this false
    }
  }, [reloadList]);

  //use effect for filter search
  useEffect(() => {
    if (
      page ||
      params?.sortby ||
      filterStartDate !== "" ||
      filterEndDate !== "" ||
      filterCategories.length > 0 ||
      filterTags.length > 0
    ) {
      getAllFaqs();
    }
  }, [
    page,
    params,
    filterStartDate,
    filterEndDate,
    filterCategories,
    filterTags,
  ]);

  useEffect(() => {
    getAllFaqs();
  }, []);

  return (
    <section className="feed_pnltotal">
      <div className="container-fluid">
        <div className="pnl_outer pnl_list_wrap">
          <div className="pnl_mdlpnl">
            {/* <!-- ========== Start filter search Section ========== --> */}
            <div className="white_shadow_bx px-3 py-2 rounded-10 bg-white d-flex align-items-center justify-content-between mb-4 gap-3 filter_srch_item">
              <div className="filter_search_wrap d-flex align-items-center gap-3 w-100 p-3">
                <div className="dropdown">
                  <Link
                    to="#"
                    className="dropdown-toggle text-gray"
                    data-bs-toggle="dropdown"
                  >
                    Sort by
                  </Link>
                  <ul className="dropdown-menu px-2">
                    <li className="mb-2">
                      <Link
                        to="#"
                        className="dropdown-item d-flex align-items-center gap-2 text-gray"
                        onClick={() => {
                          setParams({
                            sortby: "desc",
                          });
                        }}
                      >
                        {t("Rank")}
                        <i className="material-icons-outlined fs-sm">
                          arrow_upward
                        </i>
                      </Link>
                    </li>
                    <li className="mb-2">
                      <Link
                        to="#"
                        className="dropdown-item d-flex align-items-center gap-2 text-gray"
                        onClick={() => {
                          setParams({
                            sortby: "asc",
                          });
                        }}
                      >
                        {t("Rank")}
                        <i className="material-icons-outlined fs-sm">
                          arrow_downward
                        </i>
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="#"
                        className="dropdown-item d-flex align-items-center gap-2 text-gray"
                        onClick={() => {
                          setParams({
                            sortby: "name",
                          });
                        }}
                      >
                        {t("Name")} (A-Z)
                      </Link>
                    </li>
                  </ul>
                </div>

                <div className="search_filter_form d-flex align-items-center justify-content-between w-100 position-relative">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search by faq"
                    value={searchQuery}
                    onChange={(e) => {
                      setSearchQuery(e.target.value);
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        getAllFaqs();
                      }
                    }}
                  />
                  <button className="filter_srch_btn" onClick={getAllFaqs}>
                    <i className="material-icons-outlined">search</i>
                  </button>
                </div>
              </div>
              <div className="filter_refresh_btn d-flex align-items-center gap-3">
                <Link
                  to="#"
                  className="d-block refresh_iocn"
                  onClick={resetFilter}
                >
                  <i className="material-icons-outlined">refresh</i>
                </Link>
                <Link
                  to="#"
                  data-bs-toggle="offcanvas"
                  data-bs-target="#faq_filter_offcanvas"
                  role="button"
                  aria-controls="faq_filter_offcanvas"
                  className="d-flex align-items-center gap-2"
                >
                  <i className="material-icons-outlined">tune</i>
                  <span className="d-block">Filter</span>
                </Link>

                <Link
                  to="#"
                  data-bs-toggle="modal"
                  data-bs-target="#questionFaqModal"
                  className="btn btn-sm ask_btn text-black"
                >
                  Ask Questions
                </Link>
              </div>
            </div>
            {/* <!-- ========== End filter search Section ========== --> */}

            {/* <div className="d-flex align-items-center justify-content-end mb-2">
              <p>Found {faqList.length} result </p>
            </div> */}

            <div className="post_item_outer sameheight_scroll">
              {isLoading ? (
                <div className="post_item">
                  {glowCount.map((item, index) => {
                    return <FaqPlaceholder key={index} />;
                  })}
                </div>
              ) : (
                <div className="post_item">
                  {faqList.length > 0 &&
                    faqList.map((faq, index) => {
                      return (
                        <div className="people_row groups_item" key={index}>
                          <div className="d-flex justify-content-between gap-3 align-items-center corporation_wrapper w-100 flex-wrap">
                            <div className="corporation_left">
                              <h3 className="fs-lg text-black mb-3 fw-semibold">
                                {faq.question}
                              </h3>
                              <div className="event_tags d-flex align-items-center gap-2  flex-wrap mb-3">
                                {/* <span className="bg-light text-primary fs-sm rounded-90 px-3 py-2">
                                  Groups
                                </span> */}
                                <label
                                  className={
                                    faq.categorylist.length > 0
                                      ? "text-gray fw-semibold mb-0"
                                      : "d-none"
                                  }
                                >
                                  Categories
                                </label>
                                <ul
                                  className={
                                    faq.categorylist.length > 0
                                      ? "list_stye_none mb-0"
                                      : "d-none"
                                  }
                                >
                                  {faq.categorylist.map((item, index2) => {
                                    return (
                                      <li key={index2}>
                                        <Link to="#">{item.name}</Link>{" "}
                                      </li>
                                    );
                                  })}
                                </ul>
                              </div>
                              <div className="event_tags d-flex align-items-center gap-2  flex-wrap">
                                {/* <span className="bg-light text-primary fs-sm rounded-90 px-3 py-2">
                                  Groups
                                </span> */}
                                <label
                                  className={
                                    faq.usertags.length > 0
                                      ? "text-gray fw-semibold mb-0"
                                      : "d-none"
                                  }
                                >
                                  Tags
                                </label>
                                <ul
                                  className={
                                    faq.usertags.length > 0
                                      ? "list_stye_none mb-0"
                                      : "d-none"
                                  }
                                >
                                  {faq.usertags.map((item, index2) => {
                                    return (
                                      <li key={index2}>
                                        <Link to="#">{item.title}</Link>{" "}
                                      </li>
                                    );
                                  })}
                                </ul>
                              </div>
                            </div>
                            <div className="corporation_right">
                              <div className="details_btn mt-0">
                                <Link
                                  to={`/faq/details/${faq._id}`}
                                  className="btn outline"
                                >
                                  Open
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>
              )}
            </div>

            {/* <!-- ========== Start next page button Section ========== --> */}
            {hideNextButton ? (
              <div className="d-flex align-items-center gap-3 mt-3">
                <p>Found {faqList.length} result </p>
                <p className="fw-semibold text-black-gray">
                  Sorry! No more data available
                </p>
                <div className="filter_refresh_btn d-flex align-items-center gap-3">
                  <Link
                    to="#"
                    className="d-block refresh_iocn"
                    onClick={resetFilter}
                  >
                    <i className="material-icons-outlined">refresh</i>
                  </Link>
                </div>
              </div>
            ) : (
              <div className="d-flex justify-content-end align-items-center gap-2 mt-2">
                <p>Found {faqList.length} result </p>
                <button
                  className="btn"
                  onClick={() => {
                    setPage(page + 1);
                  }}
                >
                  Next
                </button>
              </div>
            )}
            {/* <!-- ========== End next page button Section ========== --> */}

            {/* <div className="d-flex align-items-center justify-content-end mb-2">
              <p>Found {faqList.length} result </p>
            </div> */}

            <FaqFilterPopup
              setfilterStartDate={setfilterStartDate}
              setfilterEndDate={setfilterEndDate}
              setfilterCategories={setfilterCategories}
              setfilterTags={setfilterTags}
            />
          </div>
          <AuthContactPanel />
        </div>
      </div>

      <SaveFaqModal />
    </section>
  );
};

export default FaqListBody;

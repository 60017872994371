/* eslint-disable */
import React, { useEffect } from "react";

import ConversationRightAiPanel from "./ConversationRightAiPanel/ConversationRightAiPanel";

import ConversationRightContactPanel from "./ConversationRightContactPanel/ConversationRightContactPanel";

import ConversationRightWelcomePanel from "./ConversationRightWelcomePanel/ConversationRightWelcomePanel";
import ConversationRightGroupPanel from "./ConversationRightGroupPanel/ConversationRightGroupPanel";
import ConversationRightTaskPanel from "./ConversationRightTaskPanel/ConversationRightTaskPanel";

const ConversationRightPanel = ({
  defaultScreen,
  selectedConversationId,
  conversationName,
  selectedChatLogo = "",
  selectedChatEmail,
  selectedChatRoomId,
  selectedChatPhone,
  selectedChatAddress,
  selectedChatCompany,
  groupModName = "",
  groupModImage = "",
  groupMembers = [],
  groupFiles = [],
  groupModId = null,
  groupMemberString = "",

  chatcomponentSlug,
  setchatcomponentSlug = () => {},
  chatComponentTitle,
  setchatComponentTitle = () => {},
  chatComponentId = null,
  setchatComponentId = () => {},
  setreloadLeftPanel = () => {},
  socket,
  allContacts = [],
}) => {
  // useEffect(() => {
  //   console.log("defaultScreen 2", defaultScreen);
  // }, [defaultScreen]);

  return (
    <div className="chat_rightpnl">
      {defaultScreen === "ai" ? (
        <ConversationRightAiPanel />
      ) : defaultScreen === "contact" ? (
        <ConversationRightContactPanel
          socket={socket}
          selectedContactUserId={selectedConversationId}
          conversationName={conversationName}
          selectedChatLogo={selectedChatLogo}
          selectedChatEmail={selectedChatEmail}
          selectedChatRoomId={selectedChatRoomId}
          selectedChatPhone={selectedChatPhone}
          selectedChatAddress={selectedChatAddress}
          selectedChatCompany={selectedChatCompany}
          chatcomponentSlug={chatcomponentSlug}
          setchatcomponentSlug={setchatcomponentSlug}
          chatComponentTitle={chatComponentTitle}
          setchatComponentTitle={setchatComponentTitle}
          chatComponentId={chatComponentId}
          setchatComponentId={setchatComponentId}
        />
      ) : defaultScreen === "group" ? (
        <ConversationRightGroupPanel
          socket={socket}
          conversationName={conversationName}
          selectedChatLogo={selectedChatLogo}
          selectedChatRoomId={selectedChatRoomId}
          selectedChatAddress={selectedChatAddress}
          chatcomponentSlug={chatcomponentSlug}
          setchatcomponentSlug={setchatcomponentSlug}
          chatComponentTitle={chatComponentTitle}
          setchatComponentTitle={setchatComponentTitle}
          chatComponentId={chatComponentId}
          setchatComponentId={setchatComponentId}
          groupModName={groupModName}
          groupModImage={groupModImage}
          groupMembers={groupMembers}
          groupFiles={groupFiles}
          groupModId={groupModId}
          groupMemberString={groupMemberString}
          allContacts={allContacts}
        />
      ) : defaultScreen === "task" ? (
        <ConversationRightTaskPanel
          socket={socket}
          conversationName={conversationName}
          selectedChatLogo={selectedChatLogo}
          selectedChatRoomId={selectedChatRoomId}
          selectedChatAddress={selectedChatAddress}
          chatcomponentSlug={chatcomponentSlug}
          setchatcomponentSlug={setchatcomponentSlug}
          chatComponentTitle={chatComponentTitle}
          setchatComponentTitle={setchatComponentTitle}
          chatComponentId={chatComponentId}
          setchatComponentId={setchatComponentId}
          groupModName={groupModName}
          groupModImage={groupModImage}
          groupMembers={groupMembers}
          groupFiles={groupFiles}
          groupModId={groupModId}
          groupMemberString={groupMemberString}
          allContacts={allContacts}
        />
      ) : defaultScreen === "welcome" ? (
        <ConversationRightWelcomePanel />
      ) : (
        ""
      )}
    </div>
  );
};

export default ConversationRightPanel;

/* eslint-disable */
import { useState, useEffect } from "react";

//**------ import url and method ------*/
import * as url from "helper/UrlHelper";
import { getData, postData, putData, uploadMultipleFile } from "utils/Gateway";

const UpdateIdea = ({
  idea,
  receiverId,
  challengeId,
  challengeResponseId,
  setIsIdeaSaved,
  showIdea,
  setShowIdea,
}) => {
  const token = localStorage.getItem("token");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  const [ideaid, setIdeaid] = useState(null);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [attachments, setAttachments] = useState([]);
  const [uploadedImageIds, setUploadedImageIds] = useState([]);

  const [isUploading, setIsUploading] = useState(false);

  useEffect(() => {
    console.log("idea", idea);
    console.log("showidea", showIdea);

    if (showIdea) {
      if (idea && idea._id) {
        setIdeaid(idea._id);
        setTitle(idea.title);
        setDescription(idea.description);

        if (idea.uploads && idea.uploads.length > 0) {
          setUploadedImageIds(idea.uploads.map((file) => file._id));

          setAttachments(
            idea.uploads.map((file) => ({
              name: file.name,
              path: url.SERVER_URL + file.path,
            }))
          );
        } else {
          setUploadedImageIds([]);
          setAttachments([]);
        }
      } else {
        resetIdea();
      }
    }
  }, [showIdea]);

  // useEffect(() => {
  //   console.log('resetting idea');
  //   resetIdea();
  // }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const ideaData = {
      id: ideaid,
      title,
      description,
      uploads: uploadedImageIds,
      receiver: receiverId,
      challenge: challengeId,
      challengeresponse: challengeResponseId,
    };

    console.log("ideaData", ideaData);

    try {
      let requestUrl = url.API_BASE_URL;

      // if ideaId present then update idea else add idea
      if (ideaid) {
        requestUrl += url.API_UPDATE_IDEA + `/${ideaid}?token=${token}`;
      } else {
        requestUrl += url.API_ADD_IDEA + `?token=${token}`;
      }

      const response = await postData(requestUrl, ideaData);

      console.log("idea save response", response);

      console.log("Response Status:", response.status);

      if (response.status) {
        // close saveIdeaOffcanvas
        setTimeout(() => {
          console.log("closing offcanvas");

          const offcanvasElement = document.getElementById("saveIdeaOffcanvas");
          const bsOffcanvas = bootstrap.Offcanvas.getInstance(offcanvasElement);
          if (bsOffcanvas) bsOffcanvas.hide();
        }, 100);

        setFetchingData(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  //function for image upload
  const imageUploadHandler = async (e) => {
    const file = e.target.files;
    setIsUploading(true);
    try {
      const requestUrl =
        url.API_BASE_URL + url.API_MULTIPLE_FILE_UPLOAD + `?token=${token}`;

      const response = await uploadMultipleFile(requestUrl, file);

      console.log("upload response-------->", response);

      setIsUploading(false);

      if (response.status) {
        /*----- assign uploaded files with path for display start ------*/
        const uploadedImageValues = response.data.map(
          ({ name, path, _id, ...other }) => ({
            name,
            path: path,
            _id,
          })
        );

        console.log("uploaded images values", uploadedImageValues);

        setAttachments(uploadedImageValues);

        setUploadedImageIds(response.data.map((file) => file._id));
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  const resetIdea = () => {
    setIdeaid(null);
    setTitle("");
    setDescription("");
    setAttachments([]);
    setUploadedImageIds([]);
  };

  return (
    <div
      className="offcanvas offcanvas-end  offcanvas-lg"
      tabIndex="-1"
      id="updateIdeaOffcanvas"
    >
      <div className="offcanvas-header">
        <h3>Suggested Idea</h3>
        <button
          type="button"
          className="btn-close"
          data-bs-dismiss="offcanvas"
          onClick={() => {
            setShowIdea(false);
          }}
        ></button>
      </div>
      <div className="offcanvas-body">
        {/* if idea as id then check status and show status below */}
        {/* {idea && (
          <div className="mb-3">
            <label className="form-label">Status</label>
            <span className="badge bg-primary ms-3">
              Read : {idea.read ? "Yes" : "No"}
            </span>
            <span className="badge bg-primary ms-3">
              Accepted : {idea.accepted ? "Yes" : "No"}
            </span>
          </div>
        )} */}

        <form onSubmit={handleSubmit}>
          <div className="mb-3">
            <label className="form-label">Idea Title</label>
            <input
              type="text"
              name="title"
              className="form-control"
              value={title}
              //onChange={(e) => setTitle(e.target.value)}
              required
              disabled
            />
          </div>

          <div className="mb-3">
            <label className="form-label">Describe your idea in details</label>
            <textarea
              name="description"
              className="form-control"
              value={description}
              //onChange={(e) => setDescription(e.target.value)}
              required
              disabled
              rows="4"
            ></textarea>
          </div>

          <div className="mb-3">
            <label className="form-label">Attachments (if any)</label>
            {/* <input
              type="file"
              id="doc_file_input"
              className=""
              onChange={imageUploadHandler}
              multiple
            /> */}
          </div>

          {/* Preview uploaded images as thumbnail*/}
          <div className="mb-3 row attachments">
            {attachments &&
              attachments.map((file, index) => (
                <div className="col-4" key={index}>
                  <div className="card">
                    {/* if file.path has image extension then only show image else show file placeholder */}
                    {file.path.match(/\.(jpeg|jpg|gif|png)$/) ? (
                      <img
                        src={url.SERVER_URL + "/" + file.path}
                        className="card-img-top"
                        alt="..."
                      />
                    ) : (
                      <i className="material-icons-outlined">file_copy</i>
                    )}

                    <div className="card-body">
                      <h5 className="card-title">{file.name}</h5>
                      <a
                        href={url.SERVER_URL + file.path}
                        target="_blank"
                        download
                      >
                        <i className="material-icons-outlined">download</i>
                      </a>
                    </div>
                  </div>
                </div>
              ))}
          </div>

          {/* <div className="d-flex justify-content-end mb-3">
            {isUploading ? (
              <button className="btn outline " disabled>
                <i className="fas fa-spinner fa-spin"></i> Uploading...
              </button>
            ) : (
              <button type="submit" className="btn outline">
                {idea ? "Update" : "Send"}
              </button>
            )}
          </div> */}
        </form>
      </div>
    </div>
  );
};

export default UpdateIdea;

/* eslint-disable */
import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";

// import use translation ------------------------------
import { useTranslation } from "react-i18next";

/*------ import url and method ------*/
import * as url from "helper/UrlHelper";
//import { postData } from "utils/Gateway";

const ShowReviewModal = ({ reviewData, showReview, setShowReview }) => {
  const { t } = useTranslation(); // translation marker
  const token = localStorage.getItem("token");

  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const params = useParams();

  // initial useState to save the comment
  const [comments, setComments] = useState("");

  // initial useState to save star rating
  const [starRating, setstarRating] = useState("1");

  /* ------------ Initial useStates for activating the starts starts here ------------ */
  const [activeFirstStar, setActiveFirstStar] = useState(true);
  const [activeSecondStar, setActiveSecondStar] = useState(false);
  const [activeThirdStar, setActiveThirdStar] = useState(false);
  const [activeFourthStar, setActiveFourthStar] = useState(false);
  const [activeFiveStar, setActiveFifthStar] = useState(false);
  const [ratingError, setRatingError] = useState(false);
  const [commentError, setCommentError] = useState(false);
  const [isReviewPosting, setIsReviewPosting] = useState(false);

  /*Initial useStates for activating the starts end*/

  // function to display the star ratings
  const highlightStars = (value) => {
    setActiveFirstStar(false);
    setActiveSecondStar(false);
    setActiveThirdStar(false);
    setActiveFourthStar(false);
    setActiveFifthStar(false);

    if (value == "1") {
      setActiveFirstStar(true);
    }

    if (value == "2") {
      setActiveFirstStar(true);
      setActiveSecondStar(true);
    }

    if (value == "3") {
      setActiveFirstStar(true);
      setActiveSecondStar(true);
      setActiveThirdStar(true);
    }

    if (value == "4") {
      setActiveFirstStar(true);
      setActiveSecondStar(true);
      setActiveThirdStar(true);
      setActiveFourthStar(true);
    }

    if (value == "5") {
      setActiveFirstStar(true);
      setActiveSecondStar(true);
      setActiveThirdStar(true);
      setActiveFourthStar(true);
      setActiveFifthStar(true);
    }
  };

  // function to reset the star and comments
  const resetHandler = () => {
    setActiveFirstStar(true);
    setActiveSecondStar(false);
    setActiveThirdStar(false);
    setActiveFourthStar(false);
    setActiveFifthStar(false);
    setComments("");
    setstarRating("1");
    setShowReview(false);
  };

  useEffect(() => {
    console.log("reviewData", reviewData);

    if (showReview && reviewData != null) {
      setComments(reviewData.comment);
      setstarRating(reviewData.rating);
      highlightStars(reviewData.rating);
    }
  }, [reviewData, showReview]);

  return (
    <div className="post_modal feedback_modal">
      <div
        className="modal fade"
        id="view_feedback_modal"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h3 className="modal-title">{t("Feedback")}</h3>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => resetHandler()}
              >
                <span className="material-icons-outlined">close</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="course_rvw_row">
                <div className="rating_hdng">
                  <h5>{t("Give Ratings")}</h5>
                </div>
                <ul className="list_stye_none">
                  {/* ----------- 1 Star ----------- */}
                  <li className={activeFirstStar ? "active_star" : ""}>
                    <Link to="#">
                      <span className="material-icons-outlined">star</span>
                    </Link>
                  </li>

                  {/* ----------- 2 Star ----------- */}
                  <li className={activeSecondStar ? "active_star" : ""}>
                    <Link to="#">
                      <span className="material-icons-outlined">star</span>
                    </Link>
                  </li>

                  {/* ----------- 3 Star ----------- */}
                  <li className={activeThirdStar ? "active_star" : ""}>
                    <Link to="#">
                      <span className="material-icons-outlined">star</span>
                    </Link>
                  </li>
                  {/* ----------- 4 Star ----------- */}
                  <li className={activeFourthStar ? "active_star" : ""}>
                    <Link to="#">
                      <span className="material-icons-outlined">star</span>
                    </Link>
                  </li>
                  {/* ----------- 5 Star ----------- */}
                  <li className={activeFiveStar ? "active_star" : ""}>
                    <Link to="#">
                      <span className="material-icons-outlined">star</span>
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="post_description">
                <label>{t("Comments")}</label>
                <textarea
                  className="form-control"
                  placeholder="Enter comments"
                  value={comments}
                  //onChange={(e) => setComments(e.target.value)}
                ></textarea>
              </div>
              <div className="post_btn">
                Date:{" "}
                {reviewData
                  ? new Date(reviewData.createdAt).toLocaleDateString()
                  : ""}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShowReviewModal;

import React from "react";

const ShowImageModal = ({ imagePath = "", setImagePath = () => {} }) => {
  return (
    <div className="historyModal">
      <div
        className="modal fade show"
        id="chatimagemodal"
        tabIndex="-1"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
      >
        <div className="modal-dialog modal-xl" role="document">
          <div className="modal-content">
            <div className="modal-header mdl_hdng d-flex justify-content-end">
              <button
                type="button"
                className="close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  setImagePath("");
                }}
              >
                <span className="material-icons-round">close</span>
              </button>
            </div>
            <div className="modal-body">
              {imagePath !== "" ? (
                <div className="upload_innrBody">
                  <div className="upload_outer">
                    <div className="chating_mdlPnl">
                      <img
                        src={imagePath}
                        alt="User"
                        className="img w-100 h-100 "
                      />
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShowImageModal;

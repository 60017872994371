/* eslint-disable */
import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";

import * as url from "helper/UrlHelper";
import { getData, postData } from "utils/Gateway";

import { assetImages } from "constants";

const ChatAiSummary = ({ roomId, isGroupChat = false }) => {
  console.log("roomId >>>", roomId);

  const params = useParams();
  const token = localStorage.getItem("token");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  const getCurrentDate = () => {
    const today = new Date();
    return today.toISOString().split("T")[0];
  };

  const [startDate, setStartDate] = useState(getCurrentDate());
  const [endDate, setEndDate] = useState(getCurrentDate());

  const [loading, setLoading] = useState(false);
  const [loadingSummary, setLoadingSummary] = useState(false);
  const [chatContent, setChatContent] = useState("");
  const [summary, setSummary] = useState([]);
  const [plannedTasks, setPlannedTasks] = useState([]);
  const [task, setTask] = useState([]);
  const [error, setError] = useState("");

  const handleGenerateSummary = async () => {
    if (!startDate || !endDate) {
      setError("Please select a start and end date.");
      return;
    }
    setError("");
    setLoading(true);
    setSummary([]);
    setPlannedTasks([]);
    setChatContent("");

    try {
      let requestUrl =
        url.API_BASE_URL + url.API_GET_ALL_CHAT_MESSAGES + `?token=${token}`;

      requestUrl += `&startdate=${startDate}&enddate=${endDate}`;

      if (isGroupChat) {
        requestUrl += `&chatgroupid=${roomId}`;
      } else {
        requestUrl += `&chatpersonid=${roomId}`;
      }

      console.log("requestUrl", requestUrl);

      const response = await getData(requestUrl);

      console.log("chat response", response);

      if (response.status) {
        const allChatRecords = response.data;

        // if allChatRecords is array, run loop and concat all record.description
        if (Array.isArray(allChatRecords)) {
          let chatContent = "";
          allChatRecords.forEach((record) => {
            chatContent += record.description + "; ";
          });

          setChatContent(chatContent);
        }
      }
    } catch (error) {
      setError("Failed to fetch summary. Please try again.");
    }
    setLoading(false);
  };

  // get AI Summary and Task based on this chat text
  // Request API Post data with chatContent and get response
  const getTaskAndSummary = async () => {
    setLoadingSummary(true);

    let requestUrl =
      url.API_BASE_URL + url.API_AI_CONVERSATION_TASK + `?token=${token}`;

    const body = {
      chatContent: chatContent,
    };

    try {
      const response = await postData(requestUrl, body);

      console.log("response", response);

      if (response.status) {
        setSummary(response.data.summary);
        setPlannedTasks(response.data.tasks);
      }

      setLoadingSummary(false);
    } catch (error) {
      console.error("Failed to get AI summary", error);
    }
  };

  // on change of chatContent, call getTaskAndSummary
  useEffect(() => {
    if (chatContent.length > 5) {
      getTaskAndSummary();
    }
  }, [chatContent]);

  // reset all when roomId changes
  useEffect(() => {
    setChatContent("");
    setSummary([]);
    setPlannedTasks([]);
    setError("");
  }, [roomId]);

  return (
    <div
      className="modal fade"
      id="chataisummary"
      tabIndex="-1"
      aria-labelledby="chataisummaryLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-xl">
        <div className="modal-content shadow-xl">
          <div className="modal-header bg-primary text-white">
            <h5 className="modal-title" id="chataisummaryLabel">
              Chat AI Summary
            </h5>
            <button
              type="button"
              className="btn-close btn-close-white"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>

          <div className="modal-body p-4">
            {/* Date Range Selection */}
            <div className="mb-3">
              <label className="form-label fw-semibold">
                Select Date Range:
              </label>
              <div className="d-flex gap-2">
                <input
                  type="date"
                  className="form-control"
                  value={startDate}
                  onChange={(e) => setStartDate(e.target.value)}
                />
                <input
                  type="date"
                  className="form-control"
                  value={endDate}
                  onChange={(e) => setEndDate(e.target.value)}
                />
                <button
                  className="btn btn-primary"
                  onClick={handleGenerateSummary}
                  disabled={loading}
                >
                  {loading ? "Generating..." : "Show"}
                </button>
              </div>
            </div>

            {error && <p className="text-danger">{error}</p>}

            {/* show loadingSummary */}
            {loadingSummary && (
              <div className="text-center mt-3">
                <p>
                  ⏳ Please wait... while we generate summary and associated
                  tasks.
                </p>

                {/* Bootstrap Glow Placeholder */}
                <div className="placeholder-glow">
                  <span className="placeholder col-6"></span>
                  <span className="placeholder col-4"></span>
                  <span className="placeholder col-8"></span>
                </div>
              </div>
            )}

            {/* Summary Section */}
            {summary && summary.length > 0 && (
              <div className="card border-0 shadow-sm p-3 mt-3">
                <h5 className="fw-bold">Summary</h5>
                <ul className="list-unstyled mt-2">
                  {summary.map((item, index) => (
                    <li key={index} className="mb-2">
                      ✅ {item}
                    </li>
                  ))}
                </ul>
              </div>
            )}

            {/* Planned Tasks Section */}
            {plannedTasks && plannedTasks.length > 0 && (
              <div className="card border-0 shadow-sm p-3 mt-3">
                <h5 className="fw-bold">Planned Tasks</h5>
                <ul className="list-unstyled mt-2">
                  {plannedTasks.map((task, index) => (
                    <li key={index} className="mb-2">
                      📌 {task}
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>

          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChatAiSummary;

/* eslint-disable */
import AuthHeader from "components/common/AuthHeader";
import MarketPlaceDetailsBody from "components/MarketPlaceComponents/Details/MarketPlaceDetailsBody";

const MarketPlaceDetails = () => {
  return (
    <>
      <AuthHeader moduleName={"marketplace"} />
      <MarketPlaceDetailsBody />
    </>
  );
};

export default MarketPlaceDetails;

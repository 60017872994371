import { Link } from "react-router-dom";
import * as url from "helper/UrlHelper";

const ProductCard = ({ product = {} }) => {
  return (
    <div className="col-lg-3 col-sm-6 mb-4">
      <div className="marketplace">
        <div className="marketplace-content p-3 bg-white rounded-16 overflow-hidden shadow-sm ">
          <div className="img-container rounded-10 overflow-hidden mb-3 position-relative bg-light text-center">
            <Link to="#">
              <img
                src={url.SERVER_URL + product.productimagepath}
                alt={product.title}
                className="img-fluid"
              />
            </Link>
            {/* <span className="d-inline-flex align-items-center justify-content-center p-2 premium_tags text-black position-absolute fs-sm fw-medium gap-1">
              <i className="d-block material-symbols-outlined fs-md icon-fill">
                stars
              </i>
              <span className="d-inline-block fw-medium">Recommended</span>
            </span> */}
          </div>
          <div className="desc">
            <h5 className="mb-2">
              <Link to="#" className="text-black fw-semibold ">
                {product.name}
              </Link>
            </h5>
            <p className="mb-2 text-gray">By {product.companyname}</p>
            <div className="d-flex gap-2 align-items-center justify-content-between mb-3">
              <p className="d-flex gap-1 align-items-center px-2 py-1 bg-light fs-sm fw-medium text-white rounded-90">
                <span className="d-block material-symbols-outlined icon-fill fs-md text-danger">
                  star
                </span>
                <span className="text-black">
                  <strong>{product.totalratings}</strong> (
                  {product.totalreviews})
                </span>
              </p>
              <span className="price d-block fw-semibold text-green">
                {product.price}
              </span>
            </div>
            <Link
              to={`/marketplace/details/${product._id}`}
              className="btn w-100"
            >
              Open Details
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductCard;

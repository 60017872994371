/* eslint-disable */
import React from "react";
import { Link } from "react-router-dom";
// import use translation ------------------------------
import { useTranslation } from "react-i18next";
import { getDateFormatInSpanish } from "helper/Common/CommonHelper";

const OverviewTab = ({
  expertDetails,
  expertSkills,
  expertEmail,
  expertLanguage,
  expertEducations,
  expertExperiences,
  userCostPerHour = 0,
}) => {
  const { t } = useTranslation(); // translation marker

  return (
    <div className="people_detailspnl">
      <div className="people_details_bx white_shadow_bx">
        <div className="details_hdng">
          <h4>{t("Details")}</h4>
        </div>
        <div className="results_para">
          <p dangerouslySetInnerHTML={{ __html: expertDetails }}></p>
        </div>
      </div>
      {/* education block */}
      <div className="people_details_bx white_shadow_bx">
        <div className="details_hdng">
          <h4>{t("Educations")}</h4>
        </div>
        <div className="experience_pnl">
          <div className="dates_pnlOuter">
            {expertEducations.map((education, index) => {
              return (
                <div className="dates_pnlItem experice_item" key={index}>
                  <span className="dates_nmbr exprince_logo">
                    <img src="/images/exprt-logo1.svg" alt="" />
                  </span>
                  <div className="experience_hdng">
                    <h3>
                      {education.degree}
                      <span>at {education.institute}</span>
                    </h3>
                    <h5>{education.year}</h5>
                  </div>
                  {/* <div className="job_tags">
                    <ul className="list_stye_none">
                      <li>
                        <span>San Franchisco, CA</span>
                      </li>
                    </ul>
                  </div> */}
                </div>
              );
            })}

            {/* <div className="dates_pnlItem experice_item">
              <span className="dates_nmbr exprince_logo">
                <img src="/images/exprt-logo2.svg" alt="" />
              </span>
              <div className="experience_hdng">
                <h3>
                  Channel Sales Director <span>at Dropbox Inc.</span>
                </h3>
                <h5>Apr 2010 to Sep 2015</h5>
              </div>
              <div className="job_tags">
                <ul className="list_stye_none">
                  <li>
                    <span>Newark, NJ</span>
                  </li>
                </ul>
              </div>
            </div>
            <div className="dates_pnlItem experice_item">
              <span className="dates_nmbr exprince_logo">
                <img src="/images/exprt-logo3.svg" alt="" />
              </span>
              <div className="experience_hdng">
                <h3>
                  Product Director <span>at Spotify Inc.</span>
                </h3>
                <h5>Jan 2008 to Mar 2010</h5>
              </div>
              <div className="job_tags">
                <ul className="list_stye_none">
                  <li>
                    <span>San Franchisco, CA</span>
                  </li>
                </ul>
              </div>
            </div> */}
          </div>
        </div>
      </div>

      {/* experience block */}
      <div className="people_details_bx white_shadow_bx">
        <div className="details_hdng">
          <h4>{t("Experience")}</h4>
        </div>
        <div className="experience_pnl">
          <div className="dates_pnlOuter">
            {expertExperiences.map((experience, index) => {
              return (
                <div className="dates_pnlItem experice_item" key={index}>
                  <span className="dates_nmbr exprince_logo">
                    <img src="/images/exprt-logo1.svg" alt="" />
                  </span>
                  <div className="experience_hdng">
                    <h3>
                      {experience.position}
                      <span>
                        at{" "}
                        {experience.companyid
                          ? experience.companyid.label
                          : experience.company != ""
                          ? experience.company
                          : ""}
                      </span>
                    </h3>
                    <h5>
                      {experience.start} to {experience.end}
                    </h5>
                  </div>
                  {/* <div className="job_tags">
                    <ul className="list_stye_none">
                      <li>
                        <span>San Franchisco, CA</span>
                      </li>
                    </ul>
                  </div> */}
                </div>
              );
            })}

            {/* <div className="dates_pnlItem experice_item">
              <span className="dates_nmbr exprince_logo">
                <img src="/images/exprt-logo2.svg" alt="" />
              </span>
              <div className="experience_hdng">
                <h3>
                  Channel Sales Director <span>at Dropbox Inc.</span>
                </h3>
                <h5>Apr 2010 to Sep 2015</h5>
              </div>
              <div className="job_tags">
                <ul className="list_stye_none">
                  <li>
                    <span>Newark, NJ</span>
                  </li>
                </ul>
              </div>
            </div>
            <div className="dates_pnlItem experice_item">
              <span className="dates_nmbr exprince_logo">
                <img src="/images/exprt-logo3.svg" alt="" />
              </span>
              <div className="experience_hdng">
                <h3>
                  Product Director <span>at Spotify Inc.</span>
                </h3>
                <h5>Jan 2008 to Mar 2010</h5>
              </div>
              <div className="job_tags">
                <ul className="list_stye_none">
                  <li>
                    <span>San Franchisco, CA</span>
                  </li>
                </ul>
              </div>
            </div> */}
          </div>
        </div>
      </div>
      {/* <div className="people_details_bx white_shadow_bx">
        <div className="details_hdng">
          <h4>{t("Recommended")}</h4>
        </div>
        <div className="recomended_outer">
          <div className="recomend_row">
            <div className="team_userOuter forum_usrOuter">
              <div className="team_usr_pic">
                <img src="/images/expert2.png" alt="" />
              </div>
              <div className="team_usrTxt">
                <h3>Roma Keller</h3>
                <h5>Sales Manager</h5>
                <div className="forum_para">
                  <p>
                    I first met Pierre in 1988. On my first day at Dell Computer
                    he jumped on the elevator with me to say he was excited I
                    joined the company. Over the next 6 years he occasionally
                    popped into my office to politely suggest what he thought my
                    priorities should be for the business.
                  </p>
                </div>
                <div className="post_time">
                  <span>2 {t("months ago")}</span>
                </div>
              </div>
            </div>
          </div>
          <div className="recomend_row">
            <div className="team_userOuter forum_usrOuter">
              <div className="team_usr_pic">
                <img src="/images/expert3.png" alt="" />
              </div>
              <div className="team_usrTxt">
                <h3>Gordon Ramsay</h3>
                <h5>Sales Manager</h5>
                <div className="forum_para">
                  <p>
                    Over the next 6 years he occasionally popped into my office
                    to politely suggest what he thought my priorities should be
                    for the business.
                  </p>
                </div>
                <div className="post_time">
                  <span>3 {t("months ago")}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <div className="people_details_bx white_shadow_bx">
        <div className="details_hdng">
          <h4>{t("Skills")}</h4>
        </div>
        <div className="experts_skill">
          <ul className="list_stye_none">
            {expertSkills.map((skill, index) => {
              return (
                <li key={index}>
                  <Link to="#">{skill.name}</Link>
                </li>
              );
            })}
          </ul>
        </div>
      </div>

      <div className="people_details_bx white_shadow_bx">
        <div className="details_hdng">
          <h4>{t("Additional Information")}</h4>
        </div>
        <div className="expert_aditonal_outer">
          <div className="row">
            <div className="col-md-6">
              <div className="additional_info">
                <h4>
                  <i className="material-icons-outlined">email</i>
                  {t("Email")}
                </h4>
                <Link to="#">{expertEmail}</Link>
              </div>
            </div>
            <div className="col-md-6">
              <div className="additional_info">
                <h4>
                  <i className="material-icons-outlined">language</i>
                  {t("Language")}
                </h4>
                <h3>{expertLanguage}</h3>
              </div>
            </div>

            {/* <div className="col-md-6">
              <div className="additional_info">
                <h4>
                  <i className="material-icons-outlined">language</i>
                  {t("Language")}
                </h4>
                <h3>English, Polish, Spanish</h3>
              </div>
            </div>

            <div className="col-md-6">
              <div className="additional_info">
                <h4>
                  <i className="material-icons-outlined">school</i>
                  {t("Education")}
                </h4>
                <h3>Bsc from Stanford University</h3>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default OverviewTab;

/* eslint-disable */
import React, { useContext, useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";

// import use translation ------------------------------
import { useTranslation } from "react-i18next";

/*import url and gateway methods */
import { getData } from "utils/Gateway";
import * as url from "helper/UrlHelper";

import ProductCard from "./ProductCard/ProductCard";
import { ecosystemSlug } from "Config/Config";

import productSortByOptions from "Data/ProductSortBy.json";
import ratingOptions from "Data/Ratings.json";

const MarketPlaceListBody = () => {
  // const { screenHeight } = useContext(GlobalProvider);
  const token = localStorage.getItem("token");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const moduleAccess = localStorage.getItem("moduleaccess");

  const { t } = useTranslation(); // translation marker

  const productListRef = useRef(null);

  const [categoryList, setcategoryList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [productList, setProductList] = useState([]);
  const [page, setPage] = useState(0);

  //initial for paginations
  const [totalPages, setTotalPages] = useState([]);
  const [lastPageNo, setLastPageNo] = useState(1);

  const [searchProductName, setSearchProductName] = useState("");
  const [selectedCategoryId, setselectedCategoryId] = useState(null);
  const [sortBy, setsortBy] = useState("");
  const [filterRatings, setfilterRatings] = useState("");

  const [isReload, setIsReload] = useState(false);

  //function for get all category
  const getAllCategories = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_CATEGORIES +
        `?token=${token}&slug=PRODUCT&ecosystemslug=${ecosystemSlug}`;

      const response = await getData(requestUrl);

      // console.log(response);

      if (response.status) {
        setcategoryList(response.data);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for get all products
  const getAllProducts = async () => {
    setIsLoading(true);
    try {
      let requestURL =
        url.API_BASE_URL +
        url.API_GET_ALL_PRODUCTS +
        `?token=${token}&start=${page * 8}&limit=8`;

      if (sortBy !== "") {
        requestURL += `&sortingby=${sortBy}`;
      }

      if (searchProductName !== "") {
        requestURL += `&title=${searchProductName}`;
      }

      if (selectedCategoryId !== null) {
        requestURL += `&categories=${selectedCategoryId}`;
      }

      // if (filterSku !== "") {
      //   requestURL += `&sku=${filterSku}`;
      // }

      // if (filterCompanies.length > 0) {
      //   requestURL += `&companies=${filterCompanies}`;
      // }

      // if (filterStartPrice !== "" && filterEndPrice !== "") {
      //   requestURL += `&startprice=${filterStartPrice}&endprice=${filterEndPrice}`;
      // }

      // if (filterStartStock !== "" && filterEndStock !== "") {
      //   requestURL += `&startstock=${filterStartStock}&endstock=${filterEndStock}`;
      // }

      // if (filterTypes.length > 0) {
      //   requestURL += `&producttypes=${filterTypes}`;
      // }

      console.log("requestURL --------> ", requestURL);

      const response = await getData(requestURL);

      setIsLoading(false);

      if (response.status) {
        setProductList(response.data);

        // set last page no from API
        setLastPageNo(response.totalpages);

        /*--- Creating dynamic array for pagination ----*/
        const pageArray = Array.from(
          { length: response.totalpages },
          (_, i) => i + 1
        );

        setTotalPages(pageArray);
        /*--- Creating dynamic array for pagination end ----*/
      }
    } catch (error) {}
  };

  //function for set next page
  const nextPage = (data) => {
    setPage(data);
  };

  //function for blogref toggle
  const productRefToggle = () => {
    productListRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const searchProductHandler = () => {
    if (searchProductName !== "") {
      setPage(0);
      getAllProducts();
    }
  };

  const searchByEnter = (e) => {
    if (e.key === "Enter") {
      searchProductHandler();
    }
  };

  const resetHandler = () => {
    setPage(0);
    setSearchProductName("");
    setselectedCategoryId(null);
    setsortBy("");
    setfilterRatings("");

    setIsReload(true);
  };

  useEffect(() => {
    getAllProducts();
  }, [selectedCategoryId, sortBy, filterRatings, page]);

  useEffect(() => {
    if (isReload) {
      getAllProducts();
      setIsReload(false);
    }
  }, [isReload]);

  useEffect(() => {
    getAllCategories();
    getAllProducts();
  }, []);

  return (
    <section className="feed_pnltotal">
      <div className="container-fluid">
        <div className="marketplace_outer">
          <div ref={productListRef} name="scrollBlogRefDiv" />
          <div className="row">
            <div className="col-xl-3">
              <div className="marketplace_lftpnl shadow-sm bg-white rounded-10 p-3">
                {/* <div className="filter_toggle rounded-10 p-3 d-flex align-items-center mb-4 bg-light">
                  <div className="form-check form-switch d-flex align-items-center gap-1 mb-0 pl-0 justify-content-between w-100 cursor-pointer">
                    <label
                      className="form-check-label fw-semibold fs-sm text-black"
                      htmlFor="flexSwitchCheckChecked"
                    >
                      Only Premium Products
                    </label>
                    <input
                      className="form-check-input cursor-pointer"
                      type="checkbox"
                      role="switch"
                      id="flexSwitchCheckChecked"
                      defaultChecked
                    />
                  </div>
                </div> */}
                <div className="filter_outerbx">
                  <div className="product_filtr_item mb-4">
                    <div className="filter_hdng d-flex align-items-center justify-content-between mb-3">
                      <div className="filter_name">
                        <h4>Sort by</h4>
                      </div>
                      {/* <span className="accor_arw cursor-pointer">
                        <i className="material-symbols-outlined">
                          {" "}
                          keyboard_arrow_down
                        </i>
                      </span> */}
                    </div>
                    <div className="filter_info">
                      <div className="filter_list department_info">
                        <ul className="list-style-none d-flex gap-2 flex-wrap">
                          {productSortByOptions.map((item, index) => {
                            return (
                              <li
                                className={`d-flex align-items-center py-2 px-3 bg-light rounded-90 cursor-pointer ${
                                  sortBy === item.value ? "border selected" : ""
                                }`}
                                key={index}
                                onClick={() => {
                                  setPage(0);
                                  if (sortBy === item.value) {
                                    setsortBy("");
                                  } else {
                                    setsortBy(item.value);
                                  }
                                }}
                              >
                                {item.label}
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    </div>
                  </div>

                  {/* <div className="product_filtr_item mb-4">
                    <div className="filter_hdng d-flex align-items-center justify-content-between mb-3">
                      <div className="filter_name">
                        <h4>Price</h4>
                      </div>
                    </div>
                    <div className="filter_info">
                      <div className="price_range">
                        <input type="range" className="text-primary" />
                      </div>
                    </div>
                  </div> */}

                  <div className="product_filtr_item mb-4">
                    <div className="filter_hdng d-flex align-items-center justify-content-between mb-3">
                      <div className="filter_name">
                        <h4>Rating</h4>
                      </div>
                      {/* <span className="accor_arw cursor-pointer">
                        <i className="material-symbols-outlined">
                          {" "}
                          keyboard_arrow_down
                        </i>
                      </span> */}
                    </div>
                    <div className="filter_info">
                      <div className="filter_list department_info">
                        <ul className="list-style-none d-flex align-items-center gap-1 flex-wrap">
                          {ratingOptions.map((item, index) => {
                            return (
                              <li
                                className={`d-flex align-items-center py-2 px-3 bg-light rounded-90 cursor-pointer gap-1 ${
                                  filterRatings === item.value
                                    ? "border selected"
                                    : ""
                                }`}
                                onClick={() => {
                                  setPage(0);
                                  if (filterRatings === item.value) {
                                    setfilterRatings("");
                                  } else {
                                    setfilterRatings(item.value);
                                  }
                                }}
                                key={index}
                              >
                                <i className="material-symbols-outlined fs-lg text-danger icon-fill">
                                  {" "}
                                  star
                                </i>{" "}
                                <span>{item.label}</span>
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-3 d-flex gap-3">
                  <button className="btn" onClick={resetHandler}>
                    Reset
                  </button>
                </div>
              </div>
            </div>
            <div className="col-xl-9">
              <div className="top_serach_pnl shadow-sm bg-white rounded-10 p-4 mb-4">
                <div className="search_fill mb-4 position-relative">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search for the product"
                    value={searchProductName}
                    onChange={(e) => setSearchProductName(e.target.value)}
                    onKeyDown={searchByEnter}
                  />
                  <button className="srch_btn" onClick={searchProductHandler}>
                    <i className="material-symbols-outlined fs-xl icon-fill">
                      {" "}
                      search
                    </i>
                  </button>
                </div>
                <div className="catagories_list">
                  <ul className="list-style-none d-flex flex-wrap">
                    {categoryList.map((category, index) => {
                      return (
                        <li
                          className="text-center position-relative"
                          key={index}
                        >
                          {/* <i className="d-block mb-2">
                            <img src="/images/others.png" alt="" />
                          </i> */}
                          <Link
                            to="#"
                            className={
                              selectedCategoryId === category._id
                                ? ""
                                : "text-gray"
                            }
                            onClick={() => {
                              setPage(0);
                              if (selectedCategoryId === category._id) {
                                setselectedCategoryId(null);
                              } else {
                                setselectedCategoryId(category._id);
                              }
                            }}
                          >
                            <span className="d-block mb-2 material-symbols-outlined icon-fill fs-sm">
                              category
                            </span>
                            <span className="d-block fw-semibold fs-sm text-capitalize">
                              {category.name}
                            </span>
                          </Link>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
              <div className="procuct_pnl">
                <div className="row">
                  {productList.map((product, index) => {
                    return <ProductCard key={index} product={product} />;
                  })}
                </div>
              </div>
              <div
                className={`${
                  totalPages.length > 0 ? "job_pagination" : "d-none"
                }`}
              >
                <ul className="list-style-none d-flex align-items-center gap-3 justify-content-center">
                  <li className="prev_pagnation">
                    <Link
                      to="#"
                      className="d-flex align-items-center justify-content-center"
                      onClick={() => {
                        if (page !== 0) {
                          nextPage(page - 1);
                          productRefToggle();
                        }
                      }}
                    >
                      <span className="material-symbols-outlined fs-lg">
                        arrow_back
                      </span>
                    </Link>
                  </li>
                  {totalPages.map((pageNo) => {
                    return (
                      <li
                        className={page + 1 === pageNo ? "active" : ""}
                        key={pageNo}
                      >
                        <Link
                          to="#"
                          onClick={() => {
                            nextPage(pageNo - 1);
                            productRefToggle();
                          }}
                        >
                          {pageNo}
                        </Link>
                      </li>
                    );
                  })}

                  <li className="next_pagination">
                    <Link
                      to="#"
                      className="d-flex align-items-center justify-content-center"
                      onClick={() => {
                        if (page !== lastPageNo - 1) {
                          nextPage(page + 1);
                          productRefToggle();
                        }
                      }}
                    >
                      <span className="material-symbols-outlined fs-lg">
                        arrow_forward
                      </span>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default MarketPlaceListBody;

/* eslint-disable */
import { ecosystemSlug } from "Config/Config";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

/*import url and gateway methods */
import { getData, putData } from "utils/Gateway";
import * as url from "helper/UrlHelper";
import { getAllCategories } from "helper/CategoryHelper/CategoryHelper";

const FaqFilterPopup = ({
  setfilterStartDate,
  setfilterEndDate,
  setfilterCategories,
  setfilterTags,
}) => {
  const token = localStorage.getItem("token");

  const [tagList, setTagList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [categories, setCategories] = useState([]);
  const [tags, setTags] = useState([]);

  /* hook for validation */
  const [validation, setValidation] = useState({
    dateWarning: false,
    costWarning: false,
  });

  /* function to validate form */
  const validationHandler = () => {
    let isValid = true;

    if (startDate !== "" && endDate === "") {
      setValidation((prevState) => ({ ...prevState, dateWarning: true }));
      isValid = false;
    }

    return isValid;
  };

  //function for get all tags
  const getAllTags = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL + url.API_GET_ALL_TAGS + `?token=${token}`;

      const response = await getData(requestUrl);

      // console.log(response);

      if (response.status) {
        setTagList(response.data);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for get all category
  const fetchAllCategories = async () => {
    try {
      const parentSlug = "FAQ";
      const response = await getAllCategories(ecosystemSlug, parentSlug);

      setCategoryList(response);
    } catch (error) {
      console.log(error.message);
    }
  };

  // function for set categories
  const categorySelectionHandler = (data) => {
    let prevData = categories;

    if (prevData.includes(data.value)) {
      let selectedItem = document.getElementById("categorydata-" + data.value);
      selectedItem.classList.remove("active");
      // exist in array, thus remove
      let key = prevData.indexOf(data.value);
      prevData.splice(key, 1);
    } else {
      let selectedTagItem = document.getElementById(
        "categorydata-" + data.value
      );
      selectedTagItem.classList.add("active");
      // not exist in array, thus add
      prevData.push(data.value);
    }

    setCategories(prevData);
  };

  //function for select tags
  const tagSelectionHandler = (data) => {
    let prevData = tags;

    if (prevData.includes(data.value)) {
      let selectedItem = document.getElementById("tagdata-" + data.value);
      selectedItem.classList.remove("active");
      // exist in array, thus remove
      let key = prevData.indexOf(data.value);
      prevData.splice(key, 1);
    } else {
      let selectedTagItem = document.getElementById("tagdata-" + data.value);
      selectedTagItem.classList.add("active");
      // not exist in array, thus add
      prevData.push(data.value);
    }

    setTags(prevData);
  };

  //function for remove active class for selected tags
  const removeActiveClass = () => {
    const liElements = document.querySelectorAll(".tags li a");
    liElements.forEach((li) => {
      li.classList.remove("active");
    });
  };

  const resetSelectCategories = () => {
    let resetBlock = document.getElementsByClassName("categoryblock");
    for (let i = 0; i < resetBlock.length; i++) {
      resetBlock[i].classList.remove("active");
    }
  };

  //function for reset
  const resetSelectTags = () => {
    let resetBlock = document.getElementsByClassName("tagblock");
    for (let i = 0; i < resetBlock.length; i++) {
      resetBlock[i].classList.remove("active");
    }
  };

  //function for search
  const searchHandler = () => {
    if (validationHandler()) {
      setfilterCategories(categories);
      setfilterTags(tags);

      if (startDate !== "" && endDate !== "") {
        setfilterStartDate(startDate);
        setfilterEndDate(endDate);
      } else {
        setfilterStartDate("");
        setfilterEndDate("");
      }

      // close the offcanvas of filter
      const offCanvasPopup = document.querySelector("#faq_filter_offcanvas");
      const offCanvas = bootstrap.Offcanvas.getInstance(offCanvasPopup);
      offCanvas.hide();
      resetHandler();
    }
  };

  //function for reset
  const resetHandler = () => {
    setStartDate("");
    setEndDate("");

    setTags([]);
    setCategories([]);

    removeActiveClass();
    resetSelectTags();
    resetSelectCategories();

    setValidation({
      dateWarning: false,
    });
  };

  //   const reloadUser = () => {
  //     // close the offcanvas of filter
  //     let offCanvasPopup = document.querySelector("#appuser_filter_offcanvas");
  //     let offCanvas = bootstrap.Offcanvas.getInstance(offCanvasPopup);
  //     offCanvas.hide();
  //     resetList();
  //   };

  useEffect(() => {
    fetchAllCategories();
    getAllTags();
  }, []);

  return (
    <div className="filter_canvas">
      <div className="offcanvas offcanvas-end" id="faq_filter_offcanvas">
        <div className="offcanvas-header">
          <h3>FILTER</h3>
          <Link to="#" data-bs-dismiss="offcanvas">
            <i className="material-icons-outlined">close</i>
          </Link>
        </div>
        <div className="offcanvas-body">
          <div className="filter_outerbx">
            {/* date range  */}
            <div className="filter_item">
              <div className="filter_hdng d-flex align-items-center justify-content-between mb-3">
                <h4>Publish Date</h4>
              </div>
              <div className="filter_information">
                <div className="form-group d-flex gap-2">
                  <input
                    type="date"
                    className="form-control"
                    placeholder="Start"
                    value={startDate}
                    onChange={(e) => {
                      setStartDate(e.target.value);
                      setValidation((prevState) => ({
                        ...prevState,
                        dateWarning: false,
                      }));
                    }}
                  />

                  <input
                    type="date"
                    className="form-control"
                    placeholder="End"
                    value={endDate}
                    onChange={(e) => {
                      setEndDate(e.target.value);
                      setValidation((prevState) => ({
                        ...prevState,
                        dateWarning: false,
                      }));
                    }}
                  />
                </div>
              </div>

              {validation.dateWarning && (
                <div className="error-message mt-3">
                  <p className="d-flex align-items-center gap-1 text-danger">
                    <span className="material-symbols-outlined">warning</span>
                    <span>Please enter end date!</span>
                  </p>
                </div>
              )}
            </div>

            {/* postion */}
            {/* <div className="filter_item">
              <div className="filter_hdng d-flex align-items-center justify-content-between mb-3">
                <h4>Position</h4>
              </div>
              <div className="filter_information">
                <div className="form-group d-flex gap-2">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Position"
                    value={position}
                    onChange={(e) => setPosition(e.target.value)}
                  />
                </div>
              </div>
            </div> */}

            <div className="accordion mt-1" id="accordionPanelsStayOpenExample">
              {/* categories  */}
              <div className="accordion-item border-0">
                <h4 className="accordion-header">
                  <button
                    className="accordion-button text-dark fw-medium shadow-none"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#panelsStayOpen-collapseOne"
                    aria-expanded="true"
                    aria-controls="panelsStayOpen-collapseOne"
                  >
                    <div className="filter_hdng d-flex align-items-center justify-content-between">
                      <h4>Categories</h4>
                    </div>
                  </button>
                </h4>
                <div
                  id="panelsStayOpen-collapseOne"
                  className="accordion-collapse collapse show"
                >
                  <div className="accordion-body p-0">
                    <div className="filter_item">
                      <div className="filter_information">
                        <div className="tags_list">
                          <div className="option_btns">
                            <ul className="list_stye_none d-flex flex-wrap align-items-center gap-2">
                              {categoryList.map((item, index) => {
                                return (
                                  <li className="tag" key={index}>
                                    <Link
                                      to="#"
                                      onClick={() =>
                                        categorySelectionHandler(item)
                                      }
                                      id={"categorydata-" + item.value}
                                      className="categoryblock d-flex align-items-center justify-content-center"
                                    >
                                      <span>{item.label}</span>
                                    </Link>
                                  </li>
                                );
                              })}
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* label */}
              <div className="accordion-item border-0">
                <h4 className="accordion-header">
                  <button
                    className="accordion-button text-dark fw-medium shadow-none"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#panelsStayOpen-collapseTwo"
                    aria-expanded="true"
                    aria-controls="panelsStayOpen-collapseTwo"
                  >
                    <div className="filter_hdng d-flex align-items-center justify-content-between">
                      <h4>Tags</h4>
                    </div>
                  </button>
                </h4>
                <div
                  id="panelsStayOpen-collapseTwo"
                  className="accordion-collapse collapse show"
                >
                  <div className="accordion-body p-0">
                    <div className="filter_item">
                      <div className="filter_information">
                        <div className="tags_list">
                          <div className="option_btns">
                            <ul className="list_stye_none d-flex flex-wrap align-items-center gap-2">
                              {tagList.map((item, index) => {
                                return (
                                  <li className="tag" key={index}>
                                    <Link
                                      to="#"
                                      onClick={() => tagSelectionHandler(item)}
                                      id={"tagdata-" + item.value}
                                      className="tagblock d-flex align-items-center justify-content-center"
                                    >
                                      <span>{item.label}</span>
                                    </Link>
                                  </li>
                                );
                              })}
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="process_btns_outer mt-3">
            {/* <button className="btn gray" onClick={reloadUser}>
              Reset
            </button> */}
            <button className="btn w-100" onClick={searchHandler}>
              Show Result
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FaqFilterPopup;

/* eslint-disable */
import React, { useState, useContext } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";

//import context
import { GlobalProvider } from "context/GlobalContext";

const CompanyFilterPopup = ({
  tagList,
  companyTypeList,
  setFilterCompanyName,
  setFilterCompanyTypes,
  setFilterCompanyTags,
  setFilterRating,
  setFilterEcosystems,
  resetList,
}) => {
  const { screenHeight, ecosystemList } = useContext(GlobalProvider);

  const [ecosystemValue, setEcosystemValue] = useState(null);
  const [selectedEcosystems, setselectedEcosystems] = useState([]);
  const [companyName, setCompanyName] = useState("");
  const [typeValues, settypeValues] = useState([]);
  const [tagValues, settagValues] = useState([]);
  const [selectedRating, setSelectedRating] = useState("");

  //select ecosystem
  const ecosystemSelectionHandler = (val) => {
    setEcosystemValue(val);
    setselectedEcosystems(val.map((item) => item.value));
  };

  //function for select event type
  const companyTypeSelectionHandler = (data) => {
    let prevTypeValues = typeValues;

    if (prevTypeValues.includes(data.value)) {
      let selectedTypeItem = document.getElementById(
        "companytypefilter-" + data.value
      );

      selectedTypeItem.classList.remove("active");
      // exist in array, thus remove
      let key = prevTypeValues.indexOf(data.value);
      prevTypeValues.splice(key, 1);
    } else {
      let selectedTagItem = document.getElementById(
        "companytypefilter-" + data.value
      );

      selectedTagItem.classList.add("active");
      // not exist in array, thus add
      prevTypeValues.push(data.value);
    }
    settypeValues(prevTypeValues);
  };

  //function for select event tags
  const companyTagSelectionHandler = (data) => {
    let prevTagValues = tagValues;

    if (prevTagValues.includes(data.value)) {
      let selectedTypeItem = document.getElementById(
        "companytagfilter-" + data.value
      );

      selectedTypeItem.classList.remove("active");
      // exist in array, thus remove
      let key = prevTagValues.indexOf(data.value);
      prevTagValues.splice(key, 1);
    } else {
      let selectedTagItem = document.getElementById(
        "companytagfilter-" + data.value
      );

      selectedTagItem.classList.add("active");
      // not exist in array, thus add
      prevTagValues.push(data.value);
    }
    settagValues(prevTagValues);
  };

  //function for remove active class for selected tags
  const removeActiveClass = () => {
    const liElements = document.querySelectorAll(".tags li a");
    liElements.forEach((li) => {
      li.classList.remove("active");
    });
  };

  const handleRatingChange = (event) => {
    setSelectedRating(event.target.value);
  };

  //function for assign search data for filter and close pop up
  const searchHandler = () => {
    setFilterCompanyName(companyName);
    setFilterCompanyTypes(typeValues);
    setFilterCompanyTags(tagValues);
    setFilterRating(selectedRating);
    setFilterEcosystems(selectedEcosystems);

    // close the offcanvas of filter
    let offCanvasPopup = document.querySelector("#company_filter_offcanvas");
    let offCanvas = bootstrap.Offcanvas.getInstance(offCanvasPopup);
    offCanvas.hide();
    resetHandler();
  };

  //function for reset search
  const resetHandler = () => {
    removeActiveClass();
    setCompanyName("");
    settypeValues([]);
    settagValues([]);
    setSelectedRating("");
    setEcosystemValue(null);
    setselectedEcosystems([]);
  };

  const reloadCompany = () => {
    // close the offcanvas of filter
    let offCanvasPopup = document.querySelector("#company_filter_offcanvas");
    let offCanvas = bootstrap.Offcanvas.getInstance(offCanvasPopup);
    offCanvas.hide();
    resetList();
  };

  return (
    <div className="filter_canvas">
      <div className="offcanvas offcanvas-end" id="company_filter_offcanvas">
        <div className="offcanvas-header">
          <h3>FILTER</h3>
          <Link to="#" data-bs-dismiss="offcanvas">
            <i className="material-icons-outlined">close</i>
          </Link>
        </div>
        <div className="offcanvas-body">
          <div className="filter_outerbx">
            {/* ecosystem */}
            <div className="filter_item">
              <div className="filter_hdng d-flex align-items-center justify-content-between mb-3">
                <h4>Select Ecosystem</h4>
              </div>
              <div className="filter_information">
                <Select
                  isMulti
                  placeholder="Select Ecosystem"
                  options={ecosystemList}
                  value={ecosystemValue}
                  onChange={ecosystemSelectionHandler}
                />
              </div>
            </div>

            {/* company name */}
            <div className="filter_item">
              <div className="filter_hdng d-flex align-items-center justify-content-between mb-3">
                <h4>User Name</h4>
              </div>
              <div className="filter_information">
                <div className="form-group d-flex gap-2">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Company Name"
                    value={companyName}
                    onChange={(e) => setCompanyName(e.target.value)}
                  />
                </div>
              </div>
            </div>

            {/* rating */}
            <div className="filter_item">
              <div className="filter_hdng d-flex align-items-center justify-content-between mb-3">
                <h4>Rating</h4>
              </div>
              <div className="filter_information">
                {/* <ul className="list_stye_none filter_rating">
                  <li>
                    <label className="radio-check">
                      <input type="radio" name="rdo3" />
                      <span></span>
                      <p>
                        4 <i className="material-icons-outlined">star</i> &
                        above
                      </p>
                    </label>
                  </li>
                  <li>
                    <label className="radio-check">
                      <input type="radio" name="rdo3" />
                      <span></span>
                      <p>
                        3 <i className="material-icons-outlined">star</i> &
                        above
                      </p>
                    </label>
                  </li>
                  <li>
                    <label className="radio-check">
                      <input type="radio" name="rdo3" />
                      <span></span>
                      <p>
                        2 <i className="material-icons-outlined">star</i> &
                        above
                      </p>
                    </label>
                  </li>
                  <li>
                    <label className="radio-check">
                      <input type="radio" name="rdo3" />
                      <span></span>
                      <p>
                        1 <i className="material-icons-outlined">star</i> &
                        above
                      </p>
                    </label>
                  </li>
                </ul> */}
                <ul className="list_stye_none filter_rating">
                  {[5, 4, 3, 2, 1].map((rating) => (
                    <li key={rating}>
                      <label className="radio-check">
                        <input
                          type="radio"
                          name="rdo3"
                          value={rating}
                          checked={selectedRating === String(rating)}
                          onChange={handleRatingChange}
                        />
                        <span></span>
                        <p>
                          {rating}{" "}
                          <i className="material-icons-outlined">star</i> &
                          above
                        </p>
                      </label>
                    </li>
                  ))}
                </ul>
              </div>
            </div>

            {/* type/sector */}
            {/* <div className="filter_item">
              <div className="filter_hdng d-flex align-items-center justify-content-between mb-3">
                <h4>Type/Sector</h4>
              </div>
              <div className="filter_information">
                <div className="tags_list">
                  <div className="option_btns mt-2">
                    <ul className="list_stye_none d-flex flex-wrap align-items-center gap-2">
                      {companyTypeList.map((companyType, index) => {
                        return (
                          <li className="tag" key={index}>
                            <Link
                              to="#"
                              onClick={() =>
                                companyTypeSelectionHandler(companyType)
                              }
                              id={"companytypefilter-" + companyType.value}
                              className="skillblock d-block px-3 py-2 gradient-light cursor-pointer rounded-90"
                            >
                              <span>{companyType.label}</span>
                            </Link>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              </div>
            </div> */}

            {/* label */}
            {/* <div className="filter_item">
              <div className="filter_hdng d-flex align-items-center justify-content-between mb-3">
                <h4>Tags</h4>
              </div>
              <div className="filter_information">
                <div className="tags_list">
                  <div className="option_btns mt-2">
                    <ul className="list_stye_none d-flex flex-wrap align-items-center gap-2">
                      {tagList.map((item, index) => {
                        return (
                          <li className="tag" key={index}>
                            <Link
                              to="#"
                              onClick={() => companyTagSelectionHandler(item)}
                              id={"companytagfilter-" + item.value}
                              className="skillblock d-flex align-items-center justify-content-center"
                            >
                              <span>{item.label}</span>
                            </Link>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              </div>
            </div> */}

            {/* accordion  */}
            <div className="accordion mt-1" id="accordionPanelsStayOpenExample">
              {/* type/sector */}
              <div className="accordion-item border-0">
                <h4 className="accordion-header">
                  <button
                    className="accordion-button text-dark fw-medium shadow-none"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#panelsStayOpen-collapseOne"
                    aria-expanded="true"
                    aria-controls="panelsStayOpen-collapseOne"
                  >
                    <div className="filter_hdng d-flex align-items-center justify-content-between">
                      <h4>Type/Sector</h4>
                    </div>
                  </button>
                </h4>
                <div
                  id="panelsStayOpen-collapseOne"
                  className="accordion-collapse collapse show"
                >
                  <div className="accordion-body p-0">
                    <div className="filter_item">
                      <div className="filter_information">
                        <div className="tags_list">
                          <div className="option_btns mt-2">
                            <ul className="list_stye_none d-flex flex-wrap align-items-center gap-2">
                              {companyTypeList.map((companyType, index) => {
                                return (
                                  <li className="tag" key={index}>
                                    <Link
                                      to="#"
                                      onClick={() =>
                                        companyTypeSelectionHandler(companyType)
                                      }
                                      id={
                                        "companytypefilter-" + companyType.value
                                      }
                                      className="skillblock d-block px-3 py-2 gradient-light cursor-pointer rounded-90"
                                    >
                                      <span>{companyType.label}</span>
                                    </Link>
                                  </li>
                                );
                              })}
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* label */}
              <div className="accordion-item border-0">
                <h4 className="accordion-header">
                  <button
                    className="accordion-button text-dark fw-medium shadow-none"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#panelsStayOpen-collapseTwo"
                    aria-expanded="true"
                    aria-controls="panelsStayOpen-collapseTwo"
                  >
                    <div className="filter_hdng d-flex align-items-center justify-content-between">
                      <h4>Tags</h4>
                    </div>
                  </button>
                </h4>
                <div
                  id="panelsStayOpen-collapseTwo"
                  className="accordion-collapse collapse show"
                >
                  <div className="accordion-body p-0">
                    <div className="filter_item">
                      <div className="filter_information">
                        <div className="tags_list">
                          <div className="option_btns mt-2">
                            <ul className="list_stye_none d-flex flex-wrap align-items-center gap-2">
                              {tagList.map((item, index) => {
                                return (
                                  <li className="tag" key={index}>
                                    <Link
                                      to="#"
                                      onClick={() =>
                                        companyTagSelectionHandler(item)
                                      }
                                      id={"companytagfilter-" + item.value}
                                      className="skillblock d-flex align-items-center justify-content-center"
                                    >
                                      <span>{item.label}</span>
                                    </Link>
                                  </li>
                                );
                              })}
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="process_btns_outer d-flex align-items-center justify-content-between mt-3">
            <button className="btn gray" onClick={reloadCompany}>
              Reset
            </button>
            <button className="btn" onClick={searchHandler}>
              Show Result
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompanyFilterPopup;

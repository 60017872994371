/* eslint-disable */
import React, { useState } from "react";
import { Link } from "react-router-dom";

/*import url and gateway methods */
import { getData, postData, uploadMultipleFile } from "utils/Gateway";
import * as url from "helper/UrlHelper";

const SaveFaqModal = () => {
  // user information
  const token = localStorage.getItem("token");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  //console.log('userInfo', userInfo);

  // component states
  const [faqsubject, setFaqSubject] = useState("");
  const [faqdescription, setFaqDescription] = useState("");

  const [isUploading, setisUploading] = useState(false);
  const [uploadedFileCount, setUploadedFileCount] = useState(0);
  const [messageWarning, setmessageWarning] = useState(false);
  const [fileIsBig, setFileIsBig] = useState(false);

  const [uploadedFiles, setuploadedFiles] = useState([]);
  const [uploadedFileIds, setuploadedFileIds] = useState([]);

  // validation states for each field
  const [faqSubjectError, setFaqSubjectError] = useState(false);
  const [faqDescriptionError, setFaqDescriptionError] = useState(false);
  const [isValidate, setIsValidate] = useState(true);

  const [isSubmitting, setisSubmitting] = useState(false);

  // function to handle form submission
  const faqSubmitHandler = (e) => {
    e.preventDefault();

    setIsValidate(true);

    if (faqdescription === "") {
      setFaqDescriptionError(true);

      setIsValidate(false);
    } else {
      setFaqDescriptionError(false);
    }

    if (isValidate) {
      saveQuestion();
    }
  };

  //function to save question using API
  const saveQuestion = async () => {
    console.log("question is validate", isValidate);

    // check if validate is true
    if (isValidate) {
      setisSubmitting(true);
      try {
        const requestUrl =
          url.API_BASE_URL + url.API_CREATE_FAQ + `?token=${token}`;
        //const requestUrl = url.API_BaseUrl_V2 + url.API_DEMO_POST_V2 + `?token=${token}`;

        const data = {
          question: faqdescription,
          uploads: uploadedFileIds,
        };

        console.log("data", data);

        const response = await postData(requestUrl, data);

        console.log(response);

        setisSubmitting(false);

        if (response.status) {
          // close question modal
          let questionModel = bootstrap.Modal.getInstance(
            document.getElementById("questionFaqModal")
          );

          if (questionModel) {
            questionModel.hide();
          }

          setFaqSubject("");
          setFaqDescription("");
          setuploadedFiles([]);
          setuploadedFileIds([]);
          setmessageWarning(true);
        }
      } catch (error) {
        console.log(error.message);
      }
    }
  };

  //function upload multiple files
  const fileUploadHandler = async (e) => {
    try {
      setFileIsBig(false);

      setisUploading(true);

      const files = e.target.files;

      setUploadedFileCount(files.length);

      console.log("files", files);

      let fileProceed = true;
      // let fileProceed = false;

      // if (files.length > 0) {
      //     for (let i = 0; i < files.length; i++) {
      //     let f = files[i];

      //     if (f.size <= 12500000) {
      //         fileProceed = true;
      //     } else {
      //         fileProceed = false;
      //     }
      //     }
      // }

      if (fileProceed) {
        const requestUrl =
          url.API_BASE_URL + url.API_MULTIPLE_FILE_UPLOAD + `?token=${token}`;

        const response = await uploadMultipleFile(requestUrl, files);

        setisUploading(false);

        console.log(requestUrl);
        console.log(response);

        if (response.status) {
          /*----- assign uploaded files with path for display start ------*/
          const uploadedFileValues = response.data.map(
            ({ name, path, _id, ...other }) => ({
              name,
              path: url.SERVER_URL + path,
              filepath: path,
              _id,
            })
          );

          //check old file exist or not if exist then new file will concat with old file
          if (uploadedFiles.length == 0) {
            setuploadedFiles(uploadedFileValues);
          } else {
            let fileArr = uploadedFiles.concat(uploadedFileValues);
            setuploadedFiles(fileArr);
          }
          /*----- assign uploaded files with path for display end ------*/

          /*----- assign uploaded file ids start ------*/
          const fileIds = response.data.map(({ _id, ...other }) => ({ _id }));

          //check old file ids are exist or not.If exist then new id will concat with old id
          if (uploadedFileIds.length == 0) {
            setuploadedFileIds(fileIds);
          } else {
            let fileArr = uploadedFileIds.concat(fileIds);
            setuploadedFileIds(fileArr);
          }
          /*----- assign uploaded file ids end ------*/
          resetFile();
        }
      } else {
        setFileIsBig(true);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  // function for clear file value
  const resetFile = () => {
    setUploadedFileCount(0);
    const file = document.getElementById("faqUploadFile");
    if (file) {
      file.value = null;
    } else {
      return;
    }
  };

  //function for delete file from upload stack
  const closeFileHandler = (index) => {
    const closedFile = [...uploadedFiles];
    const removedFileId = [...uploadedFileIds];

    closedFile.splice(index, 1);
    removedFileId.splice(index, 1);

    setuploadedFiles(closedFile);
    setuploadedFileIds(removedFileId);
  };

  return (
    <div
      className="modal fade"
      id="questionFaqModal"
      tabIndex="-1"
      aria-labelledby="question-popuplabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-xl">
        <div className="modal-content">
          <div className="modal-header border-0 p-0">
            <button
              type="button"
              className="btn-close text-black rounded-circle bg-light p-0 m-0 position-absolute"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body p-4">
            <h3 className="mb-4 fw-semibold fs-xl">Ask Question</h3>
            <div className="row">
              <div className="col-md-12 mb-3">
                <div>
                  <textarea
                    name="description"
                    id="description"
                    className="form-control shadow-none"
                    rows="10"
                    value={faqdescription}
                    onChange={(e) => setFaqDescription(e.target.value)}
                  ></textarea>

                  {faqDescriptionError && (
                    <p className="text-danger">Description is required</p>
                  )}
                </div>
              </div>

              <div className="col-md-12 mb-3">
                <div className="input_fill jobinput_fill">
                  <label>Attach File * less than 20 MB</label>
                  <div className="upload_area w-100">
                    <div className="upload_item">
                      <div className="upload_inner_btn">
                        <input
                          type="file"
                          className="file_btn"
                          onChange={fileUploadHandler}
                        />
                        <div className="upload_icon">
                          <span className="material-icons-outlined">
                            file_upload
                          </span>
                        </div>
                        <div className="upload_txt">
                          <h4>Upload</h4>
                          <h5>Max 20mb doc/mp4</h5>
                        </div>
                      </div>
                    </div>

                    {uploadedFiles.length > 0 &&
                      uploadedFiles.map((file, index) => {
                        const fileExtension = file.name.split(".").pop();

                        return (
                          <div className="upload_item">
                            <div className="uploaded_innrBx">
                              <Link
                                to="#"
                                className="delete_btn"
                                onClick={() => closeFileHandler(index)}
                              >
                                <span className="material-icons-outlined">
                                  delete
                                </span>
                              </Link>
                              <div className="upload_icon">
                                {fileExtension === "jpg" ||
                                fileExtension === "png" ||
                                fileExtension === "jpeg" ||
                                fileExtension === "gif" ||
                                fileExtension === "png" ? (
                                  <img
                                    src={file.path}
                                    alt="MyResume.pdf"
                                    className="img-fluid mb-2"
                                  />
                                ) : fileExtension === "doc" ||
                                  fileExtension === "txt" ? (
                                  <img
                                    src="/images/doc.svg"
                                    className="img-fluid mb-2"
                                    alt="doc"
                                  />
                                ) : fileExtension === "pdf" ? (
                                  <img
                                    src="/images/pdf.svg"
                                    className="img-fluid mb-2"
                                    alt="17365...pdf"
                                  />
                                ) : fileExtension === "xlsx" ? (
                                  <img
                                    src="/images/xls.svg"
                                    className="img-fluid mb-2"
                                    alt="xlsx"
                                  />
                                ) : fileExtension === "mp4" ||
                                  fileExtension === "mov" ? (
                                  <video controls class="img-fluid mb-2">
                                    <source src={file.path} type="video/mp4" />
                                    Your browser does not support the video tag.
                                  </video>
                                ) : null}
                              </div>
                              <div className="upload_txt">
                                <h4>{file.name}</h4>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>
            </div>
            <div className="process_btns_outer d-flex align-items-center justify-content-between mt-4">
              <button
                className="btn"
                onClick={faqSubmitHandler}
                disabled={isSubmitting ? true : false}
                style={{
                  cursor: isSubmitting ? "not-allowed" : "pointer",
                }}
              >
                Submit
                {isSubmitting && (
                  <div
                    className="mx-2 spinner-border spinner-border-sm"
                    role="status"
                  >
                    <span className="visually-hidden">Loading...</span>
                  </div>
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SaveFaqModal;

/* eslint-disable */
import React from "react";
import { Link } from "react-router-dom";

// import use translation ------------------------------
import { useTranslation } from "react-i18next";
import { assetImages } from "constants";

import * as url from "helper/UrlHelper";
import {
  getDateFormatInSpanish,
  getDayAbbreviation,
} from "helper/Common/CommonHelper";

const AppUserCard = ({ userData = {} }) => {
  const { t } = useTranslation(); // translation marker

  return (
    <div className="white_shadow_bx people_bx">
      <div className="people_row">
        <div className="left_people">
          <div className="people_profile">
            <img
              height="60"
              className="rounded-circle"
              src={
                userData.userimage == ""
                  ? assetImages.defaultUser
                  : url.SERVER_URL + userData.userimage
              }
              alt=""
            />
          </div>
          <div className="people_profile_info">
            <h3>{userData.username}</h3>
            <h5>{userData.position}</h5>
            <div className="event_tags">
              <ul className="list_stye_none mb-0">
                <li>
                  <i className="material-icons-outlined ornge_icon">star</i>
                  <span className="rating">
                    {userData.totalratings} <em>({userData.totalreviews})</em>
                  </span>
                </li>
                <li className="rounded-90 py-2 px-3 border-green bg-light-green text-green fs-sm fw-medium">
                  € {userData.costperhour} / Hr
                </li>
              </ul>
            </div>
            <div
              className={
                userData.agendalist && userData.agendalist.length > 0
                  ? "availibiliy mt-3"
                  : "d-none"
              }
            >
              <h5 className="text-gray mb-2 fs-sm">Availability</h5>
              <ul className="list_stye_none d-flex align-items-center gap-2 flex-wrap">
                {userData?.agendalist?.map((agenda, index) => {
                  return (
                    <li
                      className="d-flex flex-column p-2 rounded-10 border border-light-gray bg-white align-self-start"
                      key={index}
                    >
                      <span className="d-block mb-1 text-gray fs-sm">
                        {getDayAbbreviation(agenda.date)}
                        {" : "}
                        {getDateFormatInSpanish(agenda.date)}
                      </span>

                      {agenda.records.map((record, index2) => {
                        return (
                          <span
                            className="d-block text-black fs-sm mt-1"
                            key={index2}
                          >
                            {record.starttime} - {record.endtime}
                          </span>
                        );
                      })}
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
        <div className="right_people_btn">
          <Link to={`/peoples/details/${userData._id}`} className="btn outline">
            {t("Details")}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default AppUserCard;

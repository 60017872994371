/* eslint-disable */
import React from "react";
import { Link } from "react-router-dom";

// import use translation ------------------------------
import { useTranslation } from "react-i18next";

import { assetImages } from "constants";

import * as url from "helper/UrlHelper";

const GroupListCard = ({ groupData }) => {
  const { t } = useTranslation(); // translation marker

  const memberLimit = 5; //for displaying the limit number of members

  return (
    <div className="groups_item">
      <div className="groupsbx_outer">
        <div className="groups_textbx_pnl">
          <div className="event_hdng bookmark_hdng">
            <h3>{groupData.title}</h3>
            {/* <div className="bookmarks_grps">
              <Link to="#">
                <i className="material-icons-outlined">bookmark_border</i>
              </Link>
            </div> */}
            <div className="event_tags">
              <ul className="list_stye_none">
                <li>
                  <i className="material-icons-outlined ornge_icon">star</i>
                  <span className="rating">
                    {groupData.ratingtotal} <em>({groupData.reviewtotal})</em>
                  </span>
                </li>
                <li>
                  <i className="material-icons-outlined">place</i>
                  <span>
                    {groupData.city}, {groupData.country}
                  </span>
                </li>
              </ul>
            </div>
          </div>
          <div className="event_tags">
            <ul className="list_stye_none">
              {groupData.categories.map((item, index) => {
                return (
                  <li key={index}>
                    <span className="">{item.name}</span>
                  </li>
                );
              })}
            </ul>
          </div>
          <div className="grp_pic_txtInnr">
            <div className="left_group">
              <div className="event_para">
                <p>{groupData.summary}</p>
              </div>
              <div className="event_user grps_user">
                <ul className="list_stye_none">
                  {groupData.groupmemberlist
                    .slice(0, memberLimit)
                    .map((member, index) => {
                      return (
                        <li key={index}>
                          <Link to="#">
                            <img
                              height="40"
                              className="rounded-circle"
                              src={
                                member.groupmemberimage === ""
                                  ? assetImages.defaultUser
                                  : url.SERVER_URL + member.groupmemberimage
                              }
                              alt=""
                            />
                          </Link>
                        </li>
                      );
                    })}
                  <li>
                    {groupData.groupmembers.length > memberLimit ? (
                      <Link to="#">
                        +{groupData.groupmembers.length - memberLimit} more
                      </Link>
                    ) : null}
                  </li>
                </ul>
              </div>
            </div>
            <div className="groups_imgbx_pnl">
              <div className="grp_picOuter">
                {/* <div
                  className="group_pic"
                  style={{ backgroundImage: `url(${groupData.grouplogo})` }}
                ></div> */}

                {groupData.grouplogo === "" ? (
                  <span
                    className="placeholder"
                    style={{ width: "130px", height: "130px" }}
                  ></span>
                ) : (
                  <img
                    className="group_pic rounded"
                    src={url.SERVER_URL + groupData.grouplogo}
                    alt=""
                  />
                )}
              </div>
            </div>
          </div>
          <div className="details_btn">
            <Link
              to={`/groups/details/${groupData._id}`}
              className="btn outline"
            >
              {t("View Details")}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GroupListCard;

/* eslint-disable */
import React, { useContext, useEffect, useRef, useState } from "react";

/*------ import url and method ------*/
import * as url from "helper/UrlHelper";
import { postData } from "utils/Gateway";

//import context
import { GlobalProvider } from "context/GlobalContext";

// import use translation ------------------------------
import { useTranslation } from "react-i18next";
import { ecosystemSlug, storeOwnerEmail } from "Config/Config";

const OtpConfirmLoginModal = () => {
  const { userEmailforOtp, setuserEmailforOtp } = useContext(GlobalProvider);
  const { t } = useTranslation(); // translation marker

  const fullURL = window.location.href;

  const parsedURL = new URL(fullURL);
  const searchParams = parsedURL.searchParams;

  const refParam = searchParams.get("ref");
  const secretParam = searchParams.get("secret");

  // console.log(refParam); // Outputs: http://localhost:4202/events/details/6538fd3640da4a0c55804250
  // console.log(secretParam); // Outputs: 9ZSW

  // console.log(userEmailforOtp);

  const code1Ref = useRef(null);
  const code2Ref = useRef(null);
  const code3Ref = useRef(null);
  const code4Ref = useRef(null);

  const [firstCode, setFirstCode] = useState("");
  const [secondCode, setSecondCode] = useState("");
  const [thirdCode, setThirdCode] = useState("");
  const [fourthCode, setFourthCode] = useState("");
  const [otpFailed, setotpFailed] = useState(false);
  const [otpSending, setotpSending] = useState(false);
  const [otpResendMessage, setotpResendMessage] = useState("");

  // function for backspace reference second digit
  const secondCodeKey = (e) => {
    if (e.key == "Backspace") {
      if (secondCode == "" && secondCode.length == 0) {
        code1Ref.current?.focus();
      }
    }
  };

  // function for backspace reference third digit
  const thirdCodeKey = (e) => {
    if (e.key == "Backspace") {
      if (thirdCode == "" && thirdCode.length == 0) {
        code2Ref.current?.focus();
      }
    }
  };

  // function for backspace reference fourth digit
  const fourthCodeKey = (e) => {
    if (e.key == "Backspace") {
      if (fourthCode == "" && fourthCode.length == 0) {
        code3Ref.current?.focus();
      }
    }
  };

  //otp login function
  const validateOtpHandler = async () => {
    try {
      let finalCode = firstCode + secondCode + thirdCode + fourthCode;

      let userData = {
        email: userEmailforOtp,
        otp: finalCode,
      };

      let requestURL =
        url.API_BASE_URL +
        url.API_VALIDATE_OTP +
        `?ecosystemslug=${ecosystemSlug}&storeowneremail=${storeOwnerEmail}`;

      // console.log("requestURL", requestURL);
      // console.log("userData", userData);

      const response = await postData(requestURL, userData);

      // console.log(response);

      if (response.status) {
        setotpFailed(false);

        let myModal = document.querySelector("#otp_confirm_login_mdl");
        let modal = bootstrap.Modal.getInstance(myModal);
        modal.hide();

        // assigning the loggedin user's id,token,all data to the browser's local storage.
        localStorage.setItem("token", response.token);
        localStorage.setItem("userInfo", JSON.stringify(response.data));
        localStorage.setItem("userID", JSON.stringify(response.data._id));
        localStorage.setItem("ecosystem", response.ecosystemid);
        localStorage.setItem("ecosystemlogo", response.ecosystemlogo);
        localStorage.setItem("ecosystemslug", response.ecosystemslug);
        localStorage.setItem("moduleaccess", response.data.moduleaccessdata);

        if (refParam) {
          window.location.href = `${refParam}?secret=${secretParam}`;
        } else {
          window.location.reload();
        }

        //hide otp modal
        //   let loginModal = document.querySelector("#otp_confirm_login_mdl");
        //   let modal = bootstrap.Modal.getInstance(loginModal);
        //   modal.hide();

        //show login_success_modal modal
        //   let myModal = new bootstrap.Modal(
        //     document.getElementById("login_success_modal")
        //   );
        //   myModal.show();
      } else {
        setotpFailed(true);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //otp resend function
  const resendOtpHandler = async () => {
    try {
      setotpSending(true);
      let userData = {
        email: userEmailforOtp,
      };

      let requestURL = url.API_BASE_URL + url.API_GET_OTP;

      const response = await postData(requestURL, userData);

      console.log(response);

      setotpSending(false);

      if (response.status) {
        setotpResendMessage(t("We have sent OTP in your email"));
      } else {
        setotpResendMessage("");
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //reset function
  const resetOtpModal = () => {
    setotpFailed(false);
    setuserEmailforOtp("");
  };

  // use effect for shift second input
  useEffect(() => {
    if (firstCode !== "" && firstCode.length == 1) {
      code2Ref.current?.focus();
    }
  }, [firstCode]);

  // use effect for shift third input
  useEffect(() => {
    if (secondCode !== "" && secondCode.length == 1) {
      code3Ref.current?.focus();
    }
  }, [secondCode]);

  // use effect for shift fourth input
  useEffect(() => {
    if (thirdCode !== "" && thirdCode.length == 1) {
      code4Ref.current?.focus();
    }
  }, [thirdCode]);

  useEffect(() => {
    if (fourthCode != "") {
      validateOtpHandler();
    }
  }, [fourthCode]);

  return (
    <div className="registration_modal">
      <div
        className="modal fade show"
        id="otp_confirm_login_mdl"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
      >
        <div className="modal-dialog small_modal">
          <div className="modal-content">
            <div className="modal-header">
              {/* ----- modal heading start ----- */}
              <div className="signin_hdng">
                <h3>{t("Verify Email")}</h3>
                <h5>{t("Please enter OTP we just sent you on your email")}.</h5>
                <span className="email_info">
                  {userEmailforOtp}
                  {/* <Link to="#">
                    {" "}
                    <i className="material-icons-outlined">edit</i>
                  </Link> */}
                </span>
              </div>
              {/* ----- modal heading end ----- */}

              {/* ----- modal close button start ----- */}
              <button
                type="button"
                className="close"
                data-bs-dismiss="modal"
                data-bs-toggle="modal"
                data-bs-target="#login_modal"
                aria-label="Close"
                onClick={resetOtpModal}
              >
                <i className="material-icons-outlined">close</i>
              </button>
              {/* ----- modal close button end ----- */}
            </div>
            <div className="modal-body">
              <div className="login_body">
                <form onSubmit={(e) => e.preventDefault()}>
                  {/* ---- otp code input and otp error message section start ---- */}
                  <div className="input_fill">
                    <label>{t("Enter OTP")}</label>
                    {/* ---- code input section start ---- */}
                    <div className="validate_fill">
                      <input
                        type="number"
                        ref={code1Ref}
                        value={firstCode}
                        onChange={(e) => {
                          setotpFailed(false);
                          setotpResendMessage("");
                          setFirstCode(
                            e.target.value.slice(0, e.target.maxLength)
                          );
                        }}
                        className="form-control"
                        placeholder="-"
                        maxLength="1"
                      />

                      <input
                        type="number"
                        ref={code2Ref}
                        value={secondCode}
                        onChange={(e) => {
                          setotpFailed(false);
                          setotpResendMessage("");
                          setSecondCode(
                            e.target.value.slice(0, e.target.maxLength)
                          );
                        }}
                        onKeyDown={secondCodeKey}
                        className="form-control"
                        placeholder="-"
                        maxLength="1"
                      />
                      <input
                        type="number"
                        ref={code3Ref}
                        value={thirdCode}
                        onChange={(e) => {
                          setotpFailed(false);
                          setotpResendMessage("");
                          setThirdCode(
                            e.target.value.slice(0, e.target.maxLength)
                          );
                        }}
                        onKeyDown={thirdCodeKey}
                        className="form-control"
                        placeholder="-"
                        maxLength="1"
                      />
                      <input
                        type="number"
                        ref={code4Ref}
                        value={fourthCode}
                        onChange={(e) => {
                          setotpFailed(false);
                          setotpResendMessage("");
                          setFourthCode(
                            e.target.value.slice(0, e.target.maxLength)
                          );
                        }}
                        onKeyDown={fourthCodeKey}
                        className="form-control"
                        placeholder="-"
                        maxLength="1"
                      />
                    </div>
                    {/* ---- code input section end ---- */}

                    {/* ------ otp error message section start ------ */}
                    {otpFailed && (
                      <div className="validate_error">
                        <p className="error">
                          {t(
                            "Oops! the OTP verification failed. Please try again"
                          )}
                          .
                        </p>
                      </div>
                    )}
                    {/* ------ otp error message section end ------ */}
                  </div>
                  {/* ---- otp code input and otp error message section end ---- */}

                  {/* ------ resend and validate button section start ------ */}
                  <div className="loginstep_btnsouter">
                    <div className="resend_btn">
                      <button
                        className="btn outline"
                        onClick={resendOtpHandler}
                        disabled={otpSending ? true : false}
                        style={{
                          cursor: otpSending ? "not-allowed" : "pointer",
                        }}
                      >
                        {otpSending ? (
                          <div
                            className="mx-2 spinner-border spinner-border-sm"
                            role="status"
                          >
                            <span className="visually-hidden">Loading...</span>
                          </div>
                        ) : (
                          <i className="material-icons-outlined">cached</i>
                        )}
                        <span>{t("Resend Code")}</span>
                      </button>
                    </div>
                    {/* <div className="right_btn">
                      <!-- <Link to="#" className="btn disabled">Validate</Link> -->
                      <button className="btn">Validate</button>
                    </div> */}
                  </div>
                  {/* ------ resend and validate button section end ------ */}

                  {otpResendMessage == "" ? null : (
                    <div className="signin_hdng">
                      <span className="email_info">{otpResendMessage}.</span>
                    </div>
                  )}
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OtpConfirmLoginModal;

/* eslint-disable */
import { useState, useEffect } from "react";

import { Link, useParams } from "react-router-dom";

//**------ import url and method ------*/
import * as url from "helper/UrlHelper";
import { getData, postData, putData, uploadMultipleFile } from "utils/Gateway";
import { Height } from "@mui/icons-material";

const UpdateOpportunity = ({
  opportunity,
  receiverId,
  challengeId,
  challengeResponseId,
  setIsOpportunitySaved,
  showOpportunity,
  setShowOpportunity,
}) => {
  const token = localStorage.getItem("token");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  const [title, setTitle] = useState("");
  const [validateTitle, setValidateTitle] = useState(true);

  const [description, setDescription] = useState("");

  const [price, setPrice] = useState(0);
  const [validatePrice, setValidatePrice] = useState(true);

  const [promisePercent, setPromisePercent] = useState(0);

  const [items, setItems] = useState([
    { description: "", quantity: 0, price: 0, tax: 0, amount: 0 },
  ]);
  const [validateItem, setValidateItem] = useState(true);

  const [isSaving, setIsSaving] = useState(false);

  const handleItemChange = (index, field, value) => {
    const newItems = [...items];
    newItems[index][field] = value;

    // Update total amount when quantity, price, or tax changes
    if (field === "quantity" || field === "price" || field === "tax") {
      updateTotalAmount(newItems, index);
    }

    setItems(newItems);

    updateTotalPrice(newItems); // Update price when any item's total amount changes
  };

  const addItem = () => {
    setItems([
      ...items,
      { description: "", quantity: 0, price: 0, tax: 0, amount: 0 },
    ]);
  };

  const removeItem = (index) => {
    const newItems = items.filter((_, i) => i !== index);
    setItems(newItems);

    updateTotalPrice(newItems); // Update price when any item is removed
  };

  //* save the invoice record */
  const saveInvoice = async () => {
    if (validationHandler()) {
      console.log("is validated");

      setIsSaving(true);

      let invoiceData = {
        challenge: challengeId || null,
        clientid: receiverId || null,
        title,
        description,
        amount: price,
        promisepercent: promisePercent,
        payableamount: price,
        currency: "euro",
        items: items, // invoice items
        status: "1",
        notes: [],
        typeslug: "challenge",
        modulename: "",
      };

      console.log("invoice ===>", invoiceData);

      let requestUrl = url.API_BASE_URL;

      let response = {};

      try {
        if (opportunity && opportunity._id) {
          const opportunityId = opportunity._id;

          requestUrl +=
            url.API_UPDATE_INVOICE + `/${opportunityId}?token=${token}`;

          console.log("requesturl UPDATE", requestUrl);

          response = await putData(requestUrl, invoiceData);

          setIsSaving(false);
        } else {
          requestUrl += url.API_CREATE_INVOICE + `?token=${token}`;

          console.log("requesturl ADD", requestUrl);

          response = await postData(requestUrl, invoiceData);

          setIsSaving(false);
        }

        console.log("invoice response", response);

        if (response.status) {
          let offCanvasPopup = document.querySelector(
            "#saveOpportunityOffcanvas"
          );
          let offCanvas = bootstrap.Offcanvas.getInstance(offCanvasPopup);
          offCanvas.hide();
          resetHandler();
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      console.log("is not validated");
    }

    //setShowAlert(true);
  };

  // if show opportunity map the data
  useEffect(() => {
    console.log("opportunity", opportunity);
    console.log("showOpportunity", showOpportunity);

    if (showOpportunity) {
      if (opportunity && opportunity._id) {
        setTitle(opportunity.title);
        setDescription(opportunity.description);
        setPrice(opportunity.amount);
        setPromisePercent(
          opportunity.promisepercent ? opportunity.promisepercent : 0
        );

        if (opportunity.invoiceitems && opportunity.invoiceitems.length > 0) {
          // map items values to the form
          opportunity.invoiceitems.map((item) => {
            setItems([
              ...items,
              {
                description: item.description,
                quantity: item.unit,
                price: item.price,
                tax: item.taxpercent,
                amount: item.totalprice,
              },
            ]);
          });

          console.log("render items", items);
        }
      }
    } else {
      resetHandler();
    }
  }, [showOpportunity]);

  useEffect(() => {
    resetHandler();
  }, [challengeResponseId, receiverId]);

  // reset all states
  const resetHandler = () => {
    setTitle("");
    setDescription("");
    setPrice(0);
    setPromisePercent("");
    setItems([]);
    setValidateTitle(true);
    setValidatePrice(true);
    setValidateItem(true);
  };

  const updateTotalAmount = (newItems, index) => {
    const { quantity, price, tax } = newItems[index];

    const subtotal = (quantity || 0) * (price || 0); // Calculate subtotal (quantity * price)
    const taxAmount = subtotal * ((tax || 0) / 100); // Calculate tax
    const total = subtotal + taxAmount; // Total amount after tax

    newItems[index].amount = total; // Update the total amount
  };

  const updateTotalPrice = (newItems) => {
    const totalPrice = newItems.reduce(
      (sum, item) => sum + (item.amount || 0),
      0
    );
    setPrice(totalPrice); // Update the price field in the form
  };

  // check if title and price is empty or null setIsValidate false
  // also atlest there should be 1 item with description
  const validationHandler = () => {
    let isValidate = true;

    if (title === "" || title === null) {
      setValidateTitle(false);

      isValidate = false;

      console.log("title is empty");
    }

    if (price === "" || price === null) {
      setValidatePrice(false);

      isValidate = false;
    }

    // check if there is atleast 1 item and item description is not empty
    if (items.length === 0) {
      setValidateItem(false);
      isValidate = false;
    } else if (items[0].description == "") {
      setValidateItem(false);
      isValidate = false;
    }

    return isValidate;
  };

  return (
    <div
      className="offcanvas offcanvas-end  offcanvas-xl"
      tabIndex="-1"
      id="updateOpportunityOffcanvas"
    >
      <div className="offcanvas-header">
        <h3>Suggested Opportunity</h3>
        <button
          type="button"
          className="btn-close"
          data-bs-dismiss="offcanvas"
          onClick={() => {
            setShowOpportunity(false);
          }}
        ></button>
      </div>
      <div className="offcanvas-body">
        <form>
          <div className="invoicehead">
            <div className="mb-3">
              <label htmlFor="title" className="form-label">
                Title *
              </label>
              <input
                type="text"
                className="form-control"
                id="title"
                value={title}
                // onChange={(e) => {
                //   setTitle(e.target.value);
                //   setValidateTitle(true);
                // }}
                disabled
              />

              {validateTitle == false ? (
                <div className="text-danger">Title is required</div>
              ) : (
                ""
              )}
            </div>
            <div className="mb-3">
              <label htmlFor="description" className="form-label">
                Description
              </label>
              <textarea
                className="form-control"
                id="description"
                value={description}
                disabled
                //onChange={(e) => setDescription(e.target.value)}
              ></textarea>
            </div>
            <div className="row mb-3">
              <div className="col">
                <label htmlFor="price" className="form-label">
                  Price *
                </label>
                <input
                  type="number"
                  className="form-control"
                  id="price"
                  value={price}
                  disabled
                  // onChange={(e) => {
                  //   setPrice(parseFloat(e.target.value));
                  //   setValidatePrice(true);
                  // }}
                />
                {validatePrice == false ? (
                  <div className="text-danger">Please add a price</div>
                ) : (
                  ""
                )}
              </div>
              <div className="col">
                <label htmlFor="promisePercent" className="form-label">
                  Promise Percent
                </label>
                <input
                  type="number"
                  className="form-control"
                  id="promisePercent"
                  value={promisePercent}
                  disabled
                  // onChange={(e) =>
                  //   setPromisePercent(parseFloat(e.target.value))
                  // }
                />
              </div>
            </div>
            <h5 className="mb-2">Items</h5>
            <div
              className="card p-3 shadow-sm"
              style={{ minHeight: "300px", background: "#F2F4F6" }}
            >
              {items &&
                items.map((item, index) => (
                  <div key={index} className="mb-2">
                    <div className="row">
                      <div className="col-5">
                        <label className="form-label">Description</label>
                        <input
                          type="text"
                          className="form-control"
                          value={item.description}
                          // onChange={(e) =>
                          //   handleItemChange(
                          //     index,
                          //     "description",
                          //     e.target.value
                          //   )
                          // }
                        />
                      </div>
                      <div className="col">
                        <label className="form-label">Quantity</label>
                        <input
                          type="number"
                          className="form-control"
                          value={item.quantity}
                          // onChange={(e) =>
                          //   handleItemChange(
                          //     index,
                          //     "quantity",
                          //     parseFloat(e.target.value)
                          //   )
                          // }
                        />
                      </div>
                      <div className="col">
                        <label className="form-label">Price</label>
                        <input
                          type="number"
                          className="form-control"
                          value={item.price}
                          // onChange={(e) =>
                          //   handleItemChange(
                          //     index,
                          //     "price",
                          //     parseFloat(e.target.value)
                          //   )
                          // }
                        />
                      </div>
                      <div className="col">
                        <label className="form-label">Tax %</label>
                        <input
                          type="number"
                          className="form-control"
                          value={item.tax}
                          // onChange={(e) =>
                          //   handleItemChange(
                          //     index,
                          //     "tax",
                          //     parseFloat(e.target.value)
                          //   )
                          // }
                        />
                      </div>
                      <div className="col">
                        <label className="form-label">Total Amount</label>
                        <input
                          type="number"
                          className="form-control"
                          value={item.amount}
                          // onChange={(e) =>
                          //   handleItemChange(
                          //     index,
                          //     "amount",
                          //     parseFloat(e.target.value)
                          //   )
                          // }
                        />
                      </div>
                      {/* <div className="col-auto">
                        <button
                          type="button"
                          className="mt-4"
                          onClick={() => removeItem(index)}
                        >
                          <i className="material-icons-outlined">delete</i>
                        </button>
                      </div> */}
                    </div>
                  </div>
                ))}

              {validateItem == false ? (
                <div className="text-danger"> Add a valid item </div>
              ) : (
                ""
              )}

              <div>
                {/* <Link to="#" className="" onClick={addItem}>
                  <i className="material-icons-outlined m-2">add</i> Add Item
                </Link> */}
              </div>
            </div>
          </div>
          {/* <div className="invoicefooter d-flex justify-content-end mt-2">
            <button type="button" className="btn outline" onClick={saveInvoice}>
              {isSaving ? <i className="fas fa-spinner fa-spin"></i> : ""}
              Save Opportunity
            </button>
          </div> */}
        </form>
      </div>
    </div>
  );
};

export default UpdateOpportunity;

import React from "react";
import LeftPanel from "../LeftPanel/LeftPanel";
import MiddlePanel from "../MiddlePanel/MiddlePanel";
import RightPanel from "../RightPanel/RightPanel";

const ListMedicinesBody = () => {
  return (
    <section className="feed_pnltotal">
      <div className="container-fluid">
        <div className="pnl_outer">
          <LeftPanel />
          <MiddlePanel />
          <RightPanel />
        </div>
      </div>
    </section>
  );
};

export default ListMedicinesBody;

/* eslint-disable */
import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";

// import use translation ------------------------------
import { useTranslation } from "react-i18next";

//import context
import AuthHeader from "components/common/AuthHeader";
import { GlobalProvider } from "context/GlobalContext";
import ListMedicineBody from "components/DiagnosisComponents/ListMedicinesComponents/ListBody/ListBody";

const ListMedicines = () => {
  const { t, i18n } = useTranslation(); // translation marker

  return (
    <>
      <AuthHeader moduleName="appuser" />

      <ListMedicineBody />
    </>
  );
};

export default ListMedicines;

import React from "react";

const FaqPlaceholder = () => {
  return (
    <div className="people_row groups_item placeholder-glow">
      <div className="d-flex justify-content-between gap-3 align-items-center corporation_wrapper w-100 flex-wrap">
        <div className="corporation_left">
          <h3 className="fs-lg mb-3 ">
            <span
              className="placeholder col-6"
              style={{ width: "250px" }}
            ></span>
          </h3>

          <div className="event_tags d-flex align-items-center gap-2 flex-wrap my-3">
            <label className="text-gray fw-semibold mb-0">
              <span
                className="placeholder col-4"
                style={{ width: "40px" }}
              ></span>
            </label>
            <ul className="list_stye_none mb-0">
              {Array(3)
                .fill()
                .map((_, index) => (
                  <li key={index}>
                    <span
                      className="placeholder col-3"
                      style={{ width: "40px" }}
                    ></span>
                  </li>
                ))}
            </ul>
          </div>
        </div>

        <div className="corporation_right">
          <div className="details_btn mt-0">
            <span
              className="placeholder"
              style={{
                width: "110px",
                height: "40px",
                borderRadius: "30px",
              }}
            ></span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FaqPlaceholder;

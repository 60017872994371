/* eslint-disable */
import { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import JobDetailsLeftPanel from "../JobDetailsLeftPanel/JobDetailsLeftPanel";
import JobDetailsRightPanel from "../JobDetailsRightPanel/JobDetailsRightPanel";
import JobDetailsMiddlePanel from "../JobDetailsMiddlePanel/JobDetailsMiddlePanel";

/*------ import url and method ------*/
import * as url from "helper/UrlHelper";
import { getData, putData } from "utils/Gateway";
import { assetImages } from "constants";
import TagsModal from "components/common/Modals/TagsModal";
import TagsAddedSuccessModal from "components/common/Modals/TagsAddedSuccessModal";
import ShareModal from "components/common/Modals/ShareModal";

const JobDetailsBody = () => {
  const token = localStorage.getItem("token");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const moduleAccess = localStorage.getItem("moduleaccess");

  const params = useParams();

  const [isLoading, setisLoading] = useState(false);

  const [title, setTitle] = useState("");
  const [companylogopath, setCompanylogopath] = useState("");
  const [code, setcode] = useState("");
  const [summary, setsummary] = useState("");
  const [details, setdetails] = useState("");
  const [aboutCompany, setaboutCompany] = useState("");
  const [aboutPosition, setaboutPosition] = useState("");
  const [requiredSkills, setrequiredSkills] = useState([]);
  const [optionalSkills, setoptionalSkills] = useState([]);
  const [remoteJobValue, setremoteJobValue] = useState(null);
  const [jobTypeValue, setjobTypeValue] = useState("");
  const [experienceAsValue, setexperienceAsValue] = useState(null);
  const [experienceYear, setexperienceYear] = useState("");
  const [experienceMonth, setexperienceMonth] = useState("");
  const [currencyValue, setcurrencyValue] = useState(null);
  const [paymentDurationValue, setpaymentDurationValue] = useState(null);
  const [minSalary, setminSalary] = useState("");
  const [maxSalary, setmaxSalary] = useState("");
  const [companyValue, setcompanyValue] = useState(null);
  const [companyName, setcompanyName] = useState("");
  const [companyAdress, setcompanyAdress] = useState("");
  const [stateName, setstateName] = useState("");
  const [cityName, setcityName] = useState("");
  const [country, setcountry] = useState("");
  const [zipcode, setzipcode] = useState("");
  const [uploadedDocFiles, setUploadedDocFiles] = useState([]);
  const [uploadedDocFileIds, setUploadedDocFileIds] = useState([]);
  const [tagList, settagList] = useState([]);

  const [memberList, setMemberList] = useState([]);

  const [processList, setprocessList] = useState([]);
  const [isApplied, setIsApplied] = useState(false);

  const [applicationCount, setapplicationCount] = useState(0);
  const [similarJobList, setsimilarJobList] = useState([]);

  const [responseList, setResponseList] = useState([]);
  const [winnerList, setwinnerList] = useState([]);

  const [copyMessage, setCopyMessage] = useState("");

  //function for get project details
  const getJobDetails = async () => {
    setisLoading(true);
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_CHALLENGE_DETAILS +
        `/${params.id}?token=${token}`;

      const response = await getData(requestUrl);
      setisLoading(false);
      console.log(response);
      if (response.status && response.data) {
        const data = response.data;
        setTitle(data.name);
        setCompanylogopath(data.companylogopath);
        setcode(data.jobcode);
        setsummary(data.summary);
        setdetails(data.description);
        setaboutCompany(data.aboutcompany);
        setaboutPosition(data.aboutposition);
        setrequiredSkills(data.requiredskillsdata);
        setoptionalSkills(data.optionalskillsdata);
        setexperienceYear(data.experienceyear);
        setexperienceMonth(data.experienceMonth);
        settagList(data.tagsbyuser);
        setminSalary(data.minsalary);
        setmaxSalary(data.maxsalary);
        setjobTypeValue(data.jobtype);
        setcompanyName(data.companynamestring);
        setcompanyAdress(data.location);
        setzipcode(data.zipcode);
        setcityName(data.city);
        setstateName(data.state);
        setcountry(data.country);

        //assign uploaded files
        setUploadedDocFiles(response?.data?.uploadedfiles);
        setUploadedDocFileIds(response?.data?.uploadedfileids);
        setIsApplied(response.data.isappiled);
        setapplicationCount(response.data.applicationcount);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for get all event members
  const getAllChallengeMembers = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_ALL_JOB_MEMBER +
        `?token=${token}&challenge=${params.id}`;

      const response = await getData(requestUrl);

      console.log(response);

      if (response.status) {
        setMemberList(response.data);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const getAllJobProcess = async () => {
    try {
      //  setisLoading(true);
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_ALL_JOB_PROCESS +
        `?token=${token}&challengeid=${params.id}`;

      const response = await getData(requestUrl);

      console.log(response);

      //  setisLoading(false);

      if (response.status) {
        setprocessList(response.data);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for get project list
  const getAllSimilarJobs = async () => {
    try {
      let requestUrl = `${url.API_BASE_URL}${url.API_GET_ALL_CHALLENGE}?token=${token}&userrolestring=${userInfo.role.name}`;

      console.log(requestUrl);

      const response = await getData(requestUrl);

      console.log(response);

      if (response.status) {
        const filteredResults = response.data.filter(
          (job) => job._id.toString() !== params.id.toString()
        );

        setsimilarJobList(filteredResults.slice(0, 5));
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for get response
  const getAllChallengeResponse = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_ALL_CHALLENGE_RESPONSE +
        `?token=${token}&challenge=${params.id}`;

      console.log("get challenge responses", requestUrl);

      const response = await getData(requestUrl);

      console.log('challenge responses', response);

      if (response.status) {
        setResponseList(response.data);

        const filteredResults = response.data.filter((resp) => resp.iswinner);

        setwinnerList(filteredResults);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const afterTagModalClose = () => {
    getJobDetails();
    getAllChallengeMembers();
    getAllJobProcess();
    getAllSimilarJobs();
    getAllChallengeResponse();
  };

  useEffect(() => {
    if (params.id) {
      getJobDetails();
      getAllChallengeMembers();
      getAllJobProcess();
      getAllSimilarJobs();
      getAllChallengeResponse();
    }
  }, [params.id]);

  if (
    userInfo.role.slug === "ADMIN" ||
    userInfo.role.slug === "SUPER_ADMIN" ||
    moduleAccess.includes("MOD_CHALLENGE")
  ) {
    return (
      <section className="feed_pnltotal">
        <div className="container-fluid">
          <div className="back_btn">
            <Link to="/jobs">
              <i className="material-icons-outlined">arrow_back</i>
              <span>Back</span>
            </Link>
          </div>
          <div className="pnl_outer">
            {/* -------- left panel -------- */}
            <JobDetailsLeftPanel
              title={title}
              companyName={companyName}
              companyLogoPath={companylogopath}
              maxSalary={maxSalary}
              minSalary={minSalary}
              cityName={cityName}
              country={country}
              tagList={tagList}
              jobType={jobTypeValue}
              isApplied={isApplied}
              applicationCount={applicationCount}
            />

            {/* ------ mdddle panel ------- */}
            <JobDetailsMiddlePanel
              summary={summary}
              details={details}
              aboutPosition={aboutPosition}
              aboutCompany={aboutCompany}
              minSalary={minSalary}
              maxSalary={maxSalary}
              uploadedDocFiles={uploadedDocFiles}
              memberList={memberList}
              processList={processList}
              isApplied={isApplied}
              responseList={responseList}
              winnerList={winnerList}
            />

            {/* ------ right panel ------- */}
            <JobDetailsRightPanel similarJobList={similarJobList} />

            {/* --------- tags modal ----------- */}
            <TagsModal moduleName="jobdetails" selectedId={params.id} />

            {/* --------- tags added success / greetings modal ------------- */}
            <TagsAddedSuccessModal afterTagModalClose={afterTagModalClose} />

            <ShareModal copyMessage={copyMessage} />
          </div>
        </div>
      </section>
    );
  } else {
    return (
      <section className="feed_pnltotal">
        <div className="empty_access text-center">
          <div className="empty_pic mb-4">
            {" "}
            <img src={assetImages.emptyVector} alt="" />
          </div>
          <div className="empty_text">
            <p className="fs-lg text-gray fw-semibold mb-4">
              Sorry....! You don't have privilege to see this content
            </p>
          </div>
        </div>
      </section>
    );
  }
};

export default JobDetailsBody;

/* eslint-disable */
import { useContext } from "react";
import { Link } from "react-router-dom";

//import context
import { GlobalProvider } from "context/GlobalContext";

import * as url from "helper/UrlHelper";
import { postData } from "utils/Gateway";

import { assetImages } from "constants";

const ConversationTaskDetailsPopup = ({
  conversationName = "",
  taskModName = "",
  taskModImage = "",
  assignedUserImage = "",
  assignedUserName = "",
  taskFollowerList = [],
  taskFileList = [],
}) => {
  return (
    <div className="profile_rightpopup">
      <div className="offcanvas offcanvas-end" id="task_profile_pnl">
        <div className="offcanvas-body">
          <div className="profile_rightpnl">
            <div className="profile_hdngTop">
              <h3>Task Information</h3>
              <span className="profile_crossbtn" data-bs-dismiss="offcanvas">
                <i className="material-icons-round">close</i>
              </span>
            </div>
            <div className="profile_innr_body">
              <div className="profile_innrScroll">
                <div className="profile_info_row">
                  <div className="profile_picPnl">
                    {/* <!-- ========== Start group logo Section ========== --> */}
                    {/* <div className="profile_img grp_profileImg">
                      <div className="chatting_user">
                        <img
                          className="rounded-circle"
                          src={assetImages.defaultUser}
                          alt="profile"
                        />
                      </div>
                    </div> */}
                    {/* <!-- ========== End group logo Section ========== --> */}

                    {/* <!-- ========== Start group title and member count Section ========== --> */}
                    <div className="profile_textPnl">
                      <h4>{conversationName}</h4>
                      <h5>
                        {assignedUserName === ""
                          ? taskFollowerList.length + 1
                          : taskFollowerList.length + 2}{" "}
                        Participants
                      </h5>
                    </div>
                    {/* <!-- ========== End group title and member count Section ========== --> */}
                  </div>
                </div>

                {/* <!-- ========== Start moderrator block Section ========== --> */}
                <div
                  className={taskModName === "" ? "d-none" : "profile_info_row"}
                >
                  <div className="profile_details_row">
                    <div className="info_icon w-100">
                      <i className="material-icons-round">person</i>
                      <span>Moderator</span>
                    </div>
                    <div className="chat_usrRow incoming_msg_row">
                      <Link to="#">
                        <div className="usr_lftPnl">
                          <div className="chatting_user">
                            <img
                              src={
                                taskModImage === ""
                                  ? assetImages.defaultUser
                                  : url.SERVER_URL + taskModImage
                              }
                              alt="profile"
                            />
                          </div>
                          <div className="user_name">
                            <h3>{taskModName}</h3>
                          </div>
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>
                {/* <!-- ========== End moderrator block Section ========== --> */}

                {/* <!-- ========== Start moderrator block Section ========== --> */}
                <div
                  className={
                    assignedUserName === "" ? "d-none" : "profile_info_row"
                  }
                >
                  <div className="profile_details_row">
                    <div className="info_icon w-100">
                      <i className="material-icons-round">person</i>
                      <span>Assigned user</span>
                    </div>
                    <div className="chat_usrRow incoming_msg_row">
                      <Link to="#">
                        <div className="usr_lftPnl">
                          <div className="chatting_user">
                            <img
                              src={
                                assignedUserImage === ""
                                  ? assetImages.defaultUser
                                  : url.SERVER_URL + assignedUserImage
                              }
                              alt="profile"
                            />
                          </div>
                          <div className="user_name">
                            <h3>{assignedUserName}</h3>
                          </div>
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>
                {/* <!-- ========== End moderrator block Section ========== --> */}

                {/* <!-- ========== Start member Section ========== --> */}
                <div
                  className={
                    taskFollowerList.length > 0 ? "profile_info_row" : "d-none"
                  }
                >
                  <div className="profile_details_row">
                    <div className="info_icon w-100">
                      <i className="material-icons-round">person</i>
                      <span>Followers</span>
                    </div>
                    {taskFollowerList.slice(0, 4).map((follower, index) => {
                      return (
                        <div
                          className="chat_usrRow incoming_msg_row"
                          key={index}
                        >
                          <Link to="#">
                            <div className="usr_lftPnl">
                              <div className="chatting_user">
                                <img
                                  src={
                                    follower.imagepath === ""
                                      ? assetImages.defaultUser
                                      : url.SERVER_URL + follower.imagepath
                                  }
                                  alt="profile"
                                />
                              </div>
                              <div className="user_name">
                                <h3>{follower.label}</h3>
                              </div>
                            </div>
                          </Link>
                        </div>
                      );
                    })}

                    {taskFollowerList.length > 4 ? (
                      <div className="chat_usrRow incoming_msg_row mt-2 ms-3">
                        <div className="usr_lftPnl">
                          <div className="user_name">
                            <h3>+ {taskFollowerList.length - 4} More ...</h3>
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
                {/* <!-- ========== End member Section ========== --> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConversationTaskDetailsPopup;

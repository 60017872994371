/* eslint-disable */
import { assetImages } from "constants";
import React, { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import * as url from "helper/UrlHelper";

const JobHeaderSection = ({
  isAgency,
  companyLogoPath,
  companyName,
  jobTypeValue,
  title,
  summary,
  minSalary,
  maxSalary,
  isApplied,
  applyCount,
}) => {
  const params = useParams();

  // useEffect(() => {
  //   console.log('title', title);
  // }, [title]);

  // console.log('applyCount', isApplied);

  return params && params.id ? (
        title ? (
          <div className="builder_item white_shadow_bx mb-3">
            <div className="top_company_row">
              <div className="company_logotxt">
                <span>
                  <img
                    className="rounded-circle"
                    src={
                      companyLogoPath === ""
                        ? assetImages.defaultUser
                        : url.SERVER_URL + companyLogoPath
                    }
                    alt=""
                    height={35}
                  />
                </span>
                <span className="ms-2">{companyName}</span>
              </div>
              <div className="rating_tags d-flex align-items-center">
                <div className="jb_rating">
                  <i className="material-icons-outlined">schedule</i>
                  <span>{jobTypeValue}</span>
                </div>
              </div>
            </div>

            <div className="event_hdng d-flex align-items-center justify-content-between">
              <h3 className="mb-0">{title}</h3>
              <h4 className="mb-0">
                €{minSalary} - €{maxSalary}
              </h4>
            </div>

            <div className="event_hdng d-flex align-items-center justify-content-between">
              <p>{summary}</p>
            </div>

            {isApplied ? (
              <>
                {isAgency ? null : (
                  <p className="text-danger">
                    ** You have already submitted CVs for this job.
                  </p>
                )}
                <p>Application Submitted Count : {applyCount}</p>
              </>
            ) : (
              ""
            )}
          </div>
        ) : (
          // Placeholder block
          <div className="builder_item white_shadow_bx mb-3">
            <div className="top_company_row">
              <div className="company_logotxt d-flex align-items-center">
                <span>
                  <span
                    className="placeholder rounded-circle"
                    style={{ width: 35, height: 35, display: "inline-block" }}
                  ></span>
                </span>
                <span className="ms-2 placeholder-glow">
                  <span className="placeholder col-6"></span>
                </span>
              </div>
              <div className="rating_tags d-flex align-items-center">
                <div className="jb_rating placeholder-glow">
                  <span className="placeholder col-4"></span>
                </div>
              </div>
            </div>

            <div className="event_hdng d-flex align-items-center justify-content-between placeholder-glow">
              <h3 className="mb-0 placeholder col-8"></h3>
              <h4 className="mb-0 placeholder col-4"></h4>
            </div>

            <div className="event_hdng placeholder-glow">
              <p className="placeholder col-12"></p>
              <p className="placeholder col-10"></p>
            </div>

            <div className="placeholder-glow">
              <p className="placeholder col-6"></p>
            </div>
          </div>
        )
      ) : (
        ""
      );




};

export default JobHeaderSection;

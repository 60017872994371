/* eslint-disable */
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";

/*import url and gateway methods */
import { getData, putData } from "utils/Gateway";
import * as url from "helper/UrlHelper";

const ActivityListFilterPopup = ({
  ecosystemList,
  tagList,
  resetList,
  setFilterEcosystem,
  setFilterDateRange,
  setfilterIsPrivate,
  setfilterMod,
  setfilterQuery,
  setfilterEvents,
  setfilterGroups,
  setfilterTags,
}) => {
  const token = localStorage.getItem("token");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  const [ecosystemValue, setEcosystemValue] = useState(null);

  const [fromDate, setfromDate] = useState("");
  const [toDate, settoDate] = useState("");
  const [query, setQuery] = useState("");
  const [isPrivate, setIsPrivate] = useState(false);

  const [contactInput, setcontactInput] = useState("");
  const [contactOptions, setcontactOptions] = useState([]);
  const [contactValue, setContactValue] = useState(null);
  const [contactId, setContactId] = useState(null);

  const [eventInput, seteventInput] = useState("");
  const [eventOptions, seteventOptions] = useState([]);
  const [eventValue, seteventValue] = useState(null);
  const [eventIds, seteventIds] = useState([]);

  const [groupInput, setgroupInput] = useState("");
  const [groupOptions, setgroupOptions] = useState([]);
  const [groupValue, setgroupValue] = useState(null);
  const [groupIds, setgroupIds] = useState([]);

  const [tags, setTags] = useState([]);

  //function for get contact list
  const getContactList = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_USERLIST +
        `?token=${token}&userstring=${contactInput}&roleslug=${userInfo.role.name}`;

      const response = await getData(requestUrl);

      // console.log(response);

      if (response.status) {
        if (response.data.length > 0) {
          setcontactOptions(response.data);
        }
        // else {
        //   const emailMatch = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        //   let userData = {
        //     label: userInput,
        //     value: userInput,
        //     email: emailMatch.test(userInput) ? userInput : "",
        //     _id: null,
        //   };
        //   setuserOptions([userData]);
        // }
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for get contact input
  const contactInputHandler = (val) => {
    setcontactInput(val);
  };

  //function contact Selection
  const contactSelectionHandler = (val) => {
    if (val) {
      setContactValue(val);
      setContactId(val.value);
    } else {
      setContactValue(null);
      setContactId(null);
    }
  };

  //function for event input
  const eventInputHandler = (val) => {
    seteventInput(val);
  };

  //get eventlist
  const getEventList = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_EVENT_LIST_OPTIONS +
        `?token=${token}&eventstring=${eventInput}&roleslug=${userInfo.role.name}`;

      const response = await getData(requestUrl);

      console.log(response);

      if (response.status) {
        if (response.data.length > 0) {
          seteventOptions(response.data);
        } else {
          seteventOptions([]);
        }
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //eventSelectionHandler
  const eventSelectionHandler = (val) => {
    seteventValue(val);
    seteventIds(val.map((item) => item.value));
  };

  //function for group input
  const groupInputHandler = (val) => {
    setgroupInput(val);
  };

  //get group list
  const getGroupList = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_GROUP_LIST +
        `?token=${token}&groupstring=${groupInput}&roleslug=${userInfo.role.name}`;

      const response = await getData(requestUrl);

      console.log(response);

      if (response.status) {
        if (response.data.length > 0) {
          setgroupOptions(response.data);
        } else {
          setgroupOptions([]);
        }
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //groupSelectionHandler
  const groupSelectionHandler = (val) => {
    setgroupValue(val);
    setgroupIds(val.map((item) => item.value));
  };

  //function for select tags
  const tagSelectionHandler = (data) => {
    let prevData = tags;

    if (prevData.includes(data.value)) {
      let selectedItem = document.getElementById("tagdata-" + data.value);
      selectedItem.classList.remove("active");
      // exist in array, thus remove
      let key = prevData.indexOf(data.value);
      prevData.splice(key, 1);
    } else {
      let selectedTagItem = document.getElementById("tagdata-" + data.value);
      selectedTagItem.classList.add("active");
      // not exist in array, thus add
      prevData.push(data.value);
    }

    setTags(prevData);
  };

  //function for remove active class for selected tags
  const removeActiveClass = () => {
    const liElements = document.querySelectorAll(".tags li a");
    liElements.forEach((li) => {
      li.classList.remove("active");
    });
  };

  //function for reset
  const resetSelectTags = () => {
    let resetBlock = document.getElementsByClassName("tagblock");
    for (let i = 0; i < resetBlock.length; i++) {
      resetBlock[i].classList.remove("active");
    }
  };

  //function for search
  const searchHandler = () => {
    //check start date and end date are not empty.If end date empty then only search by start date
    if (fromDate != "" && toDate != "" && fromDate < toDate) {
      setFilterDateRange(fromDate + "," + toDate);
    } else if (fromDate != "") {
      setFilterDateRange(fromDate);
    }
    setfilterIsPrivate(isPrivate);
    setfilterMod(contactId);
    setfilterQuery(query);
    setfilterEvents(eventIds);
    setfilterGroups(groupIds);
    setfilterTags(tags);
    setFilterEcosystem(ecosystemValue ? ecosystemValue.value : null);

    // close the offcanvas of filter
    let offCanvasPopup = document.querySelector("#forum_filter_offcanvas");
    let offCanvas = bootstrap.Offcanvas.getInstance(offCanvasPopup);
    offCanvas.hide();
    resetHandler();
  };

  //function for reset
  const resetHandler = () => {
    setEcosystemValue(null);
    setfromDate("");
    settoDate("");
    setQuery("");
    setIsPrivate(false);
    setcontactInput("");
    setcontactOptions([]);
    setContactValue(null);
    setContactId(null);

    seteventInput("");
    seteventOptions([]);
    seteventValue(null);
    seteventIds([]);

    setgroupInput("");
    setgroupOptions([]);
    setgroupValue(null);
    setgroupIds([]);
    setTags([]);
    removeActiveClass();
    resetSelectTags();
  };

  const reloadList = () => {
    // close the offcanvas of filter
    let offCanvasPopup = document.querySelector("#forum_filter_offcanvas");
    let offCanvas = bootstrap.Offcanvas.getInstance(offCanvasPopup);
    offCanvas.hide();
    resetList();
  };

  useEffect(() => {
    if (contactInput.length > 3) {
      getContactList();
    }
  }, [contactInput]);

  useEffect(() => {
    if (eventInput.length > 3) {
      getEventList();
    }
  }, [eventInput]);

  useEffect(() => {
    if (groupInput.length > 3) {
      getGroupList();
    }
  }, [groupInput]);

  const contactOptionsToShow = contactOptions.length > 0 ? contactOptions : [];
  const eventOptionsToShow = eventOptions.length > 0 ? eventOptions : [];
  const groupOptionsToShow = groupOptions.length > 0 ? groupOptions : [];

  return (
    <div className="filter_canvas">
      <div className="offcanvas offcanvas-end" id="forum_filter_offcanvas">
        <div className="offcanvas-header">
          <h3>Filter Search</h3>
          <Link to="#" data-bs-dismiss="offcanvas">
            <i className="material-icons-outlined">close</i>
          </Link>
        </div>

        <div className="offcanvas-body">
          <div className="filter_outerbx">
            {/* ecosystem */}
            <div className="filter_item">
              <div className="filter_hdng d-flex align-items-center justify-content-between mb-3">
                <h4>Select Ecosystem</h4>
              </div>
              <div className="filter_information">
                <Select
                  placeholder="Select Ecosystem"
                  options={ecosystemList}
                  value={ecosystemValue}
                  onChange={(val) => setEcosystemValue(val)}
                />
              </div>
            </div>

            {/* date */}
            <div className="filter_item">
              <div className="filter_hdng d-flex align-items-center justify-content-between mb-3">
                <h4>Dates</h4>
              </div>
              <div className="filter_information">
                <div className="form-group d-flex gap-2">
                  <input
                    type="date"
                    className="form-control fs-sm shadow-none"
                    placeholder="From date"
                    value={fromDate}
                    onChange={(e) => setfromDate(e.target.value)}
                  />
                  <input
                    type="date"
                    className="form-control fs-sm shadow-none"
                    placeholder="To date"
                    value={toDate}
                    onChange={(e) => settoDate(e.target.value)}
                  />
                </div>
              </div>
            </div>

            {/* post type */}
            <div className="filter_item">
              <label
                htmlFor="isPrivate"
                className="d-flex align-items-center gap-2 mb-1"
              >
                <input
                  type="checkbox"
                  className="w-auto"
                  checked={isPrivate}
                  onChange={(e) => setIsPrivate(e.target.checked)}
                />
                <span className="d-block fw-bold">Is Private</span>
              </label>
            </div>

            {/* posted by */}
            <div className="filter_item">
              <div className="filter_hdng d-flex align-items-center justify-content-between mb-3">
                <h4>Posted By</h4>
              </div>
              <div className="filter_information">
                <Select
                  placeholder="Select posted by"
                  isClearable
                  options={contactOptionsToShow}
                  value={contactValue}
                  onChange={(val) => {
                    contactSelectionHandler(val);
                  }}
                  onInputChange={(val) => {
                    contactInputHandler(val);
                  }}
                />
              </div>
            </div>

            {/* query */}
            <div className="filter_item">
              <div className="filter_hdng d-flex align-items-center justify-content-between mb-3">
                <h4>Query</h4>
              </div>
              <div className="filter_information">
                <div className="form-group d-flex gap-2">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter query"
                    value={query}
                    onChange={(e) => setQuery(e.target.value)}
                  />
                </div>
              </div>
            </div>

            {/* events */}
            <div className="filter_item">
              <div className="filter_hdng d-flex align-items-center justify-content-between mb-3">
                <h4>Event</h4>
              </div>
              <div className="filter_information">
                <Select
                  isMulti
                  placeholder="Search by event name"
                  options={eventOptionsToShow}
                  value={eventValue}
                  onChange={(val) => {
                    eventSelectionHandler(val);
                  }}
                  onInputChange={(val) => {
                    eventInputHandler(val);
                  }}
                />
              </div>
            </div>

            {/* groups */}
            <div className="filter_item">
              <div className="filter_hdng d-flex align-items-center justify-content-between mb-3">
                <h4>Groups</h4>
              </div>
              <div className="filter_information">
                <Select
                  isMulti
                  placeholder="Search by group name"
                  options={groupOptionsToShow}
                  value={groupValue}
                  onChange={(val) => {
                    groupSelectionHandler(val);
                  }}
                  onInputChange={(val) => {
                    groupInputHandler(val);
                  }}
                />
              </div>
            </div>

            {/* label/tag */}
            {/* <div className="filter_item">
              <div className="filter_hdng d-flex align-items-center justify-content-between mb-3">
                <h4>Tags</h4>
              </div>
              <div className="filter_information">
                <div className="tags_list">
                  <div className="option_btns mt-2">
                    <ul className="list_stye_none d-flex flex-wrap align-items-center gap-2">
                      {tagList.map((item, index) => {
                        return (
                          <li className="tag" key={index}>
                            <Link
                              to="#"
                              onClick={() => tagSelectionHandler(item)}
                              id={"tagdata-" + item.value}
                              className="tagblock d-flex align-items-center justify-content-center"
                            >
                              <span>{item.label}</span>
                            </Link>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              </div>
            </div> */}

            <div className="accordion mt-1" id="accordionPanelsStayOpenExample">
              {/* label */}
              <div className="accordion-item border-0">
                <h4 className="accordion-header">
                  <button
                    className="accordion-button text-dark fw-medium shadow-none"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#panelsStayOpen-collapseOne"
                    aria-expanded="true"
                    aria-controls="panelsStayOpen-collapseOne"
                  >
                    <div className="filter_hdng d-flex align-items-center justify-content-between">
                      <h4>Tags</h4>
                    </div>
                  </button>
                </h4>
                <div
                  id="panelsStayOpen-collapseOne"
                  className="accordion-collapse collapse show"
                >
                  <div className="accordion-body p-0">
                    <div className="filter_item">
                      <div className="filter_information">
                        <div className="tags_list">
                          <div className="option_btns mt-2">
                            <ul className="list_stye_none d-flex flex-wrap align-items-center gap-2">
                              {tagList.map((item, index) => {
                                return (
                                  <li className="tag" key={index}>
                                    <Link
                                      to="#"
                                      onClick={() => tagSelectionHandler(item)}
                                      id={"tagdata-" + item.value}
                                      className="tagblock d-flex align-items-center justify-content-center"
                                    >
                                      <span>{item.label}</span>
                                    </Link>
                                  </li>
                                );
                              })}
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="process_btns_outer d-flex align-items-center justify-content-between mt-3">
            <button className="btn gray" onClick={reloadList}>
              Reset
            </button>
            <button className="btn" onClick={searchHandler}>
              Show Result
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ActivityListFilterPopup;

/* eslint-disable */
import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

//import context
import { GlobalProvider } from "context/GlobalContext";

/*import url and gateway methods */
import { getData, putData } from "utils/Gateway";
import * as url from "helper/UrlHelper";

// import use translation ------------------------------
import { useTranslation } from "react-i18next";

//import images
import { assetImages } from "constants";
import EventCard from "./EventCard/EventCard";
import AppUserCard from "./AppUserCard/AppUserCard";

//custom arrow button for react carousel outside
const EventButtonGroup = ({ next, previous }) => {
  const { screenHeight } = useContext(GlobalProvider);
  const token = localStorage.getItem("token");

  return (
    <div className="inBtns events_arws common_arws">
      <button className={previous ? "" : "disable"} onClick={previous} />
      <button onClick={previous} className="slick-prev slick-arrow" />
      <button onClick={next} className="slick-next slick-arrow" />
    </div>
  );
};

//custom arrow button for react carousel outside
const PeopleButtonGroup = ({ next, previous }) => {
  return (
    <div className="inBtns people_arws common_arws">
      <button className={previous ? "" : "disable"} onClick={previous} />
      <button onClick={previous} className="slick-prev slick-arrow" />
      <button onClick={next} className="slick-next slick-arrow" />
    </div>
  );
};

const FeedLandingMiddlePanel = () => {
  const token = localStorage.getItem("token");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const moduleAccess = localStorage.getItem("moduleaccess");

  const { t } = useTranslation(); // translation marker

  const eventResponsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 2,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  const peopleResponsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  const [eventList, seteventList] = useState([]);
  const [appUserList, setAppUserList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [forumList, setForumList] = useState([]);

  const getAllForums = async () => {
    try {
      let requestURL =
        url.API_BASE_URL + url.API_GET_ALL_FORUM + `?token=${token}`;

      const response = await getData(requestURL);

      console.log(response);
      if (response.status) {
        setForumList(response.data);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //get eventlist function
  const getAllEvent = async (start, limit, sortingBy) => {
    try {
      setIsLoading(true);
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_ALL_EVENTS +
        `?token=${token}&start=${start}&limit=${limit}&sortingby=${sortingBy}`;

      const response = await getData(requestUrl);

      console.log(response);

      setIsLoading(false);
      if (response.status) {
        seteventList(response.data);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for get all contacts
  const getAllAppUsers = async (start, limit, sortingBy) => {
    try {
      setIsLoading(true);
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_APP_USERS +
        `?token=${token}&start=${start}&limit=${limit}&sortingby=${sortingBy}&modulefrom=frontend`;

      // console.log("url of app user list------>", requestUrl);

      const response = await getData(requestUrl);

      // console.log("response in app user list------>", response);

      if (response.status) {
        setAppUserList(response.data);
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    getAllForums();
    getAllEvent("0", "5", null);
    getAllAppUsers("0", "10", null);
  }, []);

  if (
    userInfo.role.slug === "ADMIN" ||
    userInfo.role.slug === "SUPER_ADMIN" ||
    moduleAccess.includes("MOD_FEED")
  ) {
    return (
      <div className="pnl_mdlpnl">
        {/* <div className="post_item">
        <div className="search_post_pnl white_shadow_bx">
          <div className="top_usr_srch">
            <div className="search_profile">
              <img
                src={
                  userInfo.photoimage
                    ? userInfo.photoimage?.path
                    : assetImages.defaultUser
                }
                alt=""
              />
            </div>
            <div className="search_fill">
              <Link
                to="#"
                className="form-control"
                data-bs-toggle="modal"
                data-bs-target="#create_postmdl"
              >
                {t("Start your post")}
              </Link>
            </div>
          </div>
          <div className="post_bttm_menu">
            <ul className="list_stye_none">
              <li>
                <Link
                  to="#"
                  data-bs-toggle="modal"
                  data-bs-target="#create_post_img_mdl"
                >
                  <i className="material-icons blue_icon">image</i>
                  <span>{t("Photo")} </span>
                </Link>
              </li>
              <li>
                <Link
                  to="#"
                  data-bs-toggle="modal"
                  data-bs-target="#create_post_vdo_mdl"
                >
                  <i className="material-icons grn_icon">movie</i>
                  <span>{t("Video")}</span>
                </Link>
              </li>
              <li>
                <Link
                  to="#"
                  data-bs-target="#create_post_event_mdl"
                  data-bs-toggle="modal"
                >
                  <i className="material-icons ylw_icon">event_available</i>
                  <span>{t("Event")}</span>
                </Link>
              </li>
              <li>
                <Link
                  to="#"
                  data-bs-toggle="modal"
                  data-bs-target="#create_post_job_mdl"
                >
                  <i className="material-icons ornge_icon">work</i>
                  <span>{t("Jobs")}</span>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div> */}

        {/* activity post */}
        {userInfo.role.slug === "ADMIN" ||
        userInfo.role.slug === "SUPER_ADMIN" ||
        moduleAccess.includes("MOD_FORUM") ? (
          <div className={`${forumList.length > 0 ? "post_item" : "d-none"}`}>
            {forumList.slice(0, 2).map((forum, index) => {
              return (
                <div className="postuser_detailsbx white_shadow_bx" key={index}>
                  <div className="user_post_top">
                    <div className="post_left">
                      <div className="pofile_pic">
                        <img
                          height="35"
                          className="rounded-circle"
                          src={
                            forum.moderatorimage == ""
                              ? assetImages.defaultUser
                              : url.SERVER_URL + forum.moderatorimage
                          }
                          alt=""
                        />
                      </div>
                      <div className="pofile_info">
                        <h3>{forum.moderatorname}</h3>
                        <ul className="list_stye_none">
                          <li>
                            <i className="material-icons">public</i>
                            <span>Public</span>
                          </li>
                          <li>
                            <span className="ms-2">{forum.timestring}</span>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="user_right">
                      <ul className="list_stye_none">
                        <li className="dropdown">
                          <Link to="#" data-bs-toggle="dropdown">
                            <i className="material-icons-outlined">more_vert</i>
                          </Link>
                          <ul className="list_stye_none dropdown-menu">
                            <li>
                              <Link to={`/activities/details/${forum._id}`}>
                                {t("Post Details")}
                              </Link>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="user_post_paragraph">
                    <p>
                      <Link to={`/activities/details/${forum._id}`}>
                        {forum.title}
                      </Link>
                    </p>
                    <p
                      dangerouslySetInnerHTML={{ __html: forum.description }}
                    ></p>
                  </div>
                  {/* <div className="poster_pic">
                <img src="images/activity-pic.jpg" alt="" />
              </div> */}
                  <div className="user_post_icons">
                    <ul className="list_stye_none">
                      <li>
                        <Link to="#">
                          <i className="material-icons ornge_icon">favorite</i>
                          <span>{forum.likes.length}</span>
                        </Link>
                      </li>
                      <li>
                        <Link to={`/activities/details/${forum._id}`}>
                          <i className="material-icons">chat</i>
                          <span>{forum.commentlist.length}</span>
                        </Link>
                      </li>
                      <li className={forum.winnerperson == "" ? "" : "active"}>
                        <Link to="#">
                          <i className="material-icons">stars</i>
                          <span>{t("Winner")}</span>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              );
            })}
          </div>
        ) : null}

        {/* Recommended events start */}
        {userInfo.role.slug === "ADMIN" ||
        userInfo.role.slug === "SUPER_ADMIN" ||
        moduleAccess.includes("MOD_EVENT") ? (
          <div className={eventList.length > 0 ? "post_item" : "d-none"}>
            <div className="white_shadow_bx event_bx">
              <div className="post_hdng">
                <h4>{t("Recommended Events")}</h4>
              </div>
              <div className="event_slideOuter">
                <div className="event_slider">
                  <Carousel
                    responsive={eventResponsive}
                    infinite
                    arrows={false}
                    renderButtonGroupOutside={true}
                    customButtonGroup={<EventButtonGroup />}
                  >
                    {eventList.map((eventData, index) => {
                      return <EventCard key={index} eventData={eventData} />;
                    })}
                  </Carousel>
                </div>

                {/* <div className="inBtns events_arws common_arws"></div> */}
              </div>
              <div className="show_more">
                <Link to="/events">
                  <span>{t("Show More")}</span>
                  <i className="material-icons-outlined">arrow_forward</i>
                </Link>
              </div>
            </div>
          </div>
        ) : null}
        {/* Recommended events end */}

        {/* Recommended app user start */}
        {userInfo.role.slug === "ADMIN" ||
        userInfo.role.slug === "SUPER_ADMIN" ||
        moduleAccess.includes("MOD_USER") ? (
          <div className={appUserList.length > 0 ? "post_item" : "d-none"}>
            <div className="white_shadow_bx people_bx">
              <div className="post_hdng">
                <h4>{t("People You May Know")}</h4>
              </div>
              <div className="people_slide_outer">
                <div className="people_slider">
                  <Carousel
                    className="pb-1"
                    responsive={peopleResponsive}
                    showDots={false}
                    infinite
                    arrows={false}
                    renderButtonGroupOutside={true}
                    customButtonGroup={<PeopleButtonGroup />}
                  >
                    {appUserList.map((userData, index) => {
                      return <AppUserCard key={index} userData={userData} />;
                    })}
                  </Carousel>
                </div>

                {/* <div className="inBtns people_arws common_arws"></div> */}
              </div>
              <div className="show_more">
                <Link to="/peoples">
                  <span>{t("Show More")}</span>
                  <i className="material-icons-outlined">arrow_forward</i>
                </Link>
              </div>
            </div>
          </div>
        ) : null}
        {/* Recommended app user end */}
      </div>
    );
  } else {
    return (
      <div className="pnl_mdlpnl">
        <div className="empty_access text-center">
          <div className="empty_pic mb-4">
            {" "}
            <img src={assetImages.emptyVector} alt="" />
          </div>
          <div className="empty_text">
            <p className="fs-lg text-gray fw-semibold mb-4">
              Sorry....! You don't have privilege to see this content
            </p>
          </div>
        </div>
      </div>
    );
  }
};

export default FeedLandingMiddlePanel;

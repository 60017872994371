/* eslint-disable */
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import { loadStripe } from "@stripe/stripe-js";

/*------ import url and method ------*/
import * as url from "helper/UrlHelper";
import { getData, postData, putData } from "utils/Gateway";

const BillingAddressDetails = () => {
  const token = localStorage.getItem("token");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const invoiceId = localStorage.getItem("invoiceid"); // mongodb invoice id

  const [orderId, setOrderId] = useState(""); // invoiceid to show from API
  const [totalPrice, setTotalPrice] = useState(0);
  const [totalTax, setTotalTax] = useState(0);
  const [totalDiscount, setTotalDiscount] = useState(0);
  const [totalPayable, setTotalPayable] = useState(0);
  const [currency, setCurrency] = useState("€");
  const [invoiceDate, setInvoiceDate] = useState("");
  const [invoiceStatus, setInvoiceStatus] = useState("0");

  const [addressList, setaddressList] = useState([]);
  const [addressId, setAddressId] = useState(null);

  const [subscriptionPlan, setSubscriptionPlan] = useState(null);

  const [isLoading, setIsLoading] = useState(false);
  const [cartItems, setCartItems] = useState([]);
  const [stripeProductItems, setStripeProductItems] = useState([]);

  const [addressData, setAddressData] = useState({
    _id: null,
    address: "",
    city: "",
    country: "",
    state: "",
    zipcode: "",
    mapurl: "",
    phone: "",
  });

  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [address, setAddress] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [mapurl, setMapurl] = useState("");
  const [phone, setPhone] = useState("");

  const [isAdding, setIsAdding] = useState(false);

  //function for company details
  const getInvoiceDetails = async () => {
    try {
      setIsLoading(true);

      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_INVOICE_DETAILS +
        `/${invoiceId}?token=${token}`;

      console.log(requestUrl);

      const response = await getData(requestUrl);

      setIsLoading(false);

      console.log("response", response);

      if (response.data) {
        setOrderId(response.data.orderid);
        setInvoiceDate(response.data.invoicedate);
        setSubscriptionPlan(response.data.plan);

        setTotalPrice(response.data.totalprice);
        setTotalTax(response.data.totaltax);
        setTotalDiscount(response.data.totaldiscount);
        setTotalPayable(response.data.totalpayble);

        setCartItems(response.data.invoiceitems || []);

        setStripeProductItems(response.data.stripecartitems);

        setInvoiceStatus(response.data.status);

        setAddressId(response.data.addressid);
      }
    } catch (error) {
      console.log("Error", error.message);
    }
  };

  //function get all addresses from user
  const getAddresses = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_ALL_ADDRESS +
        `?token=${token}&typeslug=shipping`;

      const response = await getData(requestUrl);

      if (response.status) {
        setaddressList(response.data);
      }
    } catch (error) {
      console.log("Error", error.message);
    }
  };

  //function for select address
  const addressSeelctionHandler = (item) => {
    setAddressData({
      _id: item._id,
      address: item.address,
      city: item.city,
      country: item.country,
      state: item.state,
      zipcode: item.zipcode,
      mapurl: item.mapurl,
      phone: item.phone,
    });
  };

  /* function to fetch location from zip code */
  const getLocationFromZip = async (e) => {
    e.preventDefault();
    if (addressData.zipcode !== "") {
      try {
        const url = `https://maps.googleapis.com/maps/api/geocode/json?components=postal_code:${addressData.zipcode}&region=es&key=AIzaSyDt4dbtdHCHLj8grTzTVxpztvqUGDkvkaI`;
        const response = await getData(url);
        if (response.status === "OK") {
          const results = response.results;
          if (results.length > 0) {
            results[0]?.address_components.map((item) => {
              // console.log(`item`, JSON.stringify(item));
              if (item.types[0] === "country") {
                setAddressData((prevState) => ({
                  ...prevState,
                  country: item?.long_name,
                }));
              }
              if (item.types[0] === "locality") {
                setAddressData((prevState) => ({
                  ...prevState,
                  city: item?.long_name,
                }));
              }
              if (item.types[0] === "administrative_area_level_1") {
                setAddressData((prevState) => ({
                  ...prevState,
                  state: item?.long_name,
                }));
              }
            });
            setAddressData((prevState) => ({
              ...prevState,
              mapurl: `https://maps.google.com/maps?q=${results[0]?.geometry?.location?.lat},${results[0]?.geometry?.location?.lng}`,
            }));
          } else {
            console.log("No results found.");
          }
        } else {
          console.log("Request failed with status:", response.status);
        }
      } catch (error) {
        console.error("Error:", error.message);
      }
    }
  };

  //function for add billing address
  const addBillingAddress = async () => {
    try {
      setIsAdding(true);

      let invoiceData = {
        invoiceaddress: {
          ...addressData,
          name: `${userInfo.name ?? userInfo.email} ${userInfo.surname ?? ""}`,
        },
      };

      console.log("invoiceData", invoiceData);

      let requestUrl =
        url.API_BASE_URL +
        url.API_UPDATE_INVOICE +
        `/${invoiceId}?token=${token}`;

      const response = await putData(requestUrl, invoiceData);

      console.log("response", response);

      setIsAdding(false);

      if (response.status) {
        resetAddress();
        getInvoiceDetails();
        getAddresses();
      }
    } catch (error) {
      console.log("Error", error.message);
    }
  };

  //resetAddress
  const resetAddress = () => {
    setAddressData({
      _id: null,
      address: "",
      city: "",
      country: "",
      state: "",
      zipcode: "",
      mapurl: "",
      phone: "",
    });
  };

  useEffect(() => {
    getInvoiceDetails();
    getAddresses();
  }, []);

  return (
    <section className="pt-70">
      <div className="container-fluid">
        <div className="py-4 px-3 cart_sec">
          <div className="row">
            <div className="col-lg-8">
              <div className="cart_leftpnl px-4 rounded-10 overflow-hidden">
                <div className="address_outer">
                  {addressList.map((item, index) => {
                    return (
                      <div
                        className="address_row py-4 d-flex gap-4 align-items-center"
                        key={index}
                      >
                        <button
                          className="address_radio"
                          onClick={() => {
                            addressSeelctionHandler(item);
                          }}
                        >
                          <span
                            className={`material-icons fs-lg ${
                              item._id === addressData._id
                                ? "text-green"
                                : "text-gray"
                            }`}
                          >
                            {item._id === addressData._id
                              ? "radio_button_checked"
                              : "radio_button_unchecked"}
                          </span>
                        </button>
                        <div className="address_info">
                          <h4 className="text-black fw-semibold mb-2 fs-md">
                            {item.name}
                          </h4>
                          <ul className="list_stye_none d-flex align-items-center gap-2 flex-wrap">
                            <li className="d-flex align-items-center gap-1">
                              <i className="material-icons-outlined fs-lg text-gray">
                                {" "}
                                wifi_calling_3
                              </i>
                              <span className="text-black fs-sm">
                                {item.phone}
                              </span>
                            </li>
                            <li className="d-flex align-items-center gap-1">
                              <i className="material-icons-outlined fs-lg text-gray">
                                {" "}
                                place
                              </i>
                              <span className="text-black fs-sm">
                                {item.address}, {item.zipcode} {item.city},
                                {item.state}, {item.country}
                              </span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    );
                  })}
                  {/* <div className="address_row py-4 d-flex gap-4 align-items-center">
                    <div className="address_radio" style={{ cursor: "pointer" }}>
                      <span className="material-icons text-green fs-lg">
                        {" "}
                        radio_button_checked
                      </span>
                    </div>
                    <div className="address_info">
                      <h4 className="text-black fw-semibold mb-2 fs-md">
                        Dan Abraham
                      </h4>
                      <ul className="list_stye_none d-flex align-items-center gap-2 flex-wrap">
                        <li className="d-flex align-items-center gap-1">
                          <i className="material-icons-outlined fs-lg text-gray">
                            {" "}
                            wifi_calling_3
                          </i>
                          <span className="text-black fs-sm">+44 8976232489</span>
                        </li>
                        <li className="d-flex align-items-center gap-1">
                          <i className="material-icons-outlined fs-lg text-gray">
                            {" "}
                            place
                          </i>
                          <span className="text-black fs-sm">
                            Rda. de Valencia, 2, 28012 Madrid, Spain
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="address_row py-4 d-flex gap-4 align-items-center">
                    <div className="address_radio" style={{ cursor: "pointer" }}>
                      <span className="material-icons fs-lg text-gray">
                        {" "}
                        radio_button_unchecked
                      </span>
                    </div>
                    <div className="address_info">
                      <h4 className="text-black fw-semibold mb-2 fs-md">
                        Harry Lester
                      </h4>
                      <ul className="list_stye_none d-flex align-items-center gap-2 flex-wrap">
                        <li className="d-flex align-items-center gap-1">
                          <i className="material-icons-outlined fs-lg text-gray">
                            {" "}
                            wifi_calling_3
                          </i>
                          <span className="text-black fs-sm">+44 8976232489</span>
                        </li>
                        <li className="d-flex align-items-center gap-1">
                          <i className="material-icons-outlined fs-lg text-gray">
                            {" "}
                            place
                          </i>
                          <span className="text-black fs-sm">
                            Rda. de Valencia, 2, 28012 Madrid, Spain
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div> */}
                </div>
                <div className="billing_form py-5">
                  <div className="row">
                    <div className="col-md-12 mb-3">
                      <div className="form-group">
                        <label className=" text-black d-block fw-semibold">
                          Shipping Address
                        </label>
                        <textarea
                          className="form-control bg-extra-light rounded-10"
                          placeholder="Enter shipping adderss"
                          value={addressData.address}
                          onChange={(e) => {
                            setAddressData((prevState) => ({
                              ...prevState,
                              address: e.target.value,
                            }));
                          }}
                        ></textarea>
                      </div>
                    </div>

                    <div className="col-lg-4 col-md-6 mb-3">
                      <div className="form-group">
                        <label className=" text-black d-block fw-semibold">
                          Phone
                        </label>
                        <input
                          type="text"
                          className="form-control bg-extra-light rounded-10"
                          placeholder="Enter phone number"
                          value={addressData.phone}
                          onChange={(e) => {
                            setAddressData((prevState) => ({
                              ...prevState,
                              phone: e.target.value,
                            }));
                          }}
                        />
                      </div>
                    </div>

                    <div className="col-lg-4 col-md-6 mb-3">
                      <div className="form-group">
                        <label className=" text-black d-block fw-semibold">
                          ZIP Code
                        </label>
                        <input
                          type="number"
                          className="form-control bg-extra-light rounded-10"
                          placeholder="Enter city name"
                          value={addressData.zipcode}
                          onChange={(e) => {
                            setAddressData((prevState) => ({
                              ...prevState,
                              zipcode: e.target.value,
                            }));
                          }}
                        />
                      </div>
                    </div>

                    {/* <div className="col-lg-4 col-md-6 mb-3">
                      <div className="form-group">
                        <label className=" text-black d-block fw-semibold"></label>
                        <button className="btn" onClick={getLocationFromZip}>
                          Fetch Location by ZIP
                        </button>
                      </div>
                    </div> */}

                    <div className="col-lg-4 col-md-6 mb-3">
                      <div className="form-group">
                        <label className=" text-black d-block fw-semibold">
                          City
                        </label>
                        <input
                          type="text"
                          className="form-control bg-extra-light rounded-10"
                          placeholder="Enter city name"
                          value={addressData.city}
                          onChange={(e) => {
                            setAddressData((prevState) => ({
                              ...prevState,
                              city: e.target.value,
                            }));
                          }}
                        />
                      </div>
                    </div>

                    <div className="col-lg-4 col-md-6 mb-3">
                      <div className="form-group">
                        <label className=" text-black d-block fw-semibold">
                          State
                        </label>
                        <input
                          type="text"
                          className="form-control bg-extra-light rounded-10"
                          placeholder="Enter state name"
                          value={addressData.state}
                          onChange={(e) => {
                            setAddressData((prevState) => ({
                              ...prevState,
                              state: e.target.value,
                            }));
                          }}
                        />
                      </div>
                    </div>

                    <div className="col-lg-4 col-md-6 mb-3">
                      <div className="form-group">
                        <label className=" text-black d-block fw-semibold">
                          Country
                        </label>
                        <input
                          type="text"
                          className="form-control bg-extra-light rounded-10"
                          placeholder="Enter country name"
                          value={addressData.country}
                          onChange={(e) => {
                            setAddressData((prevState) => ({
                              ...prevState,
                              country: e.target.value,
                            }));
                          }}
                        />
                      </div>
                    </div>

                    <div className="col-md-12">
                      <div className="form-group mt-3">
                        <button
                          className="btn btn-primary"
                          onClick={addBillingAddress}
                          disabled={isAdding ? true : false}
                          style={{
                            cursor: isAdding ? "not-allowed" : "pointer",
                          }}
                        >
                          {addressData._id ? "Update Address" : "Add Address"}
                          {isAdding && (
                            <div
                              className="mx-2 spinner-border spinner-border-sm"
                              role="status"
                            >
                              <span className="visually-hidden">
                                Loading...
                              </span>
                            </div>
                          )}
                        </button>
                        <button className="btn ms-3" onClick={resetAddress}>
                          Reset
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-4">
              {cartItems.length > 0 ? (
                <div className="cart_rightPnl p-4 bg-white rounded-10 overflow-hidden border-0">
                  <div className="oredr-hdng mb-3">
                    <h4 className="text-gray text-uppercase fs-sm">
                      Order ID : {orderId}
                    </h4>
                  </div>
                  <div className="order_infopnl">
                    <div className="oredr_row d-flex align-items-center justify-content-between gap-2 mb-3">
                      <h5 className="fs-sm text-black mb-0">Total Price</h5>
                      <h5 className="fs-sm text-black mb-0">
                        {totalPrice} {currency}
                      </h5>
                    </div>
                    <div className="oredr_row d-flex align-items-center justify-content-between gap-2 mb-3">
                      <h5 className="fs-sm text-black mb-0">VAT</h5>
                      <h5 className="fs-sm text-gray mb-0">
                        {totalTax}% {currency}
                      </h5>
                    </div>
                    <div className="oredr_row d-flex align-items-center justify-content-between gap-2 mb-3">
                      <h5 className="fs-sm text-black mb-0">Discount</h5>
                      <h5 className="fs-sm  mb-0 text-gray">
                        - {totalDiscount}% {currency}
                      </h5>
                    </div>
                    <div className="oredr_row d-flex align-items-center justify-content-between gap-2 pt-3">
                      <h5 className="fs-sm text-black mb-0">Total Payable</h5>
                      <h3 className="fs-xl  mb-0 text-black fw-semibold">
                        {totalPayable} {currency}
                      </h3>
                    </div>
                  </div>
                  <div className={`${addressId ? "mt-4" : "d-none"}`}>
                    <Link to="/checkout" className="btn w-100">
                      Continue Checkout
                    </Link>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BillingAddressDetails;

/* eslint-disable */
import React, { useState, useContext } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";

//import context
import { GlobalProvider } from "context/GlobalContext";
const JobListFilterPopup = ({
  tagList,
  setFilterTitle,
  setFilterTags,
  setFilterRating,
  setFilterEcosystems,
  resetList,
}) => {
  const { screenHeight, ecosystemList } = useContext(GlobalProvider);

  const [ecosystemValue, setEcosystemValue] = useState(null);
  const [selectedEcosystems, setselectedEcosystems] = useState([]);
  const [title, settitle] = useState("");
  const [selectedRating, setSelectedRating] = useState("");
  const [tagValues, settagValues] = useState([]);

  //select ecosystem
  const ecosystemSelectionHandler = (val) => {
    setEcosystemValue(val);
    setselectedEcosystems(val.map((item) => item.value));
  };

  //function for select event tags
  const tagSelectionHandler = (data) => {
    let prevTagValues = tagValues;

    if (prevTagValues.includes(data.value)) {
      let selectedTypeItem = document.getElementById(
        "companytagfilter-" + data.value
      );

      selectedTypeItem.classList.remove("active");
      // exist in array, thus remove
      let key = prevTagValues.indexOf(data.value);
      prevTagValues.splice(key, 1);
    } else {
      let selectedTagItem = document.getElementById(
        "companytagfilter-" + data.value
      );

      selectedTagItem.classList.add("active");
      // not exist in array, thus add
      prevTagValues.push(data.value);
    }
    settagValues(prevTagValues);
  };

  //function for remove active class for selected tags
  const removeActiveClass = () => {
    const liElements = document.querySelectorAll(".tags li a");
    liElements.forEach((li) => {
      li.classList.remove("active");
    });
  };

  const handleRatingChange = (event) => {
    setSelectedRating(event.target.value);
  };

  //function for assign search data for filter and close pop up
  const searchHandler = () => {
    setFilterTitle(title);
    setFilterTags(tagValues);
    setFilterRating(selectedRating);
    setFilterEcosystems(selectedEcosystems);

    // close the offcanvas of filter
    let offCanvasPopup = document.querySelector("#job_filter_offcanvas");
    let offCanvas = bootstrap.Offcanvas.getInstance(offCanvasPopup);
    offCanvas.hide();
    resetHandler();
  };

  //function for reset search
  const resetHandler = () => {
    removeActiveClass();
    settitle("");
    setEcosystemValue([]);
    setselectedEcosystems([]);
    settagValues([]);
    setSelectedRating("");
  };

  const reloadFilter = () => {
    // close the offcanvas of filter
    let offCanvasPopup = document.querySelector("#job_filter_offcanvas");
    let offCanvas = bootstrap.Offcanvas.getInstance(offCanvasPopup);
    offCanvas.hide();
    resetList();
  };

  return (
    <div className="filter_canvas">
      <div className="offcanvas offcanvas-end" id="job_filter_offcanvas">
        <div className="offcanvas-header">
          <h3>FILTER</h3>
          <Link to="#" data-bs-dismiss="offcanvas">
            <i className="material-icons-outlined">close</i>
          </Link>
        </div>
        <div className="offcanvas-body">
          <div className="filter_outerbx">
            {/* ecosystem */}
            <div className="filter_item">
              <div className="filter_hdng d-flex align-items-center justify-content-between mb-3">
                <h4>Select Ecosystem</h4>
              </div>
              <div className="filter_information">
                <Select
                  isMulti
                  placeholder="Select Ecosystem"
                  options={ecosystemList}
                  value={ecosystemValue}
                  onChange={ecosystemSelectionHandler}
                />
              </div>
            </div>

            {/* company name */}
            <div className="filter_item">
              <div className="filter_hdng d-flex align-items-center justify-content-between mb-3">
                <h4>Job Name</h4>
              </div>
              <div className="filter_information">
                <div className="form-group d-flex gap-2">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Company Name"
                    value={title}
                    onChange={(e) => settitle(e.target.value)}
                  />
                </div>
              </div>
            </div>

            {/* rating */}
            <div className="filter_item">
              <div className="filter_hdng d-flex align-items-center justify-content-between mb-3">
                <h4>Rating</h4>
              </div>
              <div className="filter_information">
                {/* <ul className="list_stye_none filter_rating">
                  <li>
                    <label className="radio-check">
                      <input type="radio" name="rdo3" />
                      <span></span>
                      <p>
                        4 <i className="material-icons-outlined">star</i> &
                        above
                      </p>
                    </label>
                  </li>
                  <li>
                    <label className="radio-check">
                      <input type="radio" name="rdo3" />
                      <span></span>
                      <p>
                        3 <i className="material-icons-outlined">star</i> &
                        above
                      </p>
                    </label>
                  </li>
                  <li>
                    <label className="radio-check">
                      <input type="radio" name="rdo3" />
                      <span></span>
                      <p>
                        2 <i className="material-icons-outlined">star</i> &
                        above
                      </p>
                    </label>
                  </li>
                  <li>
                    <label className="radio-check">
                      <input type="radio" name="rdo3" />
                      <span></span>
                      <p>
                        1 <i className="material-icons-outlined">star</i> &
                        above
                      </p>
                    </label>
                  </li>
                </ul> */}
                <ul className="list_stye_none filter_rating">
                  {[5, 4, 3, 2, 1].map((rating) => (
                    <li key={rating}>
                      <label className="radio-check">
                        <input
                          type="radio"
                          name="rdo3"
                          value={rating}
                          checked={selectedRating === String(rating)}
                          onChange={handleRatingChange}
                        />
                        <span></span>
                        <p>
                          {rating}{" "}
                          <i className="material-icons-outlined">star</i> &
                          above
                        </p>
                      </label>
                    </li>
                  ))}
                </ul>
              </div>
            </div>

            {/* label */}
            <div className="filter_item">
              <div className="filter_hdng d-flex align-items-center justify-content-between mb-3">
                <h4>Tags</h4>
              </div>
              <div className="filter_information">
                <div className="tags_list">
                  <div className="option_btns mt-2">
                    <ul className="list_stye_none d-flex flex-wrap align-items-center gap-2">
                      {tagList.map((item, index) => {
                        return (
                          <li className="tag" key={index}>
                            <Link
                              to="#"
                              onClick={() => tagSelectionHandler(item)}
                              id={"companytagfilter-" + item.value}
                              className="skillblock d-flex align-items-center justify-content-center"
                            >
                              <span>{item.label}</span>
                            </Link>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="process_btns_outer d-flex align-items-center justify-content-between mt-3">
            <button className="btn gray" onClick={reloadFilter}>
              Reset
            </button>
            <button className="btn" onClick={searchHandler}>
              Show Result
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default JobListFilterPopup;

/* eslint-disable */

import React, { useContext, useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";

import "react-multi-carousel/lib/styles.css";

// import use translation ------------------------------
import { useTranslation } from "react-i18next";

//import context
import { GlobalProvider } from "context/GlobalContext";

/*import url and gateway methods */
import { getData, putData } from "utils/Gateway";
import * as url from "helper/UrlHelper";

//import images
import { assetImages } from "constants";
import AppUserCard from "./Card/AppUserCard";
import AppUserPlaceHolder from "./PlaceHolder/AppUserPlaceHolder";
import AppUserFilterPopup from "../Popup/AppUserFilterPopup";

const AppUserMiddlePanel = () => {
  const { screenHeight, ecosystemList } = useContext(GlobalProvider);

  const limit = 10;

  const token = localStorage.getItem("token");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const moduleAccess = localStorage.getItem("moduleaccess");

  const searchUserListRef = useRef(null);

  const { t } = useTranslation(); // translation marker

  const glowCount = [1, 2, 3, 4, 5, 6];

  const [skillList, setSkillList] = useState([]);
  const [serviceList, setServiceList] = useState([]);
  const [tagList, setTagList] = useState([]);

  const [filterName, setfilterName] = useState("");
  const [filterCompany, setfilterCompany] = useState("");
  const [filterPosition, setFilterPosition] = useState("");
  const [filterStartCost, setFilterStartCost] = useState("");
  const [filterEndCost, setFilterEndCost] = useState("");
  const [filterStartDate, setfilterStartDate] = useState("");
  const [filterEndDate, setfilterEndDate] = useState("");
  const [filterRating, setFilterRating] = useState("");
  const [filterServices, setFilterServices] = useState([]);
  const [filterSkills, setfilterSkills] = useState([]);
  const [filterTags, setFilterTags] = useState([]);
  const [filterEcosystems, setFilterEcosystems] = useState([]);

  // the following useState is used to increment the page number and render it accordingly.
  const [page, setPage] = useState(0);

  const [appUserList, setAppUserList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [searchedUserResults, setsearchedUserResults] = useState([]);

  const [params, setParams] = useState(null);
  const [searchedText, setsearchedText] = useState("");
  const [hideNextButton, sethideNextButton] = useState(false);

  const [reloadData, setReloadData] = useState(false);

  //get all services
  const getAllServices = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_CATEGORIES +
        `?token=${token}&slug=services&ecosystemslug=${userInfo.ecosystemid.slug}`;

      const response = await getData(requestUrl);

      // console.log(response);

      if (response.status) {
        setServiceList(response.data);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for get all skills
  const getAllSkills = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_CATEGORIES +
        `?token=${token}&slug=skills&ecosystemslug=${userInfo.ecosystemid.slug}`;

      const response = await getData(requestUrl);

      if (response.status) {
        setSkillList(response.data);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for get all tags
  const getAllTags = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL + url.API_GET_ALL_TAGS + `?token=${token}`;

      const response = await getData(requestUrl);

      // console.log(response);

      if (response.status) {
        setTagList(response.data);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for get app user by search
  const getAppUserBySearch = async (search) => {
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_USERLIST +
        `?token=${token}&userstring=${searchedText}`;

      const response = await getData(requestUrl);

      console.log(response);

      if (response.status) {
        setsearchedUserResults(response.data);
      } else {
        setsearchedUserResults([]);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for get all contacts
  const getAllAppUsers = async () => {
    try {
      setIsLoading(true);
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_APP_USERS +
        `?token=${token}&start=${
          page * limit
        }&limit=${limit}&modulefrom=frontend`;

      if (filterName != "") {
        requestUrl = requestUrl + `&filtername=${filterName}`;
      }

      if (filterCompany != "") {
        requestUrl = requestUrl + `&filtercompany=${filterCompany}`;
      }

      if (filterPosition != "") {
        requestUrl = requestUrl + `&filterposition=${filterPosition}`;
      }

      if (filterStartCost !== "" && filterEndCost !== "") {
        requestUrl += `&filterstartcostperhour=${filterStartCost}&filterendcostperhour=${filterEndCost}`;
      }

      if (filterStartDate !== "" && filterEndDate !== "") {
        requestUrl += `&filterstartdate=${filterStartDate}&filterenddate=${filterEndDate}`;
      }

      if (filterRating != "") {
        requestUrl = requestUrl + `&filterrating=${filterRating}`;
      }

      if (filterServices.length > 0) {
        requestUrl = requestUrl + `&filterservices=${filterServices}`;
      }

      if (filterSkills.length > 0) {
        requestUrl = requestUrl + `&filterskills=${filterSkills}`;
      }

      if (filterTags.length > 0) {
        requestUrl = requestUrl + `&filterlabels=${filterTags}`;
      }

      if (filterEcosystems.length > 0) {
        requestUrl = requestUrl + `&filterecosystemids=${filterEcosystems}`;
      }

      if (params) {
        requestUrl = requestUrl + `&sortingby=${params?.sortby}`;
      }

      console.log("url of app user list------>", requestUrl);

      const response = await getData(requestUrl);

      console.log("response in app user list------>", response);

      if (response.status) {
        if (page === 0) {
          setAppUserList(response.data);
        } else {
          setAppUserList((appUserList) => [...appUserList, ...response.data]);
        }

        if (response.data.length < limit) {
          sethideNextButton(true);
        } else {
          sethideNextButton(false);
        }
        // resetFilterData();
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error.message);
    }
  };

  //reset filter
  const resetFilterData = () => {
    setfilterName("");
    setfilterCompany("");
    setFilterPosition("");
    setFilterStartCost("");
    setFilterEndCost("");
    setfilterStartDate("");
    setfilterEndDate("");
    setFilterRating("");
    setFilterServices([]);
    setfilterSkills([]);
    setFilterTags([]);
    setFilterEcosystems([]);
    setPage(0);
    setParams(null);
  };

  //reset list
  const resetList = () => {
    sethideNextButton(false);
    setsearchedText("");
    setsearchedUserResults([]);
    setReloadData(true);
  };

  // Step 2: Handle the click outside logic
  const hideUserListHandler = (event) => {
    if (
      searchUserListRef.current &&
      !searchUserListRef.current.contains(event.target)
    ) {
      setsearchedText("");
      setsearchedUserResults([]);
    }
  };

  // Step 3: Use effect to add the event listener on mount and remove it on unmount
  useEffect(() => {
    // Adding event listener
    document.addEventListener("click", hideUserListHandler);

    // Cleanup the event listener on unmount
    return () => {
      document.removeEventListener("click", hideUserListHandler);
    };
  }, []);

  useEffect(() => {
    if (searchedText !== "" && searchedText.length > 1) {
      getAppUserBySearch();
    }
  }, [searchedText]);

  //use effect for filter search
  useEffect(() => {
    if (
      params?.sortby ||
      filterName != "" ||
      filterCompany != "" ||
      filterPosition != "" ||
      filterStartCost != "" ||
      filterEndCost != "" ||
      filterStartDate != "" ||
      filterEndDate != "" ||
      filterRating != "" ||
      filterSkills.length > 0 ||
      filterServices.length > 0 ||
      filterTags.length > 0 ||
      filterEcosystems.length > 0
    ) {
      getAllAppUsers();
    }
  }, [
    page,
    params,
    filterName,
    filterCompany,
    filterPosition,
    filterStartCost,
    filterEndCost,
    filterStartDate,
    filterEndDate,
    filterRating,
    filterSkills,
    filterServices,
    filterTags,
    filterEcosystems,
  ]);

  useEffect(() => {
    if (reloadData) {
      getAllAppUsers();
      setReloadData(false);
    }
  }, [reloadData]);

  useEffect(() => {
    getAllAppUsers();
    getAllSkills();
    getAllServices();
    getAllTags();
  }, []);

  if (
    userInfo.role.slug === "ADMIN" ||
    userInfo.role.slug === "SUPER_ADMIN" ||
    moduleAccess.includes("MOD_USER")
  ) {
    return (
      <div className="pnl_mdlpnl">
        {/* <!-- ========== Start filter search Section ========== --> */}
        <div className="white_shadow_bx px-3 py-2 rounded-10 bg-white d-flex align-items-center justify-content-between mb-4 gap-3 filter_srch_item">
          <div className="filter_search_wrap d-flex align-items-center gap-3 w-100 p-3">
            <div className="dropdown">
              <Link
                to="#"
                className="dropdown-toggle text-gray"
                data-bs-toggle="dropdown"
              >
                Sort by
              </Link>
              <ul className="dropdown-menu px-2">
                <li className="mb-2">
                  <Link
                    to="#"
                    className="dropdown-item d-flex align-items-center gap-2 text-gray"
                    onClick={() => {
                      setParams({
                        sortby: "desc",
                      });
                    }}
                  >
                    {t("Rank")}
                    <i className="material-icons-outlined fs-sm">
                      arrow_upward
                    </i>
                  </Link>
                </li>
                <li className="mb-2">
                  <Link
                    to="#"
                    className="dropdown-item d-flex align-items-center gap-2 text-gray"
                    onClick={() => {
                      setParams({
                        sortby: "asc",
                      });
                    }}
                  >
                    {t("Rank")}
                    <i className="material-icons-outlined fs-sm">
                      arrow_downward
                    </i>
                  </Link>
                </li>
                <li>
                  <Link
                    to="#"
                    className="dropdown-item d-flex align-items-center gap-2 text-gray"
                    onClick={() => {
                      setParams({
                        sortby: "name",
                      });
                    }}
                  >
                    {t("Name")} (A-Z)
                  </Link>
                </li>
              </ul>
            </div>

            <div className="search_filter_form d-flex align-items-center justify-content-between w-100 position-relative">
              <input
                type="text"
                className="form-control"
                placeholder="Search by user name or email"
                value={searchedText}
                onChange={(e) => {
                  setsearchedText(e.target.value);
                  if (e.target.value === "") {
                    setsearchedUserResults([]);
                  }
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    setfilterName(searchedText);
                  }
                }}
              />
              <button
                className="filter_srch_btn"
                onClick={() => {
                  setfilterName(searchedText);
                }}
              >
                <i className="material-icons-outlined">search</i>
              </button>

              <div
                ref={searchUserListRef}
                className={`search_result bg-white rounded-10 white_shadow_bx p-3 position-absolute ${
                  searchedUserResults.length > 0 ? "show_result" : ""
                }`}
              >
                <ul className="appuser_search_box">
                  {searchedUserResults.map((userData, index) => {
                    return (
                      <li
                        className="py-2 text-gray d-flex align-items-center gap-2"
                        key={index}
                      >
                        <Link to={`/peoples/details/${userData._id}`}>
                          <div className="usr_lftPnl">
                            <div className="chatting_user">
                              <img
                                src={
                                  userData.userimage == ""
                                    ? assetImages.defaultUser
                                    : url.SERVER_URL + userData.userimage
                                }
                                alt=""
                              />
                            </div>
                            <div className="user_name">
                              <h3>{userData.label}</h3>
                              <p>{userData.userposition}</p>
                            </div>
                          </div>
                        </Link>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          </div>
          <div className="filter_refresh_btn d-flex align-items-center gap-3">
            <Link
              to="#"
              className="d-block refresh_iocn"
              onClick={() => {
                resetFilterData();
                resetList();
              }}
            >
              <i className="material-icons-outlined">refresh</i>
            </Link>
            <Link
              to="#"
              data-bs-toggle="offcanvas"
              data-bs-target="#appuser_filter_offcanvas"
              role="button"
              aria-controls="appuser_filter_offcanvas"
              className="d-flex align-items-center gap-2"
            >
              <i className="material-icons-outlined">tune</i>
              <span className="d-block">Filter</span>
            </Link>
          </div>
        </div>
        {/* <!-- ========== End filter search Section ========== --> */}

        {/* <!-- ========== Start user list Section ========== --> */}
        <div className="post_item_outer sameheight_scroll">
          {isLoading ? (
            <div className="post_item">
              {glowCount.map((item, index) => {
                return <AppUserPlaceHolder key={index} />;
              })}
            </div>
          ) : (
            <div className="post_item">
              {appUserList.map((user, index) => {
                return <AppUserCard key={index} userData={user} />;
              })}
            </div>
          )}
        </div>
        {/* <!-- ========== End user list Section ========== --> */}

        {/* <!-- ========== Start next page button Section ========== --> */}
        {hideNextButton ? (
          <div className="d-flex align-items-center gap-3 mt-3">
            <p className="fw-semibold text-black-gray">
              Sorry! No more data available
            </p>
            <div className="filter_refresh_btn d-flex align-items-center gap-3">
              <Link
                to="#"
                className="d-block refresh_iocn"
                onClick={() => {
                  resetFilterData();
                  resetList();
                }}
              >
                <i className="material-icons-outlined">refresh</i>
              </Link>
            </div>
          </div>
        ) : (
          <div className="d-flex justify-content-end mt-1">
            <button
              className="btn"
              onClick={() => {
                setPage(page + 1);
              }}
            >
              Next
            </button>
          </div>
        )}
        {/* <!-- ========== End next page button Section ========== --> */}

        <AppUserFilterPopup
          skillList={skillList}
          serviceList={serviceList}
          tagList={tagList}
          ecosystemList={ecosystemList}
          setfilterName={setfilterName}
          setfilterCompany={setfilterCompany}
          setFilterPosition={setFilterPosition}
          setFilterStartCost={setFilterStartCost}
          setFilterEndCost={setFilterEndCost}
          setfilterStartDate={setfilterStartDate}
          setfilterEndDate={setfilterEndDate}
          setFilterRating={setFilterRating}
          setfilterSkills={setfilterSkills}
          setFilterServices={setFilterServices}
          setFilterTags={setFilterTags}
          setFilterEcosystems={setFilterEcosystems}
          resetList={resetList}
        />
      </div>
    );
  } else {
    return (
      <div className="pnl_mdlpnl">
        <div className="empty_access text-center">
          <div className="empty_pic mb-4">
            {" "}
            <img src={assetImages.emptyVector} alt="" />
          </div>
          <div className="empty_text">
            <p className="fs-lg text-gray fw-semibold mb-4">
              Sorry....! You don't have privilege to see this content
            </p>
          </div>
        </div>
      </div>
    );
  }
};

export default AppUserMiddlePanel;

import {
  getDateFormatInSpanish,
  getDayAbbreviation,
} from "helper/Common/CommonHelper";
import React from "react";
import { Link } from "react-router-dom";

const AppointmentTab = ({ agendaList = [] }) => {
  return (
    <div className="people_details_bx white_shadow_bx">
      <div className="details_hdng">
        <h4>Availibility of next seven days</h4>
      </div>
      <div className="booking_table mb-4">
        <div className="table-responsive rounded-10">
          <table className="w-100">
            <thead>
              <tr>
                {agendaList.map((agenda, index) => {
                  return (
                    <th key={index}>
                      <div className="appointment_date">
                        <h4 className="text-gray">
                          {getDayAbbreviation(agenda.date)}
                        </h4>
                        <h5>{getDateFormatInSpanish(agenda.date)}</h5>
                      </div>
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody>
              <tr>
                {agendaList.map((agenda, index) => {
                  return (
                    <td key={index}>
                      {agenda.records.map((record, index2) => {
                        return (
                          <Link
                            key={index2}
                            to="#"
                            className="fw-normal mb-2 bg-white "
                          >
                            {record.starttime} - {record.endtime}
                          </Link>
                        );
                      })}{" "}
                    </td>
                  );
                })}
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default AppointmentTab;

/* eslint-disable */
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

// import use translation ------------------------------
import { useTranslation } from "react-i18next";

/*------ import url and method ------*/
import * as url from "helper/UrlHelper";
import { getData } from "utils/Gateway";

//import images
import { assetImages } from "constants";

import CompanyReviewModal from "../Modal/CompanyReviewModal";
import CompanyListCard from "./Card/CompanyListCard";
import CompanyListPlaceHolder from "./PlaceHolder/CompanyListPlaceHolder";
import CompanyFilterPopup from "../Popup/CompanyFilterPopup";

const CompanyLandingMiddlePanel = () => {
  const token = localStorage.getItem("token");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const moduleAccess = localStorage.getItem("moduleaccess");

  const limit = 10;

  const { t } = useTranslation(); // translation marker

  const [companyTypeList, setCompanyTypeList] = useState([]);
  const [tagList, setTagList] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [companyList, setCompanyList] = useState([]);

  const [filterCompanyName, setFilterCompanyName] = useState("");
  const [filterCompanyTypes, setFilterCompanyTypes] = useState([]);
  const [filterCompanyTags, setFilterCompanyTags] = useState([]);
  const [filterRating, setFilterRating] = useState("");
  const [filterEcosystems, setFilterEcosystems] = useState([]);

  // the following useState is used to increment the page number and render it accordingly.
  const [page, setPage] = useState(0);
  const [params, setParams] = useState(null);
  const [searchedText, setsearchedText] = useState("");
  const [hideNextButton, sethideNextButton] = useState(false);

  const [reloadData, setReloadData] = useState(false);

  const glowCount = [1, 2, 3];

  //function for get all roles
  const getAllCompanyType = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL + url.API_GET_ALL_COMPANY_TYPE + `?token=${token}`;

      const response = await getData(requestUrl);

      if (response.status) {
        setCompanyTypeList(response.data);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for get all tags
  const getAllTags = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL + url.API_GET_ALL_TAGS + `?token=${token}`;

      const response = await getData(requestUrl);

      // console.log(response);

      if (response.status) {
        setTagList(response.data);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for get all contacts
  const getAllCompanyList = async () => {
    try {
      setIsLoading(true);

      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_ALL_COMPANY +
        `?token=${token}&start=${
          page * limit
        }&limit=${limit}&modulefrom=frontend`;

      if (filterCompanyName != "") {
        requestUrl = requestUrl + `&filtername=${filterCompanyName}`;
      }

      if (filterCompanyTypes.length > 0) {
        requestUrl = requestUrl + `&filtertypes=${filterCompanyTypes}`;
      }

      if (filterCompanyTags.length > 0) {
        requestUrl = requestUrl + `&filterlabels=${filterCompanyTags}`;
      }

      if (filterRating != "") {
        requestUrl = requestUrl + `&filterrating=${filterRating}`;
      }

      if (filterEcosystems.length > 0) {
        requestUrl = requestUrl + `&filterecosystemids=${filterEcosystems}`;
      }

      if (params) {
        requestUrl = requestUrl + `&sortingby=${params?.sortby}`;
      }

      console.log("url of company list------>", requestUrl);

      const response = await getData(requestUrl);

      console.log("response company list------>", response);

      setIsLoading(false);

      if (response.status) {
        if (page === 0) {
          setCompanyList(response.data);
        } else {
          setCompanyList((companyList) => [...companyList, ...response.data]);
        }

        if (response.data.length < limit) {
          sethideNextButton(true);
        } else {
          sethideNextButton(false);
        }

        // resetFilterData();
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const resetList = () => {
    sethideNextButton(false);
    setsearchedText("");
    setReloadData(true);
  };

  //function for reset filter
  const resetFilterData = () => {
    setFilterCompanyName("");
    setFilterCompanyTypes([]);
    setFilterCompanyTags([]);
    setFilterRating("");
    setFilterEcosystems([]);
    setPage(0);
    setParams(null);
  };

  useEffect(() => {
    if (
      filterCompanyName != "" ||
      filterCompanyTypes.length > 0 ||
      filterCompanyTags.length > 0 ||
      filterRating != "" ||
      filterEcosystems.length > 0 ||
      params
    ) {
      getAllCompanyList();
    }
  }, [
    filterCompanyName,
    filterCompanyTypes,
    filterCompanyTags,
    filterRating,
    filterEcosystems,
    params,
    page,
  ]);

  useEffect(() => {
    if (reloadData) {
      getAllCompanyList();
      setReloadData(false);
    }
  }, [reloadData]);

  useEffect(() => {
    getAllTags();
    getAllCompanyType();
    getAllCompanyList();
  }, []);

  if (
    userInfo.role.slug === "ADMIN" ||
    userInfo.role.slug === "SUPER_ADMIN" ||
    moduleAccess.includes("MOD_COMPANY")
  ) {
    return (
      <div className="pnl_mdlpnl">
        {/* <!-- ========== Start filter search Section ========== --> */}
        <div className="white_shadow_bx px-3 py-2 rounded-10 bg-white d-flex align-items-center justify-content-between mb-4 gap-3 filter_srch_item">
          <div className="filter_search_wrap d-flex align-items-center gap-3 w-100 p-3">
            <div className="dropdown">
              <Link
                to="#"
                className="dropdown-toggle text-gray"
                data-bs-toggle="dropdown"
              >
                Sort by
              </Link>
              <ul className="dropdown-menu px-2">
                <li className="mb-2">
                  <Link
                    to="#"
                    className="dropdown-item d-flex align-items-center gap-2 text-gray"
                    onClick={() => {
                      setParams({
                        sortby: "desc",
                      });
                    }}
                  >
                    {t("Rank")}
                    <i className="material-icons-outlined fs-sm">
                      arrow_upward
                    </i>
                  </Link>
                </li>
                <li className="mb-2">
                  <Link
                    to="#"
                    className="dropdown-item d-flex align-items-center gap-2 text-gray"
                    onClick={() => {
                      setParams({
                        sortby: "asc",
                      });
                    }}
                  >
                    {t("Rank")}
                    <i className="material-icons-outlined fs-sm">
                      arrow_downward
                    </i>
                  </Link>
                </li>
                <li>
                  <Link
                    to="#"
                    className="dropdown-item d-flex align-items-center gap-2 text-gray"
                    onClick={() => {
                      setParams({
                        sortby: "name",
                      });
                    }}
                  >
                    {t("Name")} (A-Z)
                  </Link>
                </li>
              </ul>
            </div>

            <div className="search_filter_form d-flex align-items-center justify-content-between w-100">
              <input
                type="text"
                className="form-control"
                placeholder="Search by company name"
                value={searchedText}
                onChange={(e) => {
                  setsearchedText(e.target.value);
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    setfilterName(searchedText);
                  }
                }}
              />
              <button
                className="filter_srch_btn"
                onClick={() => {
                  setfilterName(searchedText);
                }}
              >
                <i className="material-icons-outlined">search</i>
              </button>
            </div>
          </div>
          <div className="filter_refresh_btn d-flex align-items-center gap-3">
            <Link
              to="#"
              className="d-block refresh_iocn"
              onClick={() => {
                resetFilterData();
                resetList();
              }}
            >
              <i className="material-icons-outlined">refresh</i>
            </Link>
            <Link
              to="#"
              data-bs-toggle="offcanvas"
              data-bs-target="#company_filter_offcanvas"
              role="button"
              aria-controls="company_filter_offcanvas"
              className="d-flex align-items-center gap-2"
            >
              <i className="material-icons-outlined">tune</i>
              <span className="d-block">Filter</span>
            </Link>
          </div>
        </div>
        {/* <!-- ========== End filter search Section ========== --> */}

        <div className="post_item_outer">
          <div className="post_item">
            {isLoading ? (
              <div className="activity_innr">
                {glowCount.map((glow, index) => {
                  return <CompanyListPlaceHolder key={index} />;
                })}
              </div>
            ) : (
              <div className="activity_innr">
                {companyList.length > 0 ? (
                  companyList.map((company, index) => {
                    return <CompanyListCard key={index} company={company} />;
                  })
                ) : (
                  <div className="empty_access text-center">
                    <div className="empty_pic mb-4">
                      {" "}
                      <img src={assetImages.emptyVector} alt="" />
                    </div>
                    <div className="empty_text">
                      <p className="fs-lg text-gray fw-semibold mb-4">
                        Sorry....! Clinics are not found .
                      </p>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>

          {/* <!-- ========== Start next page button Section ========== --> */}
          {hideNextButton ? (
            <div className="d-flex align-items-center gap-3 mt-3">
              <p className="fw-semibold text-gray">
                Sorry! No more data available
              </p>
              <div className="filter_refresh_btn d-flex align-items-center gap-3">
                <Link
                  to="#"
                  className="d-block refresh_iocn"
                  onClick={() => {
                    resetFilterData();
                    resetList();
                  }}
                >
                  <i className="material-icons-outlined">refresh</i>
                </Link>
              </div>
            </div>
          ) : (
            <button
              className="mt-3 btn btn-primary"
              onClick={() => {
                setPage(page + 1);
              }}
            >
              Next Page
            </button>
          )}
          {/* <!-- ========== End next page button Section ========== --> */}
        </div>

        <CompanyFilterPopup
          setFilterCompanyName={setFilterCompanyName}
          setFilterCompanyTypes={setFilterCompanyTypes}
          setFilterCompanyTags={setFilterCompanyTags}
          setFilterRating={setFilterRating}
          setFilterEcosystems={setFilterEcosystems}
          tagList={tagList}
          companyTypeList={companyTypeList}
          resetList={resetList}
        />
      </div>
    );
  } else {
    return (
      <div className="pnl_mdlpnl">
        <div className="empty_access text-center">
          <div className="empty_pic mb-4">
            {" "}
            <img src={assetImages.emptyVector} alt="" />
          </div>
          <div className="empty_text">
            <p className="fs-lg text-gray fw-semibold mb-4">
              Sorry....! You don't have privilege to see this content
            </p>
          </div>
        </div>
      </div>
    );
  }
};

export default CompanyLandingMiddlePanel;
